import log, { type LogLevelDesc, type Logger } from 'loglevel';

import React, { createContext, useContext } from 'react';

export interface LoggerService {
  consoleLogger: Logger;
}

interface LoggerProviderProps {
  children: JSX.Element[] | JSX.Element;
  loggerService: LoggerService;
}

export const initLoggerService = (level: LogLevelDesc): LoggerService => {
  const logger: Logger = log.getLogger('discko');
  logger.setLevel(level);
  return {
    consoleLogger: logger,
  };
};

export const LoggerContext: React.Context<LoggerService> = createContext({} as LoggerService);

export function useLogger(): LoggerService {
  return useContext(LoggerContext);
}

export function LoggerProvider({ children, loggerService }: LoggerProviderProps): JSX.Element {
  return <LoggerContext.Provider value={loggerService}>{children}</LoggerContext.Provider>;
}
