/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetFullDisckoCompanyDto } from './GetFullDisckoCompanyDto';
import {
    GetFullDisckoCompanyDtoFromJSON,
    GetFullDisckoCompanyDtoFromJSONTyped,
    GetFullDisckoCompanyDtoToJSON,
} from './GetFullDisckoCompanyDto';

/**
 * 
 * @export
 * @interface GetFullDisckoCompaniesDto
 */
export interface GetFullDisckoCompaniesDto {
    /**
     * 
     * @type {Array<GetFullDisckoCompanyDto>}
     * @memberof GetFullDisckoCompaniesDto
     */
    companies: Array<GetFullDisckoCompanyDto>;
}

/**
 * Check if a given object implements the GetFullDisckoCompaniesDto interface.
 */
export function instanceOfGetFullDisckoCompaniesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companies" in value;

    return isInstance;
}

export function GetFullDisckoCompaniesDtoFromJSON(json: any): GetFullDisckoCompaniesDto {
    return GetFullDisckoCompaniesDtoFromJSONTyped(json, false);
}

export function GetFullDisckoCompaniesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFullDisckoCompaniesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(GetFullDisckoCompanyDtoFromJSON)),
    };
}

export function GetFullDisckoCompaniesDtoToJSON(value?: GetFullDisckoCompaniesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': ((value.companies as Array<any>).map(GetFullDisckoCompanyDtoToJSON)),
    };
}

