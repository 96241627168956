/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyFormOrchestratorResponseDtoMessage } from './AgencyFormOrchestratorResponseDtoMessage';
import {
    AgencyFormOrchestratorResponseDtoMessageFromJSON,
    AgencyFormOrchestratorResponseDtoMessageFromJSONTyped,
    AgencyFormOrchestratorResponseDtoMessageToJSON,
} from './AgencyFormOrchestratorResponseDtoMessage';

/**
 * 
 * @export
 * @interface AgencyFormOrchestratorResponseDto
 */
export interface AgencyFormOrchestratorResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyFormOrchestratorResponseDto
     */
    step: string;
    /**
     * 
     * @type {AgencyFormOrchestratorResponseDtoMessage}
     * @memberof AgencyFormOrchestratorResponseDto
     */
    message: AgencyFormOrchestratorResponseDtoMessage;
}

/**
 * Check if a given object implements the AgencyFormOrchestratorResponseDto interface.
 */
export function instanceOfAgencyFormOrchestratorResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "step" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function AgencyFormOrchestratorResponseDtoFromJSON(json: any): AgencyFormOrchestratorResponseDto {
    return AgencyFormOrchestratorResponseDtoFromJSONTyped(json, false);
}

export function AgencyFormOrchestratorResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyFormOrchestratorResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': json['step'],
        'message': AgencyFormOrchestratorResponseDtoMessageFromJSON(json['message']),
    };
}

export function AgencyFormOrchestratorResponseDtoToJSON(value?: AgencyFormOrchestratorResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': value.step,
        'message': AgencyFormOrchestratorResponseDtoMessageToJSON(value.message),
    };
}

