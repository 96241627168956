/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  ErrorInternalServer,
  ErrorUnauthorizedRequest,
  GetMaintenanceInfosDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorInternalServerFromJSON,
    ErrorInternalServerToJSON,
    ErrorUnauthorizedRequestFromJSON,
    ErrorUnauthorizedRequestToJSON,
    GetMaintenanceInfosDtoFromJSON,
    GetMaintenanceInfosDtoToJSON,
} from '../models';

/**
 * 
 */
export class MaintenanceApi extends runtime.BaseAPI {

    /**
     */
    async maintenanceControllerGetMaintenanceInfosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMaintenanceInfosDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/maintenance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMaintenanceInfosDtoFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceControllerGetMaintenanceInfos(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMaintenanceInfosDto> {
        const response = await this.maintenanceControllerGetMaintenanceInfosRaw(initOverrides);
        return await response.value();
    }

}
