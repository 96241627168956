/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCommentDto,
  CreateAgencyClientDto,
  ErrorBadRequest,
  GetRequirementDto,
  GetRequirementIdParamsDto,
  GetRequirementsDto,
} from '../models';
import {
    AddCommentDtoFromJSON,
    AddCommentDtoToJSON,
    CreateAgencyClientDtoFromJSON,
    CreateAgencyClientDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetRequirementDtoFromJSON,
    GetRequirementDtoToJSON,
    GetRequirementIdParamsDtoFromJSON,
    GetRequirementIdParamsDtoToJSON,
    GetRequirementsDtoFromJSON,
    GetRequirementsDtoToJSON,
} from '../models';

export interface AuthRequirementControllerFindByIdRequest {
    id: string;
}

export interface AuthRequirementControllerReadRequest {
    id: string;
}

export interface AuthRequirementControllerUnreadRequest {
    id: string;
}

export interface FormRequirementControllerAddCommentRequest {
    id: string;
    companyId: string;
    addCommentDto: AddCommentDto;
}

export interface FormRequirementControllerInitRequirementRequest {
    companyId: string;
    createAgencyClientDto: CreateAgencyClientDto;
}

/**
 * 
 */
export class RequirementsApi extends runtime.BaseAPI {

    /**
     */
    async authRequirementControllerFindAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRequirementsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequirementsDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerFindAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRequirementsDto> {
        const response = await this.authRequirementControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authRequirementControllerFindByIdRaw(requestParameters: AuthRequirementControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authRequirementControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerFindById(requestParameters: AuthRequirementControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRequirementDto> {
        const response = await this.authRequirementControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRequirementControllerReadRaw(requestParameters: AuthRequirementControllerReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authRequirementControllerRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements/{id}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerRead(requestParameters: AuthRequirementControllerReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRequirementDto> {
        const response = await this.authRequirementControllerReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRequirementControllerUnreadRaw(requestParameters: AuthRequirementControllerUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authRequirementControllerUnread.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements/{id}/unread`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerUnread(requestParameters: AuthRequirementControllerUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRequirementDto> {
        const response = await this.authRequirementControllerUnreadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formRequirementControllerAddCommentRaw(requestParameters: FormRequirementControllerAddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formRequirementControllerAddComment.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formRequirementControllerAddComment.');
        }

        if (requestParameters.addCommentDto === null || requestParameters.addCommentDto === undefined) {
            throw new runtime.RequiredError('addCommentDto','Required parameter requestParameters.addCommentDto was null or undefined when calling formRequirementControllerAddComment.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/requirements/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddCommentDtoToJSON(requestParameters.addCommentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async formRequirementControllerAddComment(requestParameters: FormRequirementControllerAddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRequirementDto> {
        const response = await this.formRequirementControllerAddCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formRequirementControllerInitRequirementRaw(requestParameters: FormRequirementControllerInitRequirementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRequirementIdParamsDto>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formRequirementControllerInitRequirement.');
        }

        if (requestParameters.createAgencyClientDto === null || requestParameters.createAgencyClientDto === undefined) {
            throw new runtime.RequiredError('createAgencyClientDto','Required parameter requestParameters.createAgencyClientDto was null or undefined when calling formRequirementControllerInitRequirement.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/requirements/init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAgencyClientDtoToJSON(requestParameters.createAgencyClientDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequirementIdParamsDtoFromJSON(jsonValue));
    }

    /**
     */
    async formRequirementControllerInitRequirement(requestParameters: FormRequirementControllerInitRequirementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRequirementIdParamsDto> {
        const response = await this.formRequirementControllerInitRequirementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formRequirementControllerTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/requirements/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async formRequirementControllerTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.formRequirementControllerTestRaw(initOverrides);
    }

}
