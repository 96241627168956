/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDisckoUserDto,
  DisckoUserControllerRemove200Response,
  ErrorBadRequest,
  ErrorInternalServer,
  ErrorUnauthorizedRequest,
  GetDisckoUserDto,
  GetDisckoUsersDto,
  UpdateDisckoUserDto,
  UpdateNotificationsDisckoUserDto,
} from '../models';
import {
    CreateDisckoUserDtoFromJSON,
    CreateDisckoUserDtoToJSON,
    DisckoUserControllerRemove200ResponseFromJSON,
    DisckoUserControllerRemove200ResponseToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorInternalServerFromJSON,
    ErrorInternalServerToJSON,
    ErrorUnauthorizedRequestFromJSON,
    ErrorUnauthorizedRequestToJSON,
    GetDisckoUserDtoFromJSON,
    GetDisckoUserDtoToJSON,
    GetDisckoUsersDtoFromJSON,
    GetDisckoUsersDtoToJSON,
    UpdateDisckoUserDtoFromJSON,
    UpdateDisckoUserDtoToJSON,
    UpdateNotificationsDisckoUserDtoFromJSON,
    UpdateNotificationsDisckoUserDtoToJSON,
} from '../models';

export interface DisckoUserControllerCreateRequest {
    createDisckoUserDto: CreateDisckoUserDto;
}

export interface DisckoUserControllerRemoveRequest {
    id: string;
}

export interface DisckoUserControllerUpdateRequest {
    id: string;
    updateDisckoUserDto: UpdateDisckoUserDto;
}

export interface DisckoUserControllerUpdateNotificationsRequest {
    updateNotificationsDisckoUserDto: UpdateNotificationsDisckoUserDto;
}

/**
 * 
 */
export class DisckoUsersApi extends runtime.BaseAPI {

    /**
     */
    async disckoUserControllerCreateRaw(requestParameters: DisckoUserControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoUserDto>> {
        if (requestParameters.createDisckoUserDto === null || requestParameters.createDisckoUserDto === undefined) {
            throw new runtime.RequiredError('createDisckoUserDto','Required parameter requestParameters.createDisckoUserDto was null or undefined when calling disckoUserControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/disckoUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDisckoUserDtoToJSON(requestParameters.createDisckoUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoUserControllerCreate(requestParameters: DisckoUserControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoUserDto> {
        const response = await this.disckoUserControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoUserControllerFindMyCompanyUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoUsersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/disckoUsers/my-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoUsersDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoUserControllerFindMyCompanyUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoUsersDto> {
        const response = await this.disckoUserControllerFindMyCompanyUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoUserControllerGetUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoUserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/disckoUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoUserControllerGetUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoUserDto> {
        const response = await this.disckoUserControllerGetUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoUserControllerRemoveRaw(requestParameters: DisckoUserControllerRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisckoUserControllerRemove200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disckoUserControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/disckoUsers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisckoUserControllerRemove200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async disckoUserControllerRemove(requestParameters: DisckoUserControllerRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisckoUserControllerRemove200Response> {
        const response = await this.disckoUserControllerRemoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoUserControllerUpdateRaw(requestParameters: DisckoUserControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoUserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disckoUserControllerUpdate.');
        }

        if (requestParameters.updateDisckoUserDto === null || requestParameters.updateDisckoUserDto === undefined) {
            throw new runtime.RequiredError('updateDisckoUserDto','Required parameter requestParameters.updateDisckoUserDto was null or undefined when calling disckoUserControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/disckoUsers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDisckoUserDtoToJSON(requestParameters.updateDisckoUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoUserControllerUpdate(requestParameters: DisckoUserControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoUserDto> {
        const response = await this.disckoUserControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoUserControllerUpdateNotificationsRaw(requestParameters: DisckoUserControllerUpdateNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoUserDto>> {
        if (requestParameters.updateNotificationsDisckoUserDto === null || requestParameters.updateNotificationsDisckoUserDto === undefined) {
            throw new runtime.RequiredError('updateNotificationsDisckoUserDto','Required parameter requestParameters.updateNotificationsDisckoUserDto was null or undefined when calling disckoUserControllerUpdateNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/disckoUsers/notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotificationsDisckoUserDtoToJSON(requestParameters.updateNotificationsDisckoUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoUserControllerUpdateNotifications(requestParameters: DisckoUserControllerUpdateNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoUserDto> {
        const response = await this.disckoUserControllerUpdateNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
