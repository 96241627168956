import { DisckoApisContext, MaintenanceContext } from 'App';

import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

export function MaintenanceCheck(): JSX.Element {
  const { maintenanceService, companyService } = useContext(DisckoApisContext);
  const { setMaintenanceInfos, maintenanceInfos, setIsUserAdmin } = useContext(MaintenanceContext);
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: maintenanceData, refetch } = useQuery({
    queryKey: ['maintenance'],
    queryFn: async () => {
      return await maintenanceService.getMaintenanceInfos();
    },
  });
  async function amIAdmin(): Promise<boolean> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.isAdminCompany(accessToken);
  }

  const { data: isAdmin } = useQuery<boolean, Error>(['admin', user?.sub], amIAdmin);

  // Trigger the refetch when the location changes
  const location = useLocation();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  }, [location]);

  useEffect(() => {
    // Deep check data diff
    function isDataDiff(): boolean {
      return (
        maintenanceData?.beginningDate.toLocaleString() !== maintenanceInfos.beginningDate.toLocaleString() ||
        maintenanceData?.endDate.toLocaleString() !== maintenanceInfos.endDate.toLocaleString() ||
        maintenanceData?.state !== maintenanceInfos.state
      );
    }
    if (maintenanceData !== undefined && isDataDiff()) {
      setMaintenanceInfos(maintenanceData);
    }
  }, [maintenanceData]);

  useEffect(() => {
    if (isAdmin !== undefined) {
      setIsUserAdmin?.(isAdmin);
    }
  }, [isAdmin]);

  return <></>;
}
