import { GetMaintenanceInfosDtoStateEnum } from 'api';
import { AuthenticationGuard } from 'authentication-guard';

import React, { createContext, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { useAuth0 } from '@auth0/auth0-react';

import { CallbackPage } from 'pages/CallbackPage/CallbackPage';
import { HomePage } from 'pages/HomePage/HomePage';
import { MaintenanceMessage } from 'pages/MaintenancePage/MaintenanceMessage';
import { MaintenancePage } from 'pages/MaintenancePage/MaintenancePage';
import { MobileStartRequirementPage } from 'pages/MobileStartRequirementPage/MobileStartRequirementPage';
import { Discko } from 'pages/RequirementForm/DisckoPage';
import { RequirementPage } from 'pages/RequirementsPages/RequirementPage';
import { RequirementsPage } from 'pages/RequirementsPages/RequirementsPage';
import { SettingsPage } from 'pages/SettingsPage/SettingsPage';
import { SuperAdminBackOfficePage } from 'pages/SuperAdminBackOffice/SuperAdminBackOfficePage';

import { BackOfficeCGSPage } from 'components/Legal/BackOfficeCGSPage';
import { BackOfficeCGUPage } from 'components/Legal/BackOfficeCGUPage';
import { FrontOfficeCGU } from 'components/Legal/FrontOfficeCGU';
import { PrivacyPolicy } from 'components/Legal/PrivacyPolicy';
import { TermsPage } from 'components/Legal/TermsPage';
import { MaintenanceCheck } from 'components/MaintenanceComponents/MaintenanceCheck';

import { AdminService } from 'services/admin.service';
import { AgencyFormOrchestratorService } from 'services/agencyFormOrchestrator.service';
import { CompanyService } from 'services/company.service';
import { MaintenanceService } from 'services/maintenance.service';
import { RequirementParametersService } from 'services/requirement-paramters.service';
import { RequirementService } from 'services/requirement.service';
import { StripeService } from 'services/stripe.service';
import { UserService } from 'services/user.service';

import { LoggerProvider, type LoggerService, initLoggerService } from 'utils/logger';

import './index.css';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: any) => {
        if (error.message === 'ERR-001') window.location.replace('/denied');
        if (error.message === 'ERR-002') window.location.replace('/expired');
      },
    },
    mutations: {
      onError: (error: any) => {
        if (error.message === 'ERR-001') window.location.replace('/denied');
        if (error.message === 'ERR-002') window.location.replace('/expired');
      },
    },
  },
});

interface DisckoApi {
  openAiService: AgencyFormOrchestratorService;
  requirementService: RequirementService;
  companyService: CompanyService;
  adminService: AdminService;
  stripeService: StripeService;
  userService: UserService;
  requirementParametersService?: RequirementParametersService;
  maintenanceService: MaintenanceService;
}

const services: DisckoApi = {
  openAiService: new AgencyFormOrchestratorService(),
  requirementService: new RequirementService(),
  companyService: new CompanyService(),
  adminService: new AdminService(),
  stripeService: new StripeService(),
  userService: new UserService(),
  requirementParametersService: new RequirementParametersService(),
  maintenanceService: new MaintenanceService(),
};

export const DisckoApisContext: React.Context<DisckoApi> = createContext(services);

interface MaintenanceInfosProps {
  endDate: Date;
  beginningDate: Date;
  state: GetMaintenanceInfosDtoStateEnum;
}

interface HandleMaintenanceProps {
  maintenanceInfos: MaintenanceInfosProps;
  setMaintenanceInfos: (infos: MaintenanceInfosProps) => void;
  isUserAdmin: boolean;
  setIsUserAdmin?: (isAdmin: boolean) => void;
}

const handleMaintenance: HandleMaintenanceProps = {
  maintenanceInfos: {
    endDate: new Date(),
    beginningDate: new Date(),
    state: GetMaintenanceInfosDtoStateEnum.Finished,
  },
  setMaintenanceInfos: (infos: MaintenanceInfosProps) => {
    handleMaintenance.maintenanceInfos = infos;
  },
  isUserAdmin: false,
  setIsUserAdmin: (isAdmin: boolean) => {
    handleMaintenance.isUserAdmin = isAdmin;
  },
};

export const MaintenanceContext: React.Context<HandleMaintenanceProps> = createContext(handleMaintenance);

// init logger
const loggerService: LoggerService = initLoggerService('TRACE');

interface HistoryProviderProps {
  children: JSX.Element[] | JSX.Element;
  handler: HistoryHandler;
}
export interface HistoryHandler {
  oldPath: string | undefined;
  actualPath: string | undefined;
}

export const HistoryContext: React.Context<HistoryHandler> = createContext({} as HistoryHandler);

export function useHistoryHandler(): HistoryHandler {
  return useContext(HistoryContext);
}

export function HistoryProvider({ children, handler }: HistoryProviderProps): JSX.Element {
  return <HistoryContext.Provider value={handler}>{children}</HistoryContext.Provider>;
}

const historyHandler: HistoryHandler = {
  oldPath: undefined,
  actualPath: undefined,
};

interface UserInfoProps {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  signUpAt: string;
  role: string;
}
const userInfo: UserInfoProps = {
  username: '-ERROR-',
  email: '-ERROR-',
  firstname: '-ERROR-',
  lastname: '-ERROR-',
  signUpAt: '-ERROR-',
  role: 'user',
};

export function App(): JSX.Element {
  const { user } = useAuth0();
  userInfo.username =
    user !== undefined ? (user?.name?.slice(user?.name?.indexOf(']') + 2, user?.name?.length) as string) : 'ERROR';
  userInfo.email = user !== undefined ? (user?.email as string) : '==ERROR==';
  userInfo.firstname = user !== undefined ? (user?.name as string) : 'ERROR';
  userInfo.lastname = user !== undefined ? (user?.family_name as string) : '==ERROR==';
  userInfo.signUpAt = user !== undefined ? (user?.created_at as string) : '==ERROR==';

  const [maintenanceInfos, setMaintenanceInfos] = React.useState<MaintenanceInfosProps>({
    endDate: new Date(),
    beginningDate: new Date(),
    state: GetMaintenanceInfosDtoStateEnum.Finished,
  });
  const [isUserAdmin, setIsUserAdmin] = React.useState<boolean>(false);

  handleMaintenance.setMaintenanceInfos = setMaintenanceInfos;
  handleMaintenance.maintenanceInfos = maintenanceInfos;
  handleMaintenance.isUserAdmin = isUserAdmin;
  handleMaintenance.setIsUserAdmin = setIsUserAdmin;

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <DisckoApisContext.Provider value={services}>
          <MaintenanceContext.Provider value={handleMaintenance}>
            <HistoryProvider handler={historyHandler}>
              <LoggerProvider loggerService={loggerService}>
                <DndProvider backend={HTML5Backend}>
                  <ToastContainer
                    bodyClassName={() => 'flex font-body font-light block p-3'}
                    position="bottom-right"
                    pauseOnHover={true}
                    closeOnClick={true}
                    limit={3}
                    closeButton={false}
                    autoClose={2000}
                  />
                  <MaintenanceCheck />
                  <Routes>
                    {!handleMaintenance.isUserAdmin &&
                      handleMaintenance.maintenanceInfos.state === GetMaintenanceInfosDtoStateEnum.InProgress && (
                        <>
                          <Route path="*" element={<AuthenticationGuard component={MaintenancePage} />} />
                          <Route path="/new" element={<MaintenanceMessage />} />
                        </>
                      )}
                    {(handleMaintenance.isUserAdmin ||
                      handleMaintenance.maintenanceInfos.state !== GetMaintenanceInfosDtoStateEnum.InProgress) && (
                      <>
                        <Route path="/" element={<AuthenticationGuard component={HomePage} />} />
                        <Route path="/callback" element={<AuthenticationGuard component={CallbackPage} />} />

                        <Route
                          path="/requirements/:requirementId"
                          element={<AuthenticationGuard component={RequirementPage} />}
                        />
                        <Route path="/cgu-bo" element={<AuthenticationGuard component={BackOfficeCGUPage} />} />
                        <Route path="/cgu-fo" element={<AuthenticationGuard component={FrontOfficeCGU} />} />
                        <Route path="/cgs" element={<AuthenticationGuard component={BackOfficeCGSPage} />} />
                        <Route path="/requirements" element={<AuthenticationGuard component={RequirementsPage} />} />
                        <Route path="/settings" element={<AuthenticationGuard component={SettingsPage} />} />
                        <Route
                          path="/start-requirement"
                          element={<AuthenticationGuard component={MobileStartRequirementPage} />}
                        />
                        <Route path="/cgu-bo" element={<AuthenticationGuard component={BackOfficeCGUPage} />} />
                        <Route path="/cgu-fo" element={<AuthenticationGuard component={FrontOfficeCGU} />} />
                        <Route path="/cgs" element={<AuthenticationGuard component={BackOfficeCGSPage} />} />

                        <Route path="/privacy-policy" element={<AuthenticationGuard component={PrivacyPolicy} />} />
                        <Route path="/terms" element={<AuthenticationGuard component={TermsPage} />} />

                        <Route
                          path="/backoffice"
                          element={<AuthenticationGuard component={SuperAdminBackOfficePage} />}
                        />
                        <Route path="/new" element={<Discko />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </>
                    )}
                  </Routes>
                </DndProvider>
              </LoggerProvider>
            </HistoryProvider>
          </MaintenanceContext.Provider>
        </DisckoApisContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
