/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoCompanyIdDto } from './DisckoCompanyIdDto';
import {
    DisckoCompanyIdDtoFromJSON,
    DisckoCompanyIdDtoFromJSONTyped,
    DisckoCompanyIdDtoToJSON,
} from './DisckoCompanyIdDto';
import type { GetAgencyClientDto } from './GetAgencyClientDto';
import {
    GetAgencyClientDtoFromJSON,
    GetAgencyClientDtoFromJSONTyped,
    GetAgencyClientDtoToJSON,
} from './GetAgencyClientDto';

/**
 * 
 * @export
 * @interface GetRequirementDto
 */
export interface GetRequirementDto {
    /**
     * 
     * @type {string}
     * @memberof GetRequirementDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetRequirementDto
     */
    read: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementDto
     */
    projectType: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementDto
     */
    requirement: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementDto
     */
    completionType: GetRequirementDtoCompletionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof GetRequirementDto
     */
    finishedAt: Date;
    /**
     * 
     * @type {GetAgencyClientDto}
     * @memberof GetRequirementDto
     */
    agencyClient: GetAgencyClientDto;
    /**
     * 
     * @type {DisckoCompanyIdDto}
     * @memberof GetRequirementDto
     */
    agency: DisckoCompanyIdDto;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementDto
     */
    clientAnalysis?: string;
}


/**
 * @export
 */
export const GetRequirementDtoCompletionTypeEnum = {
    Agency: 'agency',
    Client: 'client'
} as const;
export type GetRequirementDtoCompletionTypeEnum = typeof GetRequirementDtoCompletionTypeEnum[keyof typeof GetRequirementDtoCompletionTypeEnum];


/**
 * Check if a given object implements the GetRequirementDto interface.
 */
export function instanceOfGetRequirementDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "read" in value;
    isInstance = isInstance && "projectType" in value;
    isInstance = isInstance && "requirement" in value;
    isInstance = isInstance && "completionType" in value;
    isInstance = isInstance && "finishedAt" in value;
    isInstance = isInstance && "agencyClient" in value;
    isInstance = isInstance && "agency" in value;

    return isInstance;
}

export function GetRequirementDtoFromJSON(json: any): GetRequirementDto {
    return GetRequirementDtoFromJSONTyped(json, false);
}

export function GetRequirementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequirementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'read': json['read'],
        'projectType': json['projectType'],
        'requirement': json['requirement'],
        'completionType': json['completionType'],
        'finishedAt': (new Date(json['finishedAt'])),
        'agencyClient': GetAgencyClientDtoFromJSON(json['agencyClient']),
        'agency': DisckoCompanyIdDtoFromJSON(json['agency']),
        'clientAnalysis': !exists(json, 'clientAnalysis') ? undefined : json['clientAnalysis'],
    };
}

export function GetRequirementDtoToJSON(value?: GetRequirementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'read': value.read,
        'projectType': value.projectType,
        'requirement': value.requirement,
        'completionType': value.completionType,
        'finishedAt': (value.finishedAt.toISOString()),
        'agencyClient': GetAgencyClientDtoToJSON(value.agencyClient),
        'agency': DisckoCompanyIdDtoToJSON(value.agency),
        'clientAnalysis': value.clientAnalysis,
    };
}

