import React, { useState } from 'react';

import { Text, type TextSize, textSizeStyle } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export interface MultiSelectRadioButtonProps {
  options: string[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
  disabled?: boolean;
  label?: string;
  labelSize?: TextSize;
}

export function MultiSelectRadioButtons({
  options,
  selectedValues,
  onChange,
  disabled = false,
  label,
  labelSize = 'lg',
}: MultiSelectRadioButtonProps): JSX.Element {
  const toggleSelection = (value: string): void => {
    if (selectedValues.includes(value)) {
      onChange(selectedValues.filter((item) => item !== value));
    } else {
      onChange([...selectedValues, value]);
    }
  };

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <div className="flex flex-col gap-2">
      <div className={concatClassNames('flex', 'flex-col', 'gap-4')}>
        <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
          {label !== undefined && (
            <div
              className={concatClassNames(
                'flex',
                isLargeScreen === true ? 'flex-row gap-2 items-center' : 'flex-col items-start',
              )}
            >
              <div className={concatClassNames('flex flex-row', 'items-center', 'gap-2')}>
                <Text position="left" size={labelSize} color="black" weight="bold" content={label} />
              </div>
            </div>
          )}
        </div>
        {options.map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => {
              toggleSelection(option);
            }}
            disabled={disabled}
            className={concatClassNames(
              'w-fit px-4 py-2 rounded-md border flex flex-row  items-center justify-start gap-2 text-start',
              textSizeStyle[labelSize],
              selectedValues.includes(option) ? 'text-black font-bold' : 'text-gray-400',
              disabled ?? false ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-400',
            )}
          >
            <input
              type="checkbox"
              checked={selectedValues.includes(option)}
              readOnly
              disabled={disabled}
              className={concatClassNames(disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
            />
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}
