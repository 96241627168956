/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgencyFormOrchestratorResponseDtoMessage
 */
export interface AgencyFormOrchestratorResponseDtoMessage {
    /**
     * 
     * @type {string}
     * @memberof AgencyFormOrchestratorResponseDtoMessage
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyFormOrchestratorResponseDtoMessage
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof AgencyFormOrchestratorResponseDtoMessage
     */
    mandatoryQuestions?: number;
}

/**
 * Check if a given object implements the AgencyFormOrchestratorResponseDtoMessage interface.
 */
export function instanceOfAgencyFormOrchestratorResponseDtoMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function AgencyFormOrchestratorResponseDtoMessageFromJSON(json: any): AgencyFormOrchestratorResponseDtoMessage {
    return AgencyFormOrchestratorResponseDtoMessageFromJSONTyped(json, false);
}

export function AgencyFormOrchestratorResponseDtoMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyFormOrchestratorResponseDtoMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'content': json['content'],
        'mandatoryQuestions': !exists(json, 'mandatoryQuestions') ? undefined : json['mandatoryQuestions'],
    };
}

export function AgencyFormOrchestratorResponseDtoMessageToJSON(value?: AgencyFormOrchestratorResponseDtoMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'content': value.content,
        'mandatoryQuestions': value.mandatoryQuestions,
    };
}

