import * as Yup from 'yup';

import React, { type MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import { FrontOfficeCGU } from 'components/Legal/FrontOfficeCGU';
import { PrivacyPolicyWidget } from 'components/Legal/PrivacyPolicyWidget';
import { RichText } from 'components/RichText/RichText';
import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { Stepsbar } from 'components/core/StepsBar/Stepsbar';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import delay from 'utils/delay';
import getIcon from 'utils/getIcon';

import { disckoContext } from './DisckoPage';

interface ClientInfoFormModel {
  companyName: string;
  companyWebsite?: string;
  contactLastname: string;
  contactFirstname: string;
  contactPhone?: string;
  contactEmail: string;
}

const schema: Yup.ObjectSchema<ClientInfoFormModel> = Yup.object().shape({
  companyName: Yup.string().required('Champ obligatoire'),
  companyWebsite: Yup.string(),
  contactLastname: Yup.string().required('Champ obligatoire'),
  contactFirstname: Yup.string().required('Champ obligatoire'),
  contactPhone: Yup.string(),
  contactEmail: Yup.string().required('Champ obligatoire').email('Cette adresse est invalide.'),
});

/* --------------------------------------------------- API calls -------------------------------------------------- */

interface ClientInfoFormProps {
  setNextStep?: (nextStep: string) => void;
}

interface ClientInfos {
  companyName: string;
  companyWebsite: string;
  contactLastname: string;
  contactFirstname: string;
  contactPhone: string;
  contactEmail: string;
}

export function ClientInfoForm({ setNextStep }: ClientInfoFormProps): JSX.Element {
  const { initRequirement, setRequirementParams, isLargeScreen, reset } = useContext(disckoContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [animation, setAnimation] = useState<'visible' | 'invisible'>('visible');

  const formZoneRef = useRef<HTMLDivElement>(null);
  const formzone = document.getElementById('formzone');

  const scrollPercentage: MutableRefObject<number> = useRef<number>(0);

  React.useEffect(() => {
    if (formzone !== null) {
      scrollPercentage.current = formzone.scrollTop / (formzone.scrollHeight - formzone.clientHeight);
    }
  }, [formzone?.scrollTop, formzone?.scrollHeight, formzone?.clientHeight, formzone]);

  // reset all the form values when entering this page
  useEffect(() => {
    reset();
  }, []);

  const [mobileStep, setMobileStep] = useState<number>(1);

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(true);
  const [isCGUModalOpen, setIsCGUModalOpen] = useState<boolean>(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);
  const [swap, setSwap] = useState<boolean>(false);
  const {
    register,
    watch,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitted },
  } = useForm<ClientInfoFormModel>({
    resolver: yupResolver(schema),
  });

  const watchCompanyName: string = watch('companyName');
  const watchContactLastname: string = watch('contactLastname');
  const watchContactFirstname: string = watch('contactFirstname');

  const watchContactEmail: string | undefined = watch('contactEmail');

  let isFormValid: boolean = true;

  let isMobileFormValid: boolean = true;

  if (
    watchCompanyName === undefined ||
    watchCompanyName === '' ||
    watchContactLastname === undefined ||
    watchContactLastname === '' ||
    watchContactFirstname === undefined ||
    watchContactFirstname === '' ||
    watchContactEmail === undefined ||
    watchContactEmail === ''
  ) {
    isFormValid = false;
  }

  if (
    (mobileStep >= 1 &&
      (watchContactFirstname === undefined ||
        watchContactFirstname === '' ||
        watchContactLastname === undefined ||
        watchContactLastname === '')) ||
    (mobileStep >= 2 && (watchCompanyName === undefined || watchCompanyName === '')) ||
    (mobileStep >= 4 && (watchContactEmail === undefined || watchContactEmail === ''))
  ) {
    isMobileFormValid = false;
  }

  /* ------------------------------------------ Go to the top mobile steps ------------------------------------------ */

  useEffect(() => {
    if (formZoneRef.current !== null) {
      formZoneRef.current.scrollTop = 0;
    }
  }, [mobileStep]);

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const [clientInfo, setClientInfo] = useState<ClientInfos>();

  const { data, status, isRefetching } = useQuery({
    queryKey: ['requirement'],
    queryFn: async () => {
      return await initRequirement({
        companyName: clientInfo!.companyName,
        companyWebsite: clientInfo!.companyWebsite !== undefined ? clientInfo!.companyWebsite : '',
        contactLastname: clientInfo!.contactLastname,
        contactFirstname: clientInfo!.contactFirstname,
        contactPhone: clientInfo!.contactPhone !== undefined ? clientInfo!.contactPhone : '',
        contactEmail: clientInfo!.contactEmail,
      });
    },
    enabled: clientInfo !== undefined,
    cacheTime: 0,
  });

  useEffect(() => {
    if (status === 'error') setIsLoading(false);
    if (status !== 'success' || (isRefetching !== undefined && isRefetching)) return;

    setRequirementParams(data);

    setIsLoading(false);
    // Debute l'effet pour transitionner vers le nouveaux formulaire
    setSwap(true);
    // Change de formulaire apres 1000ms
    delay(1000)
      .then(() => {
        if (setNextStep != null) setNextStep(data.step);
        return true;
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
      });
  }, [status, data, isRefetching]);

  const onSubmitForm: SubmitHandler<ClientInfoFormModel> = async (data) => {
    setClientInfo({
      companyName: data.companyName,
      companyWebsite: data.companyWebsite !== undefined ? data.companyWebsite : '',
      contactLastname: data.contactLastname,
      contactFirstname: data.contactFirstname,
      contactPhone: data.contactPhone !== undefined ? data.contactPhone : '',
      contactEmail: data.contactEmail,
    });
    setIsLoading(true);
  };

  function handleNextMobileStep(): void {
    if (isMobileFormValid && !isLoading && mobileStep < 4) {
      setAnimation('invisible');
      delay(700)
        .then(() => {
          setMobileStep(mobileStep + 1);
          setAnimation('visible');
          return true;
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
        });
    }
  }

  const [mobileFormInputToFocus, setMobileFormInputToFocus] = useState<
    'companyWebsite' | 'contactPhone' | 'companyName' | 'contactLastname' | 'contactFirstname' | 'contactEmail'
  >();

  useEffect(() => {
    if (mobileFormInputToFocus !== undefined) {
      setFocus(mobileFormInputToFocus);
    }
  }, [mobileStep]);

  return (
    <div className="flex flex-col flex-grow z-10 overflow-auto">
      <Modal title="Bienvenue !" isOpen={isWelcomeModalOpen} setIsOpen={setIsWelcomeModalOpen}>
        <Text
          size="lg"
          position="justify"
          content="Laissez-vous guider par des questions personnalisées afin de mieux définir votre projet !"
        />
        <Text
          size="lg"
          position="justify"
          content="Cette solution génère des questions sur mesure pour affiner la synthèse finale qui nous sera envoyée. Assurez-vous d’aller au bout du questionnaire afin de générer la synthèse (durée moyenne : 3 minutes)."
        />
        <Text
          size="lg"
          position="justify"
          textDecoration="italic"
          content="En utilisant cette solution, vous acceptez que vos données soient temporairement utilisées par l’algorithme."
        />
        <Button
          content="C'est parti!"
          width="full"
          onClick={() => {
            setIsWelcomeModalOpen(false);
          }}
          borderColor="black"
          borderWidth="2"
          borderWidthOnHover="2"
          borderColorOnHover="black"
          bgColorOnHover="white"
          textColorOnHover="black"
        />
      </Modal>
      <Modal title="Conditions générales d'utilisation" isOpen={isCGUModalOpen} setIsOpen={setIsCGUModalOpen}>
        <FrontOfficeCGU />
      </Modal>
      <Modal title="Politique de confidentialité" isOpen={isPolicyModalOpen} setIsOpen={setIsPolicyModalOpen}>
        <PrivacyPolicyWidget />
      </Modal>
      <form
        className={concatClassNames(
          'flex flex-col gap-6 px-8',
          'transition all duration-700',
          swap ? 'opacity-0' : 'opacity-100',
          'items-stretch flex-grow',
          'overflow-auto',
          'justify-center',
        )}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */}
        {isLargeScreen === true && (
          <>
            <br />

            <div
              id="formzone"
              ref={formZoneRef}
              className={concatClassNames(
                'flex flex-col gap-4',
                'bg-white/70',
                'p-8 h-full overflow-auto',
                'rounded-lg',
                'relative',
              )}
            >
              <Text content="Commençons par quelques informations sur vous !" size="xl" weight="bold" />
              <div className="grid grid-cols-2 gap-6">
                <Input
                  label="Quel est votre prénom ?"
                  placeholder="Jeanne"
                  isMandatory
                  {...register('contactFirstname')}
                  error={errors.contactFirstname != null ? errors.contactFirstname.message : undefined}
                />
                <Input
                  label="Quel est votre nom ?"
                  placeholder="Forgeau"
                  uppercase
                  isMandatory
                  {...register('contactLastname')}
                  error={errors.contactLastname != null ? errors.contactLastname.message : undefined}
                />
              </div>
              <Input
                rows={1}
                isMandatory
                label="Quel est le nom de votre entreprise ou de votre projet ?"
                placeholder="Discko"
                {...register('companyName')}
                error={errors.companyName != null ? errors.companyName.message : undefined}
              />
              <Input
                rows={1}
                placeholder="https://discko.io"
                label="Si vous en avez un, quelle est l'adresse de votre site web ?"
                {...register('companyWebsite')}
                inputMode="url"
                error={errors.companyWebsite != null ? errors.companyWebsite.message : undefined}
              />
              <Text content="Et pour que l'on puisse vous recontacter... 😊" size="xl" weight="bold" />
              <div className="grid grid-cols-2 gap-6">
                <Input
                  rows={1}
                  label="Email"
                  placeholder="jeanne@discko.io"
                  isMandatory
                  inputMode="email"
                  {...register('contactEmail')}
                  error={errors.contactEmail != null && isSubmitted ? errors.contactEmail.message : undefined}
                />
                <Input
                  rows={1}
                  label="Téléphone"
                  placeholder="06 12 34 56 78"
                  {...register('contactPhone')}
                  inputMode="tel"
                  error={errors.contactPhone != null ? errors.contactPhone.message : undefined}
                />
              </div>
            </div>

            <div className="flex justify-end items-center">
              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: 'En continuant, vous acceptez nos ',
                    color: 'black',
                    size: 'sm',
                    whitespace: 'pre',
                  },
                  {
                    contentType: 'link',
                    position: 'justify',
                    content: "Conditions Générales d'Utilisation",
                    size: 'sm',
                    weight: 'bold',
                    textDecoration: 'underline-4',
                    onClick: () => {
                      setIsCGUModalOpen(true);
                    },
                  },
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: ' et notre ',
                    color: 'black',
                    size: 'sm',
                    whitespace: 'pre',
                  },
                  {
                    contentType: 'link',
                    position: 'justify',
                    content: 'Politique de Confidentialité',
                    textDecoration: 'underline-4',
                    size: 'sm',
                    weight: 'bold',
                    onClick: () => {
                      setIsPolicyModalOpen(true);
                    },
                  },
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: '.',
                    color: 'black',
                    size: 'sm',
                  },
                ]}
              ></RichText>
            </div>

            <div className="flex flex-row gap-10 justify-around items-center ">
              <Stepsbar completionFrom="0%" completionTo="25%" animate></Stepsbar>
              <Button
                content="Suivant"
                width="lg"
                type="submit"
                disabled={!isFormValid}
                borderWidthOnHover="2"
                borderColor={isFormValid ? 'black' : 'gray-50'}
                borderWidth="2"
                borderColorOnHover="black"
                bgColorOnHover="white"
                textColorOnHover="black"
              />
            </div>
          </>
        )}
        {isLargeScreen === false && (
          <>
            <div
              className={concatClassNames(
                'flex flex-col gap-4',
                'bg-white/70',
                'px-8 py-4 overflow-auto',
                'rounded-lg',
                'transition-all duration-700',
                animation === 'invisible' ? 'opacity-0' : 'opacity-100',
              )}
            >
              <div
                id="formzone"
                ref={formZoneRef}
                className={concatClassNames('flex flex-col gap-10', 'overflow-auto', 'items-stretch flex-grow')}
              >
                {mobileStep === 1 && (
                  <>
                    <Text
                      content="Commençons par quelques informations sur vous !"
                      size="xl"
                      weight="bold"
                      position="left"
                    />
                    <Input
                      label="Quel est votre prénom ?"
                      placeholder="Jeanne"
                      isMandatory
                      {...register('contactFirstname')}
                      error={errors.contactFirstname != null ? errors.contactFirstname.message : undefined}
                      onSubmit={() => {
                        setFocus('contactLastname');
                      }}
                    />
                    <Input
                      label="Quel est votre nom ?"
                      placeholder="Forgeau"
                      uppercase
                      isMandatory
                      {...register('contactLastname')}
                      error={errors.contactLastname != null ? errors.contactLastname.message : undefined}
                      onSubmit={() => {
                        handleNextMobileStep();
                        setMobileFormInputToFocus('companyName');
                      }}
                    />
                  </>
                )}
                {mobileStep === 2 && (
                  <>
                    <Input
                      rows={1}
                      isMandatory
                      label="Quel est le nom de votre entreprise ou de votre projet ?"
                      placeholder="Discko"
                      {...register('companyName')}
                      error={errors.companyName != null ? errors.companyName.message : undefined}
                      onSubmit={() => {
                        handleNextMobileStep();
                        setMobileFormInputToFocus('companyWebsite');
                      }}
                    />
                  </>
                )}
                {mobileStep === 3 && (
                  <>
                    <Input
                      rows={1}
                      placeholder="https://discko.io"
                      label="Si vous en avez un, quelle est l'adresse de votre site web ?"
                      {...register('companyWebsite')}
                      inputMode="url"
                      error={errors.companyWebsite != null ? errors.companyWebsite.message : undefined}
                      onSubmit={() => {
                        handleNextMobileStep();
                        setMobileFormInputToFocus('contactEmail');
                      }}
                    />
                  </>
                )}
                {mobileStep === 4 && (
                  <>
                    <Text
                      content="Et pour que l'on puisse vous recontacter... 😊"
                      size="xl"
                      weight="bold"
                      position="left"
                    />
                    <Input
                      rows={1}
                      label="Email"
                      placeholder="jeanne@discko.io"
                      isMandatory
                      inputMode="email"
                      {...register('contactEmail')}
                      error={errors.contactEmail != null && isSubmitted ? errors.contactEmail.message : undefined}
                      onSubmit={() => {
                        setFocus('contactPhone');
                      }}
                    />
                    <Input
                      rows={1}
                      label="Téléphone"
                      placeholder="06 12 34 56 78"
                      {...register('contactPhone')}
                      inputMode="tel"
                      error={errors.contactPhone != null ? errors.contactPhone.message : undefined}
                    />
                  </>
                )}
              </div>
              {isMobileFormValid && !isLoading && mobileStep < 4 && (
                <div
                  className="flex justify-end "
                  onClick={() => {
                    handleNextMobileStep();
                  }}
                >
                  {getIcon('arrowRight', 'black', 'lg')}
                </div>
              )}
              {isMobileFormValid && !isLoading && mobileStep === 4 && (
                <button className="flex justify-end " type="submit" disabled={!isFormValid}>
                  {getIcon('arrowRight', 'black', 'lg')}
                </button>
              )}
              {!isMobileFormValid && !isLoading && (
                <div className="flex justify-end ">{getIcon('arrowRight', 'gray-100', 'lg')}</div>
              )}
              {isLoading && (
                <div className={concatClassNames('flex justify-end')}>
                  <div className="h-fit w-fit animate-spin">{getIcon('spinCircle', 'gray-200', 'lg')}</div>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
}
