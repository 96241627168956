import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto } from 'api';

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export function MobileStartRequirementPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const { companyService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();

  async function getCompanyIdByUserId(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>(
    ['company', user?.sub],
    getCompanyIdByUserId,
  );

  function handleOnClickStartRequirement(): void {
    if (companyStatus !== 'success') return;
    navigate('/new?companyid=' + companyData.id);
  }

  return (
    <BasePage>
      <div className="flex flex-col gap-4 h-full">
        <div
          className={concatClassNames(
            'shadow-2xl',
            'p-2',
            'h-fit py-4',
            'flex flex-col gap-4 items-center align-middle justify-center',
            'bg-white',
          )}
        >
          <Text
            position="center"
            size="sm"
            weight="bold"
            content="Vous avez un prospect au téléphone ou face à vous ?"
          />
          <Text position="center" size="sm" content="Générez une synthèse de son besoin en quelques minutes." />

          {companyStatus === 'success' && companyData.credits < 1 && !companyData.isSubscribed && (
            <p className={concatClassNames('text-red-500')}>{'Rechargez vos crédits pour continuer'}</p>
          )}
          <Button
            height="fit"
            width="fit"
            content="Démarrer"
            bgColor="blue-400"
            bgColorOnHover="blue-300"
            disabled={companyStatus === 'success' && companyData.credits < 1 && !companyData.isSubscribed}
            onClick={handleOnClickStartRequirement}
          />
        </div>
        <div
          className={concatClassNames(
            'shadow-2xl',
            'p-2',
            'h-fit py-4',
            'flex flex-col gap-4 items-center align-middle justify-center',
            'bg-white',
          )}
        >
          <Text
            position="center"
            size="sm"
            weight="bold"
            content="🗓️ Vous avez programmé un premier rendez-vous avec un prospect ?"
          />
          <Text
            position="center"
            size="sm"
            content="Aidez-le à préparer cet échange et personnalisez votre accompagnement."
          />
          <div className="relative flex flex-col items-center">
            {companyStatus === 'success' && companyData.credits < 1 && !companyData.isSubscribed && (
              <p className={concatClassNames('text-red-500')}>{'Rechargez vos crédits pour continuer'}</p>
            )}
            <Button
              height="fit"
              width="fit"
              content="Copier le lien"
              bgColor="blue-400"
              bgColorOnHover="blue-300"
              iconName="link"
              iconPosition="left"
              disabled={companyStatus === 'success' && companyData.credits < 1 && !companyData.isSubscribed}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${window.location.protocol}//${window.location.host}/new/?companyid=${companyData!.id}`,
                );
                toast.success('Le lien a bien été copié.');
              }}
            />
          </div>
        </div>
      </div>
    </BasePage>
  );
}
