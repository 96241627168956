import React, { Fragment, useState } from 'react';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';

import concatClassNames from 'utils/classNames';

/* ------------------------------------------------- Props values ------------------------------------------------- */
export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const avatarSizeStyle: Record<string, string> = {
  xs: 'h-8 w-8',
  sm: 'h-10 w-10',
  md: 'h12 w-12',
  lg: 'h-14 w-14',
  xl: 'h-20 w-20',
};

/* ----------------------------------------------------- Props ---------------------------------------------------- */
type UserAvatarProps =
  | {
      imgUrl: string | undefined;
      size: AvatarSize;
      name: string | undefined;
      email: string | undefined;
      loading?: false;
    }
  | {
      imgUrl?: never;
      size: AvatarSize;
      name?: never;
      email?: never;
      loading: true;
    };

export function UserAvatar({ size = 'sm', imgUrl, name, email, loading = false }: UserAvatarProps): JSX.Element {
  /* ---------------------------------------------------- states ---------------------------------------------------- */
  const { logout } = useAuth0();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* ----------------------------------------------- handle functions ----------------------------------------------- */
  async function handleOnclick(): Promise<void> {
    await logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }
  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      {loading ? (
        <div className={concatClassNames(avatarSizeStyle[size], 'rounded-full', 'bg-gray-200', 'animate-pulse')} />
      ) : (
        <div className={concatClassNames('relative  h-14', 'flex')}>
          <Menu>
            <Menu.Button>
              <img
                className={concatClassNames(avatarSizeStyle[size], 'rounded-full')}
                src={imgUrl}
                alt="profile picture"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={concatClassNames(
                  'absolute',
                  isLargeScreen === true ? 'left-10 bottom-10' : 'right-0 top-10',
                  'w-56',
                  'origin-top-right',
                  'divide-y divide-gray-25',
                  'rounded-md ',
                  'bg-white ',
                  'shadow-lg ',
                  'ring-1 ring-black ring-opacity-5',
                  'z-10',
                  'focus:outline-none',
                )}
              >
                <div className="flex flex-col px-1 py-1 gap-2 items-center m-4">
                  <img
                    className={concatClassNames(avatarSizeStyle[size], 'rounded-full')}
                    src={imgUrl}
                    alt="profile picture"
                  />
                  <div>
                    <p className={concatClassNames('text-md', 'text-center')}>{name}</p>
                    <p className={concatClassNames('text-sm', 'text-center', ' text-gray-200')}>{email}</p>
                    <p className={concatClassNames('text-sm', 'text-center', ' text-gray-200')}>{}</p>
                  </div>
                </div>
                <div className="px-3 py-3">
                  <Menu.Item key={uuid()}>
                    {({ active }) => {
                      return (
                        <button
                          className={concatClassNames(
                            'bg-gradient-to-r from-blue-400 to-blue-200',
                            ' text-white',
                            'flex',
                            ' w-full',
                            ' justify-center',
                            ' rounded-md',
                            ' px-2 py-2',
                            ' text-sm',
                          )}
                          onClick={handleOnclick}
                        >
                          Log out
                        </button>
                      );
                    }}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      )}
    </>
  );
}
