import React, { type ChangeEventHandler, forwardRef, useContext } from 'react';
import uuid from 'react-uuid';

import { disckoContext } from 'pages/RequirementForm/DisckoPage';

import { Text, type TextSize, textSizeStyle } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export interface RadioButtonsProps {
  name?: string;
  values: string[];
  valuesDisplay?: string[];
  isComplete?: boolean;
  error?: string;
  textSize?: TextSize;
  label?: string;
  labelSize?: TextSize;
  information?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  selected?: string;
  loading?: boolean;
  isEditable?: boolean;
  isMandatory?: boolean;
  disabled?: boolean;
}

function RadioButtons(
  {
    name,
    values = [],
    valuesDisplay,
    isComplete = true,
    error = '',
    textSize = 'base',
    label,
    labelSize = 'xl',
    onChange,
    selected,
    loading = false,
    isMandatory = false,
    disabled = false,
  }: RadioButtonsProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const { isLargeScreen } = useContext(disckoContext);

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  if (isLargeScreen === undefined) return <div></div>;
  return loading ? (
    <div
      className={concatClassNames(
        'w-full',
        'h-16',
        'flex',
        'flex-row',
        'rounded-lg',
        'p-1 px-2',
        'bg-gray-50',
        'animate-pulse',
      )}
    ></div>
  ) : (
    <div className={concatClassNames('flex', 'flex-col', 'gap-4')}>
      <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
        {label !== undefined && (
          <div
            className={concatClassNames('flex', isLargeScreen ? 'flex-row gap-2 items-center' : 'flex-col items-start')}
          >
            <div className={concatClassNames('flex flex-row', 'items-center', 'gap-2')}>
              <Text position="left" size={labelSize} color="black" weight="bold" content={label} />
              {isMandatory && <Text content="*" size={labelSize} color="error-500" position="left" weight="light" />}
            </div>
            <div>
              {isMandatory && (
                <Text content=" (Obligatoire)" size="sm" color="error-500" position="left" weight="light" />
              )}
              {!isMandatory && <Text content=" (Facultatif)" size="sm" color="black" position="left" weight="light" />}
            </div>
          </div>
        )}
      </div>
      <div className={concatClassNames('w-full', 'flex flex-wrap', 'gap-2', 'rounded-lg')}>
        {values.map((value: string, index) => {
          return (
            <div
              key={uuid()}
              className={concatClassNames(
                'flex',
                'flex-row',
                'w-fit',
                selected === value ? 'bg-black' : 'bg-gray-50',
                'rounded-md',
                'p-1 px-2',
              )}
            >
              <label
                className={concatClassNames(
                  'flex',
                  'w-full',
                  'text-left',
                  selected === value ? 'text-white' : 'text-gray-500',
                  'cursor-pointer',
                  'items-center',
                  'justify-center',
                  textSizeStyle[textSize],
                )}
              >
                <div className={concatClassNames('flex flex-row', 'gap-2')}>
                  {valuesDisplay !== undefined ? valuesDisplay[index] : value}
                </div>

                <input
                  type="radio"
                  id={value}
                  name={name}
                  value={value}
                  className={concatClassNames('hidden', 'opacity-0', 'cursor-pointer')}
                  checked={selected === value}
                  onChange={onChange}
                  ref={ref}
                  disabled={disabled}
                />
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default forwardRef<HTMLInputElement, RadioButtonsProps>(RadioButtons);
