import { AdminApi, type GetDisckoCompanyDto } from 'api';

export class AdminService {
  private readonly adminApi: AdminApi;

  constructor() {
    this.adminApi = new AdminApi();
  }

  public async createCompany(accessToken: string, companyName: string): Promise<GetDisckoCompanyDto> {
    return await this.adminApi.disckoAdminControllerCreateCompany(
      { companyName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async addUserToCompany(
    accessToken: string,
    companyId: string,
    userEmail: string,
    userName: string,
  ): Promise<void> {
    await this.adminApi.disckoAdminControllerAddUserToCompany(
      { companyId, userEmail, userName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
