import React, { type ChangeEventHandler, forwardRef, useState } from 'react';
import uuid from 'react-uuid';

import { Text, type TextSize, textSizeStyle } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export interface RadioButtonsProps {
  name?: string;
  values: Array<{ id: string; description: string }>;
  valuesDisplay?: string[];
  textSize?: TextSize;
  label?: string;
  labelSize?: TextSize;
  information?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  selected?: string;
  loading?: boolean;
  isEditable?: boolean;
  disabled?: boolean;
}

function RadioButtons(
  {
    name,
    values = [],
    valuesDisplay,
    textSize = 'base',
    label,
    labelSize = 'lg',
    onChange,
    selected,
    loading = false,
    disabled = false,
  }: RadioButtonsProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return loading ? (
    <div
      className={concatClassNames(
        'w-full',
        'h-16',
        'flex',
        'flex-row',
        'rounded-lg',
        'p-1 px-2',
        'bg-gray-50',
        'animate-pulse',
      )}
    ></div>
  ) : (
    <div className={concatClassNames('flex', 'flex-col', 'gap-4')}>
      <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
        {label !== undefined && (
          <div
            className={concatClassNames(
              'flex',
              isLargeScreen === true ? 'flex-row gap-2 items-center' : 'flex-col items-start',
            )}
          >
            <div className={concatClassNames('flex flex-row', 'items-center', 'gap-2')}>
              <Text position="left" size={labelSize} color="black" weight="bold" content={label} />
            </div>
          </div>
        )}
      </div>
      <div className={concatClassNames('w-full', 'flex flex-wrap', 'gap-2', 'rounded-lg')}>
        {values.map((value: { id: string; description: string }, index) => {
          return (
            <div className="flex flex-col items-start gap-2" key={uuid()}>
              <div
                key={uuid()}
                className={concatClassNames(
                  'flex',
                  'flex-row',
                  'w-fit',
                  selected === value.id ? '' : '',
                  'rounded-md',
                  'p-1 px-2',
                )}
              >
                <label
                  className={concatClassNames(
                    'flex flex-row gap-4',
                    'w-full',
                    'text-left',
                    selected === value.id ? 'text-black font-bold' : 'text-gray-400',
                    !disabled ? 'cursor-pointer' : '',
                    'items-center',
                    'justify-center',
                    textSizeStyle[textSize],
                  )}
                >
                  <input
                    type="radio"
                    id={value.id}
                    name={name}
                    value={value.id}
                    className={concatClassNames(!disabled ? 'cursor-pointer' : 'cursor-not-allowed')}
                    checked={selected === value.id}
                    onChange={onChange}
                    ref={ref}
                    disabled={disabled}
                  />
                  <div className={concatClassNames('flex flex-row', 'gap-2', textSizeStyle[labelSize])}>
                    {valuesDisplay !== undefined ? valuesDisplay[index] : value.id}
                  </div>
                </label>
              </div>

              <div className={concatClassNames(isLargeScreen === true ? 'indent-10' : '', 'text-sm')}>
                {value.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default forwardRef<HTMLInputElement, RadioButtonsProps>(RadioButtons);
