import { DisckoUsersApi, type GetDisckoUserDto, type GetDisckoUsersDto } from 'api';

export class UserService {
  private readonly userClient: DisckoUsersApi;

  constructor() {
    this.userClient = new DisckoUsersApi();
  }

  public async findMyCompanyUsers(accessToken: string): Promise<GetDisckoUsersDto> {
    return await this.userClient.disckoUserControllerFindMyCompanyUsers({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  }

  public async getUserInfos(accessToken: string): Promise<GetDisckoUserDto> {
    return await this.userClient.disckoUserControllerGetUser({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  }

  public async updateUserInfos(accessToken: string, isNotificationsActivated: boolean): Promise<GetDisckoUserDto> {
    return await this.userClient.disckoUserControllerUpdateNotifications(
      {
        updateNotificationsDisckoUserDto: {
          getNotifications: isNotificationsActivated,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
