import { MaintenanceContext } from 'App';

import React, { useContext } from 'react';

import { formatDate } from 'pages/RequirementsPages/RequirementsPage';

import concatClassNames from 'utils/classNames';

export function MaintenanceHeader(): JSX.Element {
  const { maintenanceInfos } = useContext(MaintenanceContext);

  if (maintenanceInfos.state !== 'planned') return <></>;

  return (
    <div
      id="trial-header"
      className={concatClassNames(
        'top-0 left-0 right-0',
        'flex',
        'py-1.5 px-2',
        'bg-secondary-500',
        'justify-center',
        'items-center',
        'text-white text-md text-center',
      )}
    >
      <p>
        {'Une maintenance est planifiée du '} {formatDate(maintenanceInfos.beginningDate)} {'au'}{' '}
        {formatDate(maintenanceInfos.endDate)}
        {'. La plateforme et les services seront indisponibles durant cette période.'}
      </p>
    </div>
  );
}
