import React, { useEffect, useState } from 'react';

import concatClassNames from 'utils/classNames';

type Completion = '25%' | '50%' | '75%' | '100%' | '0%';

const completionType: Record<Completion, string> = {
  '100%': 'w-full',
  '25%': 'w-1/4',
  '50%': 'w-1/2',
  '75%': 'w-3/4',
  '0%': 'w-0',
};

export interface StepsbarProps {
  completionTo: Completion;
  completionFrom: Completion;
  animate?: boolean; // Ajouter une prop pour activer l'animation
}

export function Stepsbar({ completionTo, completionFrom, animate = true }: StepsbarProps): JSX.Element {
  const [widthClass, setWidthClass] = useState(completionType[completionFrom]); // Commence par une largeur nulle

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setWidthClass(completionType[completionTo]);
      }, 100); // Délai pour démarrer l'animation (ajustez selon vos besoins)
      return () => {
        clearTimeout(timer);
      };
    } else {
      setWidthClass(completionType[completionTo]);
    }
  }, [completionTo, animate]);

  return (
    <div className="flex rounded-full h-[50%] bg-gray-50 w-full ">
      <div
        className={concatClassNames(
          'flex h-full rounded-l-full transition-width duration-1000 ease-in-out bg-black ',
          completionTo === '100%' ? 'rounded-r-full' : 'rounded-r-none',
          widthClass,
        )}
      >
        .
      </div>
    </div>
  );
}
