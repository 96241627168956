import React from 'react';

import ApiIcon from 'icons/ApiIcon';
import ArrowDownIcon from 'icons/ArrowDown';
import ArrowRightIcon from 'icons/ArrowRightIcon';
import ArrowUpIcon from 'icons/ArrowUp';
import BatchIcon from 'icons/BatchIcon';
import BellIcon from 'icons/BellIcon';
import BooksIcon from 'icons/BooksIcon';
import BoxIcon from 'icons/BoxIcon';
import ButtonIcon from 'icons/ButtonIcon';
import CheckCircleIcon from 'icons/CheckCircleIcon';
import CheckIcon from 'icons/CheckIcon';
import ChevronDownIcon from 'icons/ChevronDownIcon';
import ChevronLeftIcon from 'icons/ChevronLeftIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';
import ChevronUpIcon from 'icons/ChevronUpIcon';
import CircleExclamationIcon from 'icons/CircleExclamationIcon';
import ComponentIcon from 'icons/ComponentIcon';
import DatabaseIcon from 'icons/DatabaseIcon';
import DateInputIcon from 'icons/DateInputIcon';
import EditIcon from 'icons/EditIcon';
import { ExternalLinkIcon } from 'icons/ExternalLinkIcon';
import EyeIcon from 'icons/EyeIcon';
import FeatherIcon from 'icons/FeatherIcon';
import FeatureIcon from 'icons/FeatureIcon';
import FrontEndIcon from 'icons/FrontEndIcon';
import GripDotsVertical from 'icons/GripDotsVertical';
import HomeIcon from 'icons/HomeIcon';
import HtmlIcon from 'icons/HtmlIcon';
import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';
import HardDriveIcon from 'icons/IconHardDrive';
import SearchIcon from 'icons/IconSearch';
import IconesIcon from 'icons/IconesIcon';
import AlertTriangleIcon from 'icons/IconsAlertTriangle';
import ArrowUpFromSquareIcon from 'icons/IconsArrowUpFromSquare';
import CircleIcon from 'icons/IconsCircle';
import ClipboardIcon from 'icons/IconsClipboard';
import ClockIcon from 'icons/IconsClock';
import SelectIcon from 'icons/IconsSelect';
import UpRightIcon from 'icons/IconsUpRight';
import Image2Icon from 'icons/Image2Icon';
import ImageIcon from 'icons/ImageIcon';
import InformationIcon from 'icons/InformationIcon';
import InputIcon from 'icons/InputIcon';
import LightbulbOnIcon from 'icons/LightbulbOnIcon';
import LinkIcon from 'icons/LinkIcon';
import ListIcon from 'icons/ListIcon';
import MessageIcon from 'icons/MessageIcon';
import MoreIcon from 'icons/MoreIcon';
import PenLineIcon from 'icons/PenLineIcon';
import PersonaIcon from 'icons/PersonaIcon';
import PersonaeIcon from 'icons/PersonaeIcon';
import PlugIcon from 'icons/PlugIcon';
import PlusIcon from 'icons/PlusIcon';
import ProductIcon from 'icons/ProductIcon';
import PuzzleIcon from 'icons/PuzzleIcon';
import ResetIcon from 'icons/ResetIcon';
import SaveIcon from 'icons/SaveIcon';
import SchemaIcon from 'icons/SchemaIcon';
import SettingsIcon from 'icons/SettingsIcon';
import SignatureIcon from 'icons/SignatureIcon';
import SlashIcon from 'icons/SlashIcon';
import SortIcon from 'icons/SortIcon';
import SpinCircleIcon from 'icons/SpinCircleIcon';
import StarIcon from 'icons/StarIcon';
import StatusIcon from 'icons/StatusIcon';
import TextIcon from 'icons/TextIcon';
import TitleIcon from 'icons/TitleIcon';
import TrashIcon from 'icons/TrashIcon';
import XIcon from 'icons/XIcon';
import ZoneIcon from 'icons/ZoneIcon';
import UiIcon from 'icons/ui-icon';

export default function getIcon(name: IconName, color: IconColor, size: IconSize, strokeWidth = 2): JSX.Element {
  switch (name) {
    case 'product':
      return <ProductIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'arrowRight':
      return <ArrowRightIcon color={color} size={size} />;
    case 'arrowDown':
      return <ArrowDownIcon color={color} size={size} />;
    case 'arrowUp':
      return <ArrowUpIcon color={color} size={size} />;
    case 'check':
      return <CheckIcon color={color} size={size} />;
    case 'chevronUp':
      return <ChevronUpIcon color={color} size={size} />;
    case 'chevronDown':
      return <ChevronDownIcon color={color} size={size} />;
    case 'chevronLeft':
      return <ChevronLeftIcon color={color} size={size} />;
    case 'chevronRight':
      return <ChevronRightIcon color={color} size={size} />;
    case 'home':
      return <HomeIcon color={color} size={size} />;
    case 'more':
      return <MoreIcon color={color} size={size} />;
    case 'status':
      return <StatusIcon color={color} size={size} />;
    case 'plus':
      return <PlusIcon color={color} size={size} />;
    case 'persona':
      return <PersonaIcon color={color} size={size} />;
    case 'settings':
      return <SettingsIcon color={color} size={size} />;
    case 'component':
      return <ComponentIcon color={color} size={size} />;
    case 'puzzle':
      return <PuzzleIcon color={color} size={size} />;
    case 'personae':
      return <PersonaeIcon color={color} size={size} />;
    case 'sort':
      return <SortIcon color={color} size={size} />;
    case 'alertTriangle':
      return <AlertTriangleIcon color={color} size={size} />;
    case 'star':
      return <StarIcon color={color} size={size} />;
    case 'lightbulbOn':
      return <LightbulbOnIcon color={color} size={size} />;
    case 'reset':
      return <ResetIcon color={color} size={size} />;
    case 'books':
      return <BooksIcon color={color} size={size} />;
    case 'schema':
      return <SchemaIcon color={color} size={size} />;
    case 'feature':
      return <FeatureIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'externalLink':
      return <ExternalLinkIcon color={color} size={size} />;
    case 'x':
      return <XIcon color={color} size={size} />;
    case 'frontEnd':
      return <FrontEndIcon color={color} size={size} />;
    case 'database':
      return <DatabaseIcon color={color} size={size} />;
    case 'batch':
      return <BatchIcon color={color} size={size} />;
    case 'penLine':
      return <PenLineIcon color={color} size={size} />;
    case 'image':
      return <ImageIcon color={color} size={size} />;
    case 'api':
      return <ApiIcon color={color} size={size} />;
    case 'edit':
      return <EditIcon color={color} size={size} />;
    case 'save':
      return <SaveIcon color={color} size={size} />;
    case 'checkCircle':
      return <CheckCircleIcon color={color} size={size} />;
    case 'feather':
      return <FeatherIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'title':
      return <TitleIcon color={color} size={size} />;
    case 'input':
      return <InputIcon color={color} size={size} />;
    case 'paperPlane':
      return <BellIcon color={color} size={size} />;
    case 'trash':
      return <TrashIcon color={color} size={size} />;
    case 'spinCircle':
      return <SpinCircleIcon color={color} size={size} />;
    case 'search':
      return <SearchIcon color={color} size={size} />;
    case 'hardDrive':
      return <HardDriveIcon color={color} size={size} />;
    case 'button':
      return <ButtonIcon color={color} size={size} />;
    case 'plug':
      return <PlugIcon color={color} size={size} />;
    case 'dateInput':
      return <DateInputIcon color={color} size={size} />;
    case 'image2':
      return <Image2Icon color={color} size={size} />;
    case 'list':
      return <ListIcon color={color} size={size} />;
    case 'text':
      return <TextIcon color={color} size={size} />;
    case 'box':
      return <BoxIcon color={color} size={size} />;
    case 'html':
      return <HtmlIcon color={color} size={size} />;
    case 'zone':
      return <ZoneIcon color={color} size={size} />;
    case 'icons':
      return <IconesIcon color={color} size={size} />;
    case 'slash':
      return <SlashIcon color={color} size={size} />;
    case 'gripDotsVertical':
      return <GripDotsVertical color={color} size={size} />;
    case 'Ui':
      return <UiIcon color={color} size={size} />;
    case 'eye':
      return <EyeIcon color={color} size={size} />;
    case 'upRight':
      return <UpRightIcon color={color} size={size} />;
    case 'clock':
      return <ClockIcon color={color} size={size} />;
    case 'select':
      return <SelectIcon color={color} size={size} />;
    case 'clipboard':
      return <ClipboardIcon color={color} size={size} />;
    case 'information':
      return <InformationIcon color={color} size={size} />;
    case 'message':
      return <MessageIcon color={color} size={size} />;
    case 'signature':
      return <SignatureIcon color={color} size={size} />;
    case 'circle':
      return <CircleIcon color={color} size={size} />;
    case 'circleExclamation':
      return <CircleExclamationIcon color={color} size={size} />;
    case 'arrowUpFromSquare':
      return <ArrowUpFromSquareIcon color={color} size={size} />;
    case 'link':
      return <LinkIcon color={color} size={size} />;
    default:
      return <div>error</div>;
  }
}
