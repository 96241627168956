/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import {
  type GetDisckoCompanyDto,
  type UpdateRequirementsParametersDto,
  type UpdateRequirementsParametersDtoQuestionFocusEnum,
} from 'api';
import * as Yup from 'yup';

import React, { useContext, useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { type QueryClient, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';

import BackOfficeRadioButtons from 'components/RadioButtons/BackOfficeRadioButtons';
import { MultiSelectRadioButtons } from 'components/RadioButtons/MultiSelectRadioButtons';
import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

interface CustomizationFormModel {
  promptOrientation: UpdateRequirementsParametersDtoQuestionFocusEnum;
  formQuestions: string[];
  urlRedirect: string | undefined;
}

const schema: Yup.ObjectSchema<CustomizationFormModel> = Yup.object().shape({
  promptOrientation: Yup.mixed<UpdateRequirementsParametersDtoQuestionFocusEnum>().required('Champ obligatoire'),
  formQuestions: Yup.array().required('Champ obligatoire'),
  urlRedirect: Yup.string()
    .test('isRedirectUrlNeeded', 'Champ obligatoire', (value, { options }) => {
      if (options.context?.isRedirectionActivated)
        if (value == null || value === undefined || value.length === 0) return false;
      return true;
    })
    .url('URL invalide. Avez-vous pensé à préciser le protocole (https://)?'),
});

const promptOrientationOptions: Array<{ id: string; description: string }> = [
  {
    id: 'NEEDS',
    description:
      "Pour les agences cherchant des informations détaillées sur le métier ou le secteur d'activité de leurs prospects.",
  },
  {
    id: 'SOLUTION',
    description:
      'Pour les agences désirant filtrer les projets en fonction de technologies spécifiques ou de fonctionnalités particulières.',
  },
];

const promptOrientationStyleOptions: string[] = [
  'Questions orientées sur le besoin du client (problème à résoudre, objectifs, etc.)',
  'Questions orientées sur la solution proposée (fonctionnalités attendues, technologies utilisées, etc.)',
];

const promptOrientationOptionsMap: Map<string, string> = new Map<string, string>();
promptOrientationOptionsMap.set('NEEDS', 'Questions orientées sur le besoin du client');
promptOrientationOptionsMap.set('SOLUTION', 'Questions orientées sur la solution proposée');

const formQuestionsOptions: string[] = [
  'Quel est le niveau de maturité de votre projet ?',
  'Quel est votre budget pour ce projet ?',
  'Quel est votre délai pour ce projet ?',
];

interface FormCustomizationManagementProps {
  companyData: GetDisckoCompanyDto;
}

export function FormCustomizationManagement({ companyData }: FormCustomizationManagementProps): JSX.Element {
  /* ------------------------------------------ Controls the checkbox state ----------------------------------------- */

  const [isRedirectionActivated, setIsRedirectionActivated] = useState<boolean>(false);

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitted },
  } = useForm<CustomizationFormModel>({
    context: {
      isRedirectionActivated,
    },
    resolver: yupResolver(schema),
  });

  const { requirementParametersService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const queryClient: QueryClient = useQueryClient();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const watchPromptOrientation: UpdateRequirementsParametersDtoQuestionFocusEnum = watch('promptOrientation');
  const watchFormQuestions: string[] = watch('formQuestions');
  const watchUrlRedirect: string | undefined = watch('urlRedirect');
  // const watchSetupRedirection: boolean = watch('setupRedirection');

  const [originalFormQuestions, setOriginalFormQuestions] = useState<string[]>([]);
  const [originalPromptOrientation, setOriginalPromptOrientation] = useState<string>('');
  const [originalUrlRedirect, setOriginalUrlRedirect] = useState<string | undefined>('');

  /* -------------------------------- The first time, set values with the companyData ------------------------------- */
  useEffect(() => {
    if (companyData !== undefined) {
      setValue('promptOrientation', companyData.questionFocus);
      const formQuestions: string[] = [];
      if (companyData.askProjectMaturity) formQuestions.push(formQuestionsOptions[0]);
      if (companyData.askPriceRange) formQuestions.push(formQuestionsOptions[1]);
      if (companyData.askDeadline) formQuestions.push(formQuestionsOptions[2]);
      if (companyData.askProjectType) formQuestions.push(formQuestionsOptions[3]);

      setValue('formQuestions', formQuestions);

      if (companyData.urlRedirect !== undefined && companyData.urlRedirect !== '') {
        setValue('urlRedirect', companyData.urlRedirect);
        setIsRedirectionActivated(true);
      } else {
        setIsRedirectionActivated(false);
      }
      // Save the original values
      setOriginalUrlRedirect(companyData.urlRedirect);
      setOriginalFormQuestions(formQuestions);
      setOriginalPromptOrientation(companyData.questionFocus);
    }
  }, [
    companyData,
    companyData.askDeadline,
    companyData.askPriceRange,
    companyData.askProjectMaturity,
    companyData.askProjectType,
    companyData.questionFocus,
    companyData.urlRedirect,
    setValue,
  ]);

  function resetToOriginalValues(): void {
    if (companyData !== undefined) {
      setValue('promptOrientation', originalPromptOrientation as UpdateRequirementsParametersDtoQuestionFocusEnum);
      setValue('formQuestions', originalFormQuestions);
      setValue('urlRedirect', originalUrlRedirect);
      if (originalUrlRedirect === undefined || originalUrlRedirect === '') {
        setIsRedirectionActivated(false);
      } else {
        setIsRedirectionActivated(true);
      }
    }
  }

  /* ----------------------------- Initialize state from company data on component mount ---------------------------- */
  useEffect(() => {
    if (companyData !== undefined) {
      setIsRedirectionActivated(!!companyData.urlRedirect);
      setValue('urlRedirect', companyData.urlRedirect); // Définit l'URL ou une chaîne vide
    }
  }, [companyData, setValue]);

  /* ------------------------------------- Check if we activate the save button ------------------------------------- */

  let hasFormChanged: boolean = false;
  if (
    (watchPromptOrientation !== undefined && watchPromptOrientation !== originalPromptOrientation) ||
    (watchFormQuestions !== undefined &&
      originalFormQuestions !== undefined &&
      watchFormQuestions.length !== originalFormQuestions.length) ||
    (watchUrlRedirect !== undefined && watchUrlRedirect !== originalUrlRedirect)
  ) {
    hasFormChanged = true;
  }
  if (
    watchFormQuestions !== undefined &&
    originalFormQuestions !== undefined &&
    watchFormQuestions.length === originalFormQuestions.length
  )
    watchFormQuestions.forEach((formQuestion) => {
      if (!originalFormQuestions.includes(formQuestion)) {
        hasFormChanged = true;
      }
    });
  if (
    watchFormQuestions !== undefined &&
    originalFormQuestions !== undefined &&
    watchFormQuestions.length === originalFormQuestions.length
  )
    originalFormQuestions.forEach((originalFormQuestion) => {
      if (!watchFormQuestions.includes(originalFormQuestion)) {
        hasFormChanged = true;
      }
    });

  /* -------------------------------------------- Desktop or mobile view -------------------------------------------- */

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* ----------------------------------------------- Check form valid ----------------------------------------------- */

  let isFormValid: boolean = false;

  // Check if the form is valid, form is invalid if the checkbox is checked but the url is empty
  if (
    (watchUrlRedirect === undefined && isRedirectionActivated) ||
    (watchUrlRedirect === '' && isRedirectionActivated)
  ) {
    isFormValid = false;
  } else {
    isFormValid = true;
  }

  /* ------------------------------------- Submit form / open confirmation modal ------------------------------------ */

  const onSubmitForm: SubmitHandler<CustomizationFormModel> = async (data) => {
    setIsConfirmationModalOpen(true);
  };

  /* --------------------------------------------------- Save Data -------------------------------------------------- */

  async function saveRequirmentsParameters(): Promise<UpdateRequirementsParametersDto> {
    const accessToken: string = await getAccessTokenSilently();
    if (requirementParametersService === undefined) {
      throw new Error('requirementParametersService is undefined');
    }
    return await requirementParametersService.saveRequirementParameters({
      accessToken,
      updateRequirementsParametersDto: {
        askProjectMaturity: watchFormQuestions.includes(formQuestionsOptions[0]),
        askPriceRange: watchFormQuestions.includes(formQuestionsOptions[1]),
        askDeadline: watchFormQuestions.includes(formQuestionsOptions[2]),
        askProjectType: watchFormQuestions.includes(formQuestionsOptions[3]),
        questionFocus: watchPromptOrientation,
        urlRedirect: watchUrlRedirect,
      },
    });
  }

  const { mutate: mutateSaveRequirmentsParameters } = useMutation(saveRequirmentsParameters, {
    onSuccess: async (savedRequirementsParameters: UpdateRequirementsParametersDto) => {
      setIsConfirmationModalOpen(false);
      toast.success('Les modifications ont bien été appliquées');
      queryClient.setQueryData(['company', user?.sub], (oldData: GetDisckoCompanyDto | undefined) => {
        if (oldData === undefined) {
          throw new Error('oldData is undefined');
        }
        oldData.askProjectMaturity = savedRequirementsParameters.askProjectMaturity;
        oldData.askPriceRange = savedRequirementsParameters.askPriceRange;
        oldData.askDeadline = savedRequirementsParameters.askDeadline;
        oldData.askProjectType = savedRequirementsParameters.askProjectType;
        oldData.questionFocus = savedRequirementsParameters.questionFocus;
        oldData.urlRedirect = savedRequirementsParameters.urlRedirect;

        // updateTheData(oldData);
        return oldData;
      });
      return savedRequirementsParameters;
    },
    onError: () => {
      setIsConfirmationModalOpen(false);
      toast.error("Une erreur est survenue lors de l'enregistrement des modifications");
    },
  });
  /* ----------------------------------------------- Manage scrolling ----------------------------------------------- */

  useEffect(() => {
    if (isRedirectionActivated) {
      const element = document.getElementById('urlRedirectInput');
      element?.scrollIntoView();
    }
  }, [isRedirectionActivated]);

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      <Modal title="Attention" isOpen={isConfirmationModalOpen} setIsOpen={setIsConfirmationModalOpen}>
        <>
          <Text
            whitespace="pre-line"
            content={`Vos modifications vont prendre effet instantanément.
            Êtes-vous certain de vouloir poursuivre ?`}
            position="justify"
            size="base"
          />
          <div className="flex flex-row gap-2 justify-end">
            <Button
              onClick={async () => {
                setIsConfirmationModalOpen(false);
              }}
              content="Annuler"
              width="1/2"
              type="button"
              height="sm"
              bgColor="black"
            />
            <Button
              onClick={async () => {
                mutateSaveRequirmentsParameters();
              }}
              content="Je confirme"
              width="1/2"
              type="submit"
              height="sm"
              bgColor="blue-400"
            />
          </div>
        </>
      </Modal>
      <form id="customizationForm" onSubmit={handleSubmit(onSubmitForm)}>
        {isLargeScreen === true && (
          <div className={concatClassNames('pl-4 pb-4 flex flex-col gap-12', '', 'rounded-md')}>
            <div className="flex flex-row gap-6 w-full items-end justify-end">
              <>
                {hasFormChanged && (
                  <Button
                    content="Réinitialiser"
                    width="fit"
                    type="button"
                    borderColor={'black'}
                    bgColor="black"
                    bgColorOnHover="gray-200"
                    textColorOnHover="white"
                    onClick={resetToOriginalValues}
                  />
                )}
                <Button
                  content="Enregistrer vos modifications"
                  width="fit"
                  bgColor="blue-400"
                  type="submit"
                  disabled={!hasFormChanged || !isFormValid}
                  borderColor={!hasFormChanged ? 'gray-50' : 'black'}
                  bgColorOnHover="blue-300"
                  textColorOnHover="white"
                />
              </>
            </div>

            <Controller
              name="formQuestions"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultiSelectRadioButtons
                  options={formQuestionsOptions}
                  selectedValues={value}
                  onChange={onChange}
                  label="Sélectionnez les questions préalables que vous souhaitez voir apparaître dans votre formulaire :"
                />
              )}
            />
            <Controller
              name="promptOrientation"
              control={control}
              defaultValue={'NEEDS'}
              render={({ field: { value, onChange } }) => (
                <BackOfficeRadioButtons
                  label="Quelle orientation souhaitez-vous donner aux questions générées par l'IA?"
                  values={promptOrientationOptions}
                  valuesDisplay={promptOrientationStyleOptions}
                  selected={value}
                  onChange={onChange}
                />
              )}
            />
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4">
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={isRedirectionActivated}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setIsRedirectionActivated(checked);

                    if (!checked) {
                      setValue('urlRedirect', '');
                    }
                  }}
                  value={isRedirectionActivated ? 'checked' : ''}
                  className={concatClassNames('cursor-pointer')}
                />
                <Text
                  position="left"
                  size="lg"
                  color="black"
                  weight="bold"
                  content="Activer la redirection des utilisateurs vers un calendrier en ligne au moment de la synthèse"
                />
              </div>
              <div id="urlRedirectInput">
                {isRedirectionActivated && (
                  <div className="flex flex-col gap-4">
                    <div className="rounded-full flex flex-row gap-4 items-center w-full">
                      <div className="w-[35%] h-fit">
                        <Text position="left" size="lg" color="black" weight="bold" content="URL de redirection :" />
                      </div>
                      <div className="w-full">
                        <Input
                          placeholder="https://example.com"
                          {...register('urlRedirect')}
                          value={watchUrlRedirect ?? ''}
                          error={
                            errors.urlRedirect != null && isSubmitted
                              ? errors.urlRedirect.message
                              : isRedirectionActivated && watchUrlRedirect === ''
                              ? 'Champ obligatoire'
                              : undefined
                          }
                        />
                      </div>
                    </div>
                    <Text
                      position="left"
                      size="sm"
                      color="black"
                      weight="light"
                      content="L'URL de redirection est la même pour tous les membres de votre entreprise."
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {isLargeScreen === false && (
          <div className={concatClassNames('flex flex-col gap-6', 'rounded-md')}>
            <>
              <Controller
                name="formQuestions"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelectRadioButtons
                    options={formQuestionsOptions}
                    selectedValues={value}
                    onChange={onChange}
                    labelSize="sm"
                    label="Sélectionnez les questions préalables que vous souhaitez voir apparaître dans votre formulaire :"
                  />
                )}
              />
            </>
            <>
              <Controller
                name="promptOrientation"
                control={control}
                defaultValue={'NEEDS'}
                render={({ field: { value, onChange } }) => (
                  <BackOfficeRadioButtons
                    label="Quelle orientation souhaitez-vous donner aux questions générées par l'IA?"
                    labelSize="sm"
                    values={promptOrientationOptions}
                    valuesDisplay={promptOrientationStyleOptions}
                    selected={value}
                    onChange={onChange}
                  />
                )}
              />
            </>

            <>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={isRedirectionActivated}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setIsRedirectionActivated(checked);

                      if (!checked) {
                        setValue('urlRedirect', '');
                      }
                    }}
                    value={isRedirectionActivated ? 'checked' : ''}
                    className={concatClassNames('cursor-pointer')}
                  />
                  <Text
                    position="left"
                    size="sm"
                    color="black"
                    weight="bold"
                    content="Activer la redirection des utilisateurs vers un calendrier en ligne au moment de la synthèse"
                  />
                </div>
                <div id="urlRedirectInput">
                  {isRedirectionActivated && (
                    <>
                      <div className="rounded-full flex flex-col gap-4 items-center w-full">
                        <div className="w-full h-fit">
                          <Text position="left" size="sm" color="black" weight="bold" content="URL de redirection :" />
                        </div>
                        <div className="w-full">
                          <Input
                            placeholder="https://example.com"
                            {...register('urlRedirect')}
                            value={watchUrlRedirect ?? ''}
                            error={
                              errors.urlRedirect != null && isSubmitted
                                ? errors.urlRedirect.message
                                : isRedirectionActivated && watchUrlRedirect === ''
                                ? 'Champ obligatoire'
                                : undefined
                            }
                          />
                        </div>
                        <Text
                          position="left"
                          size="sm"
                          color="black"
                          weight="light"
                          content="L'URL de redirection est la même pour tous les membres de votre entreprise."
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>

            <div className="flex flex-col gap-6 w-full items-end justify-end text-xs">
              {hasFormChanged && (
                <Button
                  content="Réinitialiser"
                  width="fit"
                  iconName="reset"
                  iconColor="black"
                  iconPosition="left"
                  type="button"
                  textColor="black"
                  bgColor="none"
                  onClick={resetToOriginalValues}
                />
              )}
              <Button
                content="Enregistrer vos modifications"
                width="fit"
                bgColor="blue-400"
                type="submit"
                disabled={!hasFormChanged || !isFormValid}
                borderColor={!hasFormChanged ? 'gray-50' : 'black'}
                bgColorOnHover="blue-300"
                textColorOnHover="white"
              />
            </div>
          </div>
        )}
      </form>
    </>
  );
}
