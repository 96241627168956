import React from 'react';

import { BasePage } from 'components/core/BasePage/BasePage';

import { TermsWidget } from './TermsWidget';

export function TermsPage(): JSX.Element {
  return (
    <BasePage>
      <div className="p-12">
        <TermsWidget />
      </div>
    </BasePage>
  );
}
