/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  ErrorInternalServer,
  ErrorUnauthorizedRequest,
  GetDisckoCompanyDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorInternalServerFromJSON,
    ErrorInternalServerToJSON,
    ErrorUnauthorizedRequestFromJSON,
    ErrorUnauthorizedRequestToJSON,
    GetDisckoCompanyDtoFromJSON,
    GetDisckoCompanyDtoToJSON,
} from '../models';

export interface DisckoAdminControllerAddUserToCompanyRequest {
    companyId: string;
    userName: string;
    userEmail: string;
}

export interface DisckoAdminControllerCreateCompanyRequest {
    companyName: string;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     */
    async disckoAdminControllerAddUserToCompanyRaw(requestParameters: DisckoAdminControllerAddUserToCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling disckoAdminControllerAddUserToCompany.');
        }

        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling disckoAdminControllerAddUserToCompany.');
        }

        if (requestParameters.userEmail === null || requestParameters.userEmail === undefined) {
            throw new runtime.RequiredError('userEmail','Required parameter requestParameters.userEmail was null or undefined when calling disckoAdminControllerAddUserToCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.userName !== undefined) {
            queryParameters['userName'] = requestParameters.userName;
        }

        if (requestParameters.userEmail !== undefined) {
            queryParameters['userEmail'] = requestParameters.userEmail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/Admin/add-user-to-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async disckoAdminControllerAddUserToCompany(requestParameters: DisckoAdminControllerAddUserToCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disckoAdminControllerAddUserToCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async disckoAdminControllerCreateCompanyRaw(requestParameters: DisckoAdminControllerCreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoCompanyDto>> {
        if (requestParameters.companyName === null || requestParameters.companyName === undefined) {
            throw new runtime.RequiredError('companyName','Required parameter requestParameters.companyName was null or undefined when calling disckoAdminControllerCreateCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyName !== undefined) {
            queryParameters['companyName'] = requestParameters.companyName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/Admin/create-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoAdminControllerCreateCompany(requestParameters: DisckoAdminControllerCreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoCompanyDto> {
        const response = await this.disckoAdminControllerCreateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
