import React, { useEffect } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

export function CallbackPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  useEffect(() => {
    navigate('/');
  });

  return <></>;
}
