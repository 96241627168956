/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import { type GetDisckoUserDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export function PersonalNotificationsSettings(): JSX.Element {
  const { getAccessTokenSilently, user } = useAuth0();
  const { userService } = useContext(DisckoApisContext);

  /* ------------------------------------------ Controls the checkbox state ----------------------------------------- */

  const [isNotificationsActivated, setIsNotificationsActivated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* --------------------------------------------- Get User Informations -------------------------------------------- */

  async function getUser(): Promise<GetDisckoUserDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await userService.getUserInfos(accessToken);
  }

  const { data: userData, status: userStatus } = useQuery(['user', user?.sub], getUser);

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const queryClient: QueryClient = useQueryClient();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();

  /* -------------------------------- The first time, set values with the companyData ------------------------------- */

  useEffect(() => {
    if (userStatus === 'success' && userData !== undefined) {
      setIsNotificationsActivated(userData.getNotifications);
    }
  }, [userData, userData?.getNotifications, userStatus]);

  /* -------------------------------------------- Desktop or mobile view -------------------------------------------- */

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* --------------------------------------------------- Save Data -------------------------------------------------- */

  async function saveRequirmentsParameters(isNotificationsActivated2: boolean): Promise<GetDisckoUserDto> {
    setIsLoading(true);
    const accessToken: string = await getAccessTokenSilently();
    return await userService.updateUserInfos(accessToken, isNotificationsActivated2);
  }

  const { mutate: mutateSaveRequirmentsParameters } = useMutation(saveRequirmentsParameters, {
    onSuccess: async (savePersonalNotificationsSettings: GetDisckoUserDto) => {
      toast.success('Les modifications ont bien été appliquées');
      queryClient.setQueryData(['user', user?.sub], (oldData: GetDisckoUserDto | undefined) => {
        if (oldData === undefined) {
          throw new Error('oldData is undefined');
        }
        oldData.getNotifications = savePersonalNotificationsSettings.getNotifications;

        setIsLoading(false);
        return oldData;
      });
      return savePersonalNotificationsSettings;
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de l'enregistrement des modifications");
    },
  });

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      {isLargeScreen === true && (
        <div className={concatClassNames('pl-4 pb-4 flex flex-col gap-6', '', 'rounded-md')}>
          <div
            className="flex flex-row gap-4 cursor-pointer"
            onClick={() => {
              mutateSaveRequirmentsParameters(!isNotificationsActivated);
            }}
          >
            <input
              type="checkbox"
              id="checkbox"
              checked={isNotificationsActivated}
              value={isNotificationsActivated ? 'checked' : ''}
              className={concatClassNames('cursor-pointer')}
              disabled={isLoading}
            />
            <Text
              position="left"
              size="lg"
              color="black"
              weight="bold"
              content={`Recevoir une notification par email quand une nouvelle expression de besoin est reçue (${
                userData?.email ?? ''
              })`}
            />
          </div>
        </div>
      )}
      {isLargeScreen === false && (
        <div className={concatClassNames('flex flex-col gap-6', 'rounded-md')}>
          <div
            className="flex flex-row gap-4 cursor-pointer"
            onClick={() => {
              mutateSaveRequirmentsParameters(!isNotificationsActivated);
            }}
          >
            <input
              type="checkbox"
              id="checkbox"
              checked={isNotificationsActivated}
              value={isNotificationsActivated ? 'checked' : ''}
              className={concatClassNames('cursor-pointer')}
              disabled={isLoading}
            />
            <Text
              position="left"
              size="lg"
              color="black"
              weight="bold"
              content={`Recevoir une notification par email quand une nouvelle expression de besoin est reçue (${
                userData?.email ?? ''
              })`}
            />
          </div>
        </div>
      )}
    </>
  );
}
