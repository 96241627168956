import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { CurrentPlan } from 'components/CurrentPlan/CurrentPlan';
import { FormCustomizationManagement } from 'components/FormCustomizationManagement/FormCustomizationManagement';
import { MembersManagement } from 'components/Members Management/MembersManagement';
import { PersonalNotificationsSettings } from 'components/PersonalNotificationsSettings/PersonalNotificationsSettings';
import { RichText } from 'components/RichText/RichText';
import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import { P } from 'components/core/P';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

export function SettingsPage(): JSX.Element {
  const { companyService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const canceled: string | undefined = searchParams.get('canceled') ?? undefined;
  let alreadyNotify: boolean = false;

  useEffect(() => {
    if (canceled === 'true' && !alreadyNotify) {
      alreadyNotify = true;
      toast.error('Le paiement à échoué');
    }
  }, [canceled]);

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);

  async function getCompanyIdByUserId(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>(
    ['company', user?.sub],
    getCompanyIdByUserId,
  );

  interface SubCategory {
    name: string;
    iconSource: string;
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isHovered: boolean;
    setIsHovered: (isHover: boolean) => void;
  }
  const [isFacturationHovered, setIsFacturationHovered] = useState<boolean>(false);
  const [isIntegrationHovered, setIsIntegrationHovered] = useState<boolean>(false);
  const [isEquipeHovered, setIsEquipeHovered] = useState<boolean>(false);
  const [isCustomizationHovered, setIsCustomizationHovered] = useState<boolean>(false);
  const [isNotificationHovered, setIsNotificationHovered] = useState<boolean>(false);

  const [isFacturationOpen, setIsFacturationOpen] = useState<boolean>(true);
  const [isIntegrationOpen, setIsIntegrationOpen] = useState<boolean>(false);
  const [isEquipeOpen, setIsEquipeOpen] = useState<boolean>(false);
  const [isCustomizationOpen, setIsCustomizationOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);

  const subCategories: SubCategory[] = [
    {
      name: 'Facturation',
      iconSource: '/imgs/GIFs/billing.gif',
      isOpen: isFacturationOpen,
      setIsOpen: setIsFacturationOpen,
      isHovered: isFacturationHovered,
      setIsHovered: setIsFacturationHovered,
    },
    {
      name: 'Intégration',
      iconSource: '/imgs/GIFs/play.gif',
      isOpen: isIntegrationOpen,
      setIsOpen: setIsIntegrationOpen,
      isHovered: isIntegrationHovered,
      setIsHovered: setIsIntegrationHovered,
    },
    {
      name: 'Equipe',
      iconSource: '/imgs/GIFs/team.gif',
      isOpen: isEquipeOpen,
      setIsOpen: setIsEquipeOpen,
      isHovered: isEquipeHovered,
      setIsHovered: setIsEquipeHovered,
    },
    {
      name: 'Personnalisation du formulaire',
      iconSource: '/imgs/GIFs/prompt.gif',
      isOpen: isCustomizationOpen,
      setIsOpen: setIsCustomizationOpen,
      isHovered: isCustomizationHovered,
      setIsHovered: setIsCustomizationHovered,
    },
    {
      name: 'Notifications',
      iconSource: '/imgs/GIFs/notification.gif',
      isOpen: isNotificationOpen,
      setIsOpen: setIsNotificationOpen,
      isHovered: isNotificationHovered,
      setIsHovered: setIsNotificationHovered,
    },
  ];

  return (
    <BasePage>
      <>
        {isLargeScreen && (
          <div className="flex flex-row w-full overflow-auto">
            <div className=" w-[20%] flex flex-col gap-6 p-4 pt-24">
              <Text content="Votre espace" position="left" size="xl" weight="bold" />
              <>
                {subCategories.map((subCategory, index) => (
                  <div
                    key={index}
                    className={concatClassNames(
                      'flex flex-row items-center gap-4 cursor-pointer',
                      subCategory.isHovered || subCategory.isOpen === true ? 'bg-white/50 ' : '',
                      'py-2 px-2 rounded-r-lg',
                    )}
                    onMouseEnter={() => {
                      subCategory.setIsHovered(true);
                    }}
                    onMouseLeave={() => {
                      subCategory.setIsHovered(!subCategory.isHovered);
                    }}
                    onClick={() => {
                      setIsFacturationHovered(false);
                      setIsFacturationOpen(false);
                      setIsIntegrationHovered(false);
                      setIsIntegrationOpen(false);
                      setIsNotificationOpen(false);
                      setIsEquipeHovered(false);
                      setIsEquipeOpen(false);
                      setIsCustomizationHovered(false);
                      setIsCustomizationOpen(false);
                      setIsNotificationHovered(false);
                      subCategory.setIsOpen(true);
                    }}
                  >
                    <img src={subCategory.iconSource} alt="icon" className="h-10 w-10 " />
                    <Text
                      content={subCategory.name}
                      position="left"
                      size="lg"
                      weight="bold"
                      color={subCategory.isOpen === true ? 'blue-400' : 'black'}
                    />
                  </div>
                ))}
              </>
            </div>
            <>
              {companyStatus !== 'loading' && companyStatus !== 'idle' && (
                <>
                  {isFacturationOpen && (
                    <div className="p-24 flex flex-col gap-12 w-[80%] overflow-auto">
                      <Text content="Gérer votre tarif et vos factures" position="left" size="3xl" weight="bold" />

                      {/* TODO : finir ici */}
                      <CurrentPlan
                        isOldPrice={companyData?.isOldPrice}
                        isSubscribed={companyData?.isSubscribed ?? false}
                      />
                    </div>
                  )}
                  {isIntegrationOpen && (
                    <div className="p-24 flex flex-col gap-12 w-[80%] overflow-auto">
                      <Text content="Intégrer Discko à votre site web" position="left" size="3xl" weight="bold" />
                      <div className="pl-4 flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                          <P
                            position="left"
                            weight="normal"
                            text="Vous pouvez insérer un lien vers le formulaire Discko directement depuis votre site web."
                          />
                          <div className="relative inline-block ">
                            <Button
                              height="fit"
                              width="fit"
                              content="Récupérer le lien à insérer sur votre site"
                              bgColor="blue-400"
                              bgColorOnHover="blue-300"
                              iconName="link"
                              iconPosition="left"
                              onClick={async () => {
                                await navigator.clipboard.writeText(
                                  `${window.location.protocol}//${window.location.host}/new/?companyid=${
                                    companyData?.id ?? ''
                                  }`,
                                );
                                toast.success('Le lien a bien été copié.');
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <RichText
                            fragments={[
                              { contentType: 'p', content: "1. En tant qu'iFrame. ", size: 'lg', weight: 'bold' },
                              {
                                contentType: 'p',
                                size: 'lg',
                                content:
                                  "Intégrez directement le formulaire Discko sur l'une de vos pages en utilisant un iFrame pour une intégration fluide.",
                              },
                            ]}
                          />
                          <code className="bg-gray-500 text-white p-4 ml-4 rounded-lg flex flex-row gap-2 whitespace-pre-wrap">
                            {companyStatus === 'success' &&
                              `<iframe src="${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}" />`}
                            {companyStatus !== 'success' && `<iframe src="https://votre-lien-discko.com" />`}
                          </code>
                        </div>
                        <div className="flex flex-col gap-4">
                          <RichText
                            fragments={[
                              {
                                contentType: 'p',
                                content: '2. En tant que lien ou bouton. ',
                                size: 'lg',
                                weight: 'bold',
                              },
                              {
                                contentType: 'p',
                                size: 'lg',
                                content:
                                  'Vous pouvez aussi choisir de rediriger vos utilisateurs vers le formulaire Discko en utilisant un simple lien ou un bouton cliquable.',
                              },
                            ]}
                          />

                          <code className="bg-gray-500 text-white p-4 ml-4 rounded-lg flex flex-row gap-2">
                            {companyStatus === 'success' &&
                              `
                              <a href="${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}" target="_blank" rel="noreferrer">
                              Accéder au formulaire Discko
                              </a>`}
                            {companyStatus !== 'success' &&
                              `
                                <a href="https://votre-lien-discko.com" target="_blank" rel="noreferrer">
                                Accéder au formulaire Discko
                                </a>`}
                          </code>
                          <code className="bg-gray-500 text-white p-4 ml-4 rounded-lg flex flex-row gap-2">
                            {companyStatus === 'success' &&
                              `
                            <button onclick="window.open('${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}', '_blank')">
                              Accéder au formulaire Discko
                            </button>`}
                            {companyStatus !== 'success' &&
                              `
                            <button onclick="window.open('https://votre-lien-discko.com', '_blank')">
                              Accéder au formulaire Discko
                            </button>`}
                          </code>
                        </div>
                      </div>
                    </div>
                  )}
                  {companyStatus === 'success' && isEquipeOpen && (
                    <div className="p-24 flex flex-col gap-12 w-[80%] overflow-auto">
                      <Text content="Inviter les membres de votre équipe" position="left" size="3xl" weight="bold" />
                      <MembersManagement />
                    </div>
                  )}
                  {companyStatus === 'success' && isCustomizationOpen && (
                    <div className="pt-24 px-14 flex flex-col gap-12 w-[80%] overflow-auto">
                      <Text content="Personnaliser Discko à votre image" position="left" size="3xl" weight="bold" />
                      <FormCustomizationManagement companyData={companyData} />
                    </div>
                  )}
                  {companyStatus === 'success' && isNotificationOpen && (
                    <div className="p-24 flex flex-col gap-12 w-[80%] overflow-auto">
                      <Text content="Parametrer les notifications" position="left" size="3xl" weight="bold" />
                      <PersonalNotificationsSettings />
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        )}

        {!isLargeScreen && (
          <div className="">
            <div className="flex flex-col items-center justify-between">
              <>
                {subCategories.map((subCategory, index) => (
                  <div key={index} className="w-full align-middle">
                    <div
                      className={concatClassNames(
                        'flex flex-row items-center align-top gap-4 cursor-pointer w-full',
                        subCategory.isHovered || subCategory.isOpen === true ? 'bg-white/50 ' : '',
                        'py-2 px-2 rounded-r-lg',
                      )}
                      onMouseEnter={() => {
                        subCategory.setIsHovered(true);
                      }}
                      onMouseLeave={() => {
                        subCategory.setIsHovered(!subCategory.isHovered);
                      }}
                      onClick={() => {
                        setIsFacturationHovered(false);
                        setIsFacturationOpen(false);
                        setIsIntegrationHovered(false);
                        setIsIntegrationOpen(false);
                        setIsNotificationOpen(false);
                        setIsEquipeHovered(false);
                        setIsEquipeOpen(false);
                        setIsCustomizationHovered(false);
                        setIsCustomizationOpen(false);
                        setIsNotificationHovered(false);
                        subCategory.setIsOpen(!(subCategory.isOpen ?? false));
                      }}
                    >
                      <img src={subCategory.iconSource} alt="icon" className="h-10 w-10 " />
                      <Text
                        content={subCategory.name}
                        position="left"
                        size="lg"
                        weight="bold"
                        color={subCategory.isOpen === true ? 'blue-400' : 'black'}
                      />
                      {subCategory.isOpen === true
                        ? getIcon('chevronUp', 'blue-400', 'smd')
                        : getIcon('chevronDown', 'black', 'smd')}
                    </div>
                    {isFacturationOpen && companyStatus === 'success' && subCategory.name === 'Facturation' && (
                      <>
                        <div className="p-4 flex flex-col gap-12 w-full overflow-auto  align-middle">
                          <CurrentPlan isOldPrice={companyData?.isOldPrice} isSubscribed={companyData?.isSubscribed} />
                        </div>
                      </>
                    )}
                    {isIntegrationOpen && companyStatus === 'success' && subCategory.name === 'Intégration' && (
                      <div className="p-4 flex flex-col gap-12 w-full overflow-auto  align-middle">
                        {"Cette section n'est pas accessibe au format mobile."}
                      </div>
                    )}
                    {isEquipeOpen && companyStatus === 'success' && subCategory.name === 'Equipe' && (
                      <div className="p-4 flex flex-col gap-12 w-full overflow-auto  align-middle">
                        <MembersManagement />
                      </div>
                    )}

                    {isCustomizationOpen &&
                      companyStatus === 'success' &&
                      subCategory.name === 'Personnalisation du formulaire' && (
                        <div className="p-4 flex flex-col gap-12 w-full overflow-auto  align-middle">
                          <FormCustomizationManagement companyData={companyData} />
                        </div>
                      )}
                    {companyStatus === 'success' && isNotificationOpen && subCategory.name === 'Notification' && (
                      <div className="p-4 flex flex-col gap-12 w-full overflow-auto">
                        <PersonalNotificationsSettings />
                      </div>
                    )}
                  </div>
                ))}
              </>
            </div>
          </div>
        )}
      </>
    </BasePage>
  );
}
