/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRequirementIdParamsDto
 */
export interface GetRequirementIdParamsDto {
    /**
     * 
     * @type {string}
     * @memberof GetRequirementIdParamsDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetRequirementIdParamsDto
     */
    askDeadline: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetRequirementIdParamsDto
     */
    askPriceRange: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetRequirementIdParamsDto
     */
    askProjectMaturity: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetRequirementIdParamsDto
     */
    askProjectType: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementIdParamsDto
     */
    questionFocus: GetRequirementIdParamsDtoQuestionFocusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementIdParamsDto
     */
    urlRedirect?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequirementIdParamsDto
     */
    step: string;
}


/**
 * @export
 */
export const GetRequirementIdParamsDtoQuestionFocusEnum = {
    Needs: 'NEEDS',
    Solution: 'SOLUTION'
} as const;
export type GetRequirementIdParamsDtoQuestionFocusEnum = typeof GetRequirementIdParamsDtoQuestionFocusEnum[keyof typeof GetRequirementIdParamsDtoQuestionFocusEnum];


/**
 * Check if a given object implements the GetRequirementIdParamsDto interface.
 */
export function instanceOfGetRequirementIdParamsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "askDeadline" in value;
    isInstance = isInstance && "askPriceRange" in value;
    isInstance = isInstance && "askProjectMaturity" in value;
    isInstance = isInstance && "askProjectType" in value;
    isInstance = isInstance && "questionFocus" in value;
    isInstance = isInstance && "step" in value;

    return isInstance;
}

export function GetRequirementIdParamsDtoFromJSON(json: any): GetRequirementIdParamsDto {
    return GetRequirementIdParamsDtoFromJSONTyped(json, false);
}

export function GetRequirementIdParamsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequirementIdParamsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'askDeadline': json['askDeadline'],
        'askPriceRange': json['askPriceRange'],
        'askProjectMaturity': json['askProjectMaturity'],
        'askProjectType': json['askProjectType'],
        'questionFocus': json['questionFocus'],
        'urlRedirect': !exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'step': json['step'],
    };
}

export function GetRequirementIdParamsDtoToJSON(value?: GetRequirementIdParamsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'askDeadline': value.askDeadline,
        'askPriceRange': value.askPriceRange,
        'askProjectMaturity': value.askProjectMaturity,
        'askProjectType': value.askProjectType,
        'questionFocus': value.questionFocus,
        'urlRedirect': value.urlRedirect,
        'step': value.step,
    };
}

