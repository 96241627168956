/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRequirementsParametersDto
 */
export interface UpdateRequirementsParametersDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRequirementsParametersDto
     */
    askDeadline: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRequirementsParametersDto
     */
    askPriceRange: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRequirementsParametersDto
     */
    askProjectMaturity: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRequirementsParametersDto
     */
    askProjectType: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequirementsParametersDto
     */
    questionFocus: UpdateRequirementsParametersDtoQuestionFocusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequirementsParametersDto
     */
    urlRedirect?: string;
}


/**
 * @export
 */
export const UpdateRequirementsParametersDtoQuestionFocusEnum = {
    Needs: 'NEEDS',
    Solution: 'SOLUTION'
} as const;
export type UpdateRequirementsParametersDtoQuestionFocusEnum = typeof UpdateRequirementsParametersDtoQuestionFocusEnum[keyof typeof UpdateRequirementsParametersDtoQuestionFocusEnum];


/**
 * Check if a given object implements the UpdateRequirementsParametersDto interface.
 */
export function instanceOfUpdateRequirementsParametersDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "askDeadline" in value;
    isInstance = isInstance && "askPriceRange" in value;
    isInstance = isInstance && "askProjectMaturity" in value;
    isInstance = isInstance && "askProjectType" in value;
    isInstance = isInstance && "questionFocus" in value;

    return isInstance;
}

export function UpdateRequirementsParametersDtoFromJSON(json: any): UpdateRequirementsParametersDto {
    return UpdateRequirementsParametersDtoFromJSONTyped(json, false);
}

export function UpdateRequirementsParametersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRequirementsParametersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'askDeadline': json['askDeadline'],
        'askPriceRange': json['askPriceRange'],
        'askProjectMaturity': json['askProjectMaturity'],
        'askProjectType': json['askProjectType'],
        'questionFocus': json['questionFocus'],
        'urlRedirect': !exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
    };
}

export function UpdateRequirementsParametersDtoToJSON(value?: UpdateRequirementsParametersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'askDeadline': value.askDeadline,
        'askPriceRange': value.askPriceRange,
        'askProjectMaturity': value.askProjectMaturity,
        'askProjectType': value.askProjectType,
        'questionFocus': value.questionFocus,
        'urlRedirect': value.urlRedirect,
    };
}

