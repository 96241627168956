import { DisckoApisContext, MaintenanceContext } from 'App';
import {
  type AgencyFormOrchestratorQuestionAnswerDto,
  type AgencyFormOrchestratorResponseDto,
  type CreateAgencyClientDto,
  type GetRequirementIdParamsDto,
  type ReceiveQuestionsInputsDto,
  type SetupProjectInputsDto,
} from 'api';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';

import { ScreebProvider } from '@screeb/sdk-react';

import { RichText } from 'components/RichText/RichText';
import { Modal } from 'components/core/Modal/Modal';

import concatClassNames from 'utils/classNames';

import { ClientInfoForm } from './DisckoClientsInfoForm';
import { DisckoFormComment } from './DisckoFormComment';
import { DisckoFormEndRequirement } from './DisckoFormEndRequirement';
import { DisckoFormProject } from './DisckoFormProject';
import { DisckoFormQuestions } from './DisckoFormQuestions';

/* -------------------------------------------------- interfaces -------------------------------------------------- */

export interface RequirementSynthesis {
  clientContext?: string[];
  objective?: string[];
  targetUsers?: string[];
  constraints?: string[];
  keySuccessFactors?: string[];
  abstract?: string;
  comment?: string;
  clientMaturity?: string;
  priceRange?: string;
  projectType?: string;
  projectMaturity?: string;
  deadline?: { deadlineCategory?: string; date?: string };
  step?: string;
  projectSubject?: string;
  questions?: AgencyFormOrchestratorQuestionAnswerDto[];
}

export interface DisckoFormProps {
  setNextStep?: (nextStep: string) => void;
  onClickPrevious?: () => void;
}

export interface Question {
  index: number;
  question: string;
  placeholder: string[];
  mandatory?: boolean;
}

/* ------------------------------------------------ Set up Context ------------------------------------------------ */

export interface RequirementQuestions {
  askPricingRange: boolean;
  askDeadline: boolean;
  askProjectMaturity: boolean;
  askProjectType: boolean;
  urlRedirect?: string;
}
interface ContextProps {
  openAiQuestionsFormToDisplay: Question[];
  contextRequirement: RequirementSynthesis;
  questionsToSave: AgencyFormOrchestratorQuestionAnswerDto[];
  setContextRequirement: (value: RequirementSynthesis) => void;
  setupProject: (setupProjectInputs: SetupProjectInputsDto) => Promise<AgencyFormOrchestratorResponseDto>;
  sendQuestions: (sendQuestionsInputs: ReceiveQuestionsInputsDto) => Promise<AgencyFormOrchestratorResponseDto>;
  initRequirement: (clientsInfo: CreateAgencyClientDto) => Promise<GetRequirementIdParamsDto>;
  requirementId?: string;
  requirementParams?: RequirementQuestions;
  setRequirementParams: (value: GetRequirementIdParamsDto) => void;
  isLargeScreen?: boolean;
  companyIsAllowedToUseDiscko?: boolean;
  reset: () => void;
}

const context: ContextProps = {
  companyIsAllowedToUseDiscko: undefined,
  openAiQuestionsFormToDisplay: [],
  questionsToSave: [],
  contextRequirement: {
    clientContext: [],
    objective: [],
    constraints: [],
    keySuccessFactors: [],
    targetUsers: [],
    abstract: '',
    deadline: { deadlineCategory: 'flexible' },
    priceRange: 'abstain',
    projectType: 'Website',
    projectMaturity: 'IDEA',
    clientMaturity: 'BEGINNER',
    projectSubject: '',
    questions: [],
    step: '',
    comment: '',
  },
  setContextRequirement: ({
    clientContext,
    objective,
    constraints,
    keySuccessFactors,
    targetUsers,
    abstract: synthesis,
  }: RequirementSynthesis) => {
    context.contextRequirement.clientContext = clientContext;
    context.contextRequirement.objective = objective;
    context.contextRequirement.constraints = constraints;
    context.contextRequirement.keySuccessFactors = keySuccessFactors;
    context.contextRequirement.targetUsers = targetUsers;
    context.contextRequirement.abstract = synthesis;
  },
  setupProject: async (): Promise<AgencyFormOrchestratorResponseDto> => {
    return {
      message: {
        content: '',
        role: '',
      },
      step: '',
    };
  },
  sendQuestions: async (): Promise<AgencyFormOrchestratorResponseDto> => {
    return {
      message: {
        content: '',
        role: '',
      },
      step: '',
    };
  },
  initRequirement: async (clientsInfo: CreateAgencyClientDto): Promise<GetRequirementIdParamsDto> => {
    return {
      id: '',
      askProjectMaturity: false,
      askDeadline: false,
      askPriceRange: false,
      askProjectType: false,
      questionFocus: 'NEEDS',
      step: Step.CONTACT,
    };
  },
  setRequirementParams: (value: GetRequirementIdParamsDto) => {
    context.requirementId = value.id;
    context.requirementParams = {
      askProjectMaturity: value.askProjectMaturity,
      askDeadline: value.askDeadline,
      askPricingRange: value.askPriceRange,
      askProjectType: value.askProjectType,
      urlRedirect: value.urlRedirect,
    };
  },
  reset: () => {
    context.requirementId = undefined;
    context.openAiQuestionsFormToDisplay = [];
  },
  isLargeScreen: true,
};

export const disckoContext: React.Context<ContextProps> = createContext(context);

/* --------------------------------------------------- Component -------------------------------------------------- */

export enum Step {
  CONTACT = 'contact',
  PROJECT = 'project',
  QUESTION = 'questions',
  COMMENT = 'comment',
  END = 'end',
}

export function Discko(): JSX.Element {
  const { requirementService, companyService } = useContext(DisckoApisContext);
  const [searchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();

  const [hasCompanyEnoughCredits, setHasCompanyEnoughCredits] = useState<boolean>();

  const [activeForm, setActiveForm] = useState<string>(Step.CONTACT);

  const [requirementParams, setRequirementParams] = useState<GetRequirementIdParamsDto>();

  const { maintenanceInfos } = useContext(MaintenanceContext);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const companyId: string | undefined = searchParams.get('companyid') ?? undefined;

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: companyCreditsData } = useQuery({
    queryKey: ['company', companyId],
    queryFn: async () => companyService.isCompanyIsAllowedToUseDiscko(companyId ?? ''),
    enabled: companyId !== undefined,
  });

  useEffect(() => {
    if (companyCreditsData !== undefined) {
      setHasCompanyEnoughCredits(companyCreditsData);
    }
  }, [companyCreditsData]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  const { openAiService } = useContext(DisckoApisContext);
  context.setupProject = async (openAiInputs: SetupProjectInputsDto): Promise<AgencyFormOrchestratorResponseDto> => {
    if (companyId === undefined) {
      navigate('/');
    } else {
      if (requirementParams === undefined) console.error('requirementId is undefined');
      else {
        return await openAiService.setupProject(companyId, {
          requirementId: requirementParams.id,
          step: activeForm.toString(),
          clientMaturity: openAiInputs.clientMaturity,
          projectMaturity: openAiInputs.projectMaturity,
          projectDescription: openAiInputs.projectDescription,
          deadlineCategory: openAiInputs.deadlineCategory,
          deadlineDate: openAiInputs.deadlineDate,
          priceRange: openAiInputs.priceRange,
        });
      }
    }
    return {
      step: '',
      message: {
        content: '',
        role: '',
      },
    };
  };
  context.sendQuestions = async (
    openAiInputs: ReceiveQuestionsInputsDto,
  ): Promise<AgencyFormOrchestratorResponseDto> => {
    if (companyId === undefined) {
      navigate('/');
    } else {
      if (requirementParams === undefined) console.error('requirementId is undefined');
      else {
        return await openAiService.sendQuestion(companyId, {
          requirementId: requirementParams.id,
          step: activeForm.toString(),
          questions: openAiInputs.questions,
        });
      }
    }
    return {
      step: '',
      message: {
        content: '',
        role: '',
      },
    };
  };
  context.initRequirement = async (clientsInfo: CreateAgencyClientDto): Promise<GetRequirementIdParamsDto> => {
    if (companyId === undefined) console.error('companyId is undefined');
    return await requirementService.initRequirement(clientsInfo, companyId ?? '');
  };

  context.requirementId = requirementParams?.id;
  context.setRequirementParams = setRequirementParams;
  context.requirementParams = {
    askProjectMaturity: requirementParams?.askProjectMaturity ?? false,
    askDeadline: requirementParams?.askDeadline ?? false,
    askPricingRange: requirementParams?.askPriceRange ?? false,
    askProjectType: requirementParams?.askProjectType ?? false,
    urlRedirect: requirementParams?.urlRedirect ?? undefined,
  };
  context.isLargeScreen = isLargeScreen ?? true;
  context.companyIsAllowedToUseDiscko = hasCompanyEnoughCredits;

  /* ---------------------------------------------- Create Requirement ---------------------------------------------- */

  if (isLargeScreen === undefined) return <div></div>;

  return (
    <ScreebProvider autoInit websiteId="988babb4-d9ee-4aa0-ba2b-da236c484619">
      <Modal isOpen={hasCompanyEnoughCredits === false}>
        <RichText
          fragments={[
            {
              content: "Malheureusement, la solution n'est pas disponible pour le moment.",
              contentType: 'p',
            },
            {
              content: 'Nous reviendrons vers vous avec une solution dans les plus brefs délais.',
              contentType: 'p',
            },
          ]}
        ></RichText>
      </Modal>
      <div
        className={concatClassNames(
          'flex flex-col gap-1 py-4 h-screen relative bg-white',
          'items-stretch flex-grow',
          'overflow-auto',
        )}
      >
        <disckoContext.Provider value={context}>
          {maintenanceInfos.beginningDate !== undefined && maintenanceInfos.endDate !== undefined && <div></div>}
          {activeForm === Step.CONTACT && isLargeScreen !== undefined && <ClientInfoForm setNextStep={setActiveForm} />}
          {activeForm === Step.PROJECT && <DisckoFormProject setNextStep={setActiveForm} />}
          {activeForm === Step.QUESTION && <DisckoFormQuestions setNextStep={setActiveForm} />}
          {activeForm === Step.COMMENT && <DisckoFormComment setNextStep={setActiveForm} />}
          {activeForm === Step.END && <DisckoFormEndRequirement />}
          <div className={concatClassNames(isLargeScreen ? 'pl-16' : 'pl-4 text-xs')}>
            {/* {'Powered by Discko.io'} */}
            <RichText
              fragments={[
                {
                  contentType: 'span',
                  position: 'justify',
                  content: 'Propulsé par ',
                  color: 'black',
                  size: 'sm',
                },
                {
                  contentType: 'link',
                  position: 'justify',
                  content: 'Discko.io',
                  color: 'black',
                  weight: 'bold',
                  size: 'sm',
                  onClick: () => window.open('https://discko.io', '_blank'),
                },
              ]}
            ></RichText>
          </div>
        </disckoContext.Provider>
      </div>
    </ScreebProvider>
  );
}
