import { DisckoApisContext } from 'App';
import { type GetRequirementDto, type GetRequirementsDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { Text } from 'components/core/Text/Text';
import { RequirementRow } from 'components/requirements/RequirementRow';

import concatClassNames from 'utils/classNames';
import { type CompletionType } from 'utils/getCompletionTypeChip';
import { type ProjectType } from 'utils/getProjectTypeChip';

export function RequirementsWidget(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const navigate: NavigateFunction = useNavigate();
  const { requirementService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently } = useAuth0();

  const [displayedRequirements, setDisplayedRequirements] = useState<GetRequirementDto[]>([]);
  const [isCompanyNameSortedAsc, setIsCompanyNameSortedAsc] = useState<boolean>(false);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  async function getRequirements(): Promise<GetRequirementsDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await requirementService.findAll(accessToken);
  }

  const { data: requirementsData, status: requirementsStatus } = useQuery<GetRequirementsDto, Error>(
    'requirements',
    getRequirements,
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (requirementsStatus === 'success') {
      // separate the read an unread requirements
      const unreadRequirements: GetRequirementDto[] = requirementsData.requirements.filter(
        (requirement) => !requirement.read,
      );
      const readRequirements: GetRequirementDto[] = requirementsData.requirements.filter(
        (requirement) => requirement.read,
      );
      // sort them by date
      unreadRequirements.sort((a, b) => (a.finishedAt > b.finishedAt ? -1 : 1));
      readRequirements.sort((a, b) => (a.finishedAt > b.finishedAt ? -1 : 1));
      // concate them
      const tmp: GetRequirementDto[] = unreadRequirements.concat(readRequirements);

      setDisplayedRequirements(tmp);
    }
  }, [requirementsData, requirementsStatus]);

  /* ---------------------------------------------------- Sorting --------------------------------------------------- */

  function sortRequirements(sortBy: string): void {
    switch (sortBy) {
      case 'agencyClient.companyName':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.agencyClient.companyName.toUpperCase() > b.agencyClient.companyName.toUpperCase()
                ? 1
                : b.agencyClient.companyName.toUpperCase() > a.agencyClient.companyName.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.agencyClient.companyName.toUpperCase() < b.agencyClient.companyName.toUpperCase()
                ? 1
                : b.agencyClient.companyName.toUpperCase() < a.agencyClient.companyName.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }

        break;
      case 'projectType':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.projectType.toUpperCase() > b.projectType.toUpperCase()
                ? 1
                : b.projectType.toUpperCase() > a.projectType.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.projectType.toUpperCase() < b.projectType.toUpperCase()
                ? 1
                : b.projectType.toUpperCase() < a.projectType.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
      case 'createdAt':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.finishedAt > b.finishedAt ? 1 : b.finishedAt > a.finishedAt ? -1 : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.finishedAt < b.finishedAt ? 1 : b.finishedAt < a.finishedAt ? -1 : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
      case 'read':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) => (a.read > b.read ? 1 : b.read > a.read ? -1 : 0)),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) => (a.read < b.read ? 1 : b.read < a.read ? -1 : 0)),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
    }
  }

  function handleSortBy(sortBy: string): void {
    sortRequirements(sortBy);
  }

  function formatDate(dateToFormat: Date): string {
    const day: string = ('0' + String(dateToFormat.getDate())).slice(-2);
    const monthNumber: string = ('0' + String(dateToFormat.getMonth() + 1)).slice(-2);
    const fullYear: string = String(dateToFormat.getFullYear());
    const hour: string = ('0' + String(dateToFormat.getHours())).slice(-2);
    const minutes: string = ('0' + String(dateToFormat.getMinutes())).slice(-2);
    const dateAndTime: string = `${day}/${monthNumber}/${fullYear} à ${hour}:${minutes}`;
    return dateAndTime;
  }

  const widgetHeight: number | undefined = document.getElementById('requirements_container')?.clientHeight;
  const requirementRowsToDisplay: number =
    widgetHeight !== undefined ? Math.floor(Math.trunc(widgetHeight - 106) / 56) : 2;

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */
  return (
    <>
      <div id="requirements_container" className={concatClassNames('h-full', 'w-full', 'flex', 'flex-row')}>
        <div className={concatClassNames('flex flex-col w-full')}>
          <div
            className={concatClassNames('flex flex-row justify-between items-center', 'hover:cursor-pointer')}
            onClick={() => {
              navigate('/requirements');
            }}
          >
            <Text size="lg" weight="bold" content="Vos dernières expressions de besoin"></Text>
            {requirementsStatus === 'success' && requirementsData.requirements.length > 0 && (
              <div
                className={concatClassNames(
                  'flex flex-row',
                  'gap-1 items-center',
                  'border-1 border-black rounded-lg',
                  ' p-2',
                  'hover:cursor-pointer hover:drop-shadow-lg hover:bg-slate-50',
                )}
                onClick={() => {
                  navigate('/requirements');
                }}
              >
                <div className={concatClassNames('h-3 w-3 bg-red-500 rounded-full')}></div>

                <Text
                  size="xs"
                  weight="bold"
                  content={`${requirementsData?.requirements
                    .filter((requirement) => !requirement.read)
                    .length.toString()} élément(s) non lu(s)`}
                />
              </div>
            )}
          </div>

          <div
            id="requirements"
            className={concatClassNames('flex flex-col w-full', isLargeScreen === true ? '' : 'h-[200px]')}
          >
            <ul id="requirementsZone" className={concatClassNames('flex flex-col w-full gap-3', 'overflow-auto')}>
              <RequirementRow title setSortBy={handleSortBy} chipSize="sm" />

              {requirementsStatus === 'success' &&
                displayedRequirements.length > 0 &&
                displayedRequirements
                  .slice(0, requirementRowsToDisplay)
                  .map((requirement: GetRequirementDto) => (
                    <RequirementRow
                      key={requirement.id}
                      id={requirement.id}
                      name={requirement.agencyClient.companyName}
                      completionType={requirement.completionType as CompletionType}
                      projectType={requirement.projectType as ProjectType}
                      read={requirement.read}
                      createdAt={formatDate(requirement.finishedAt)}
                      chipSize="sm"
                    />
                  ))}
              {requirementsStatus === 'success' && requirementsData.requirements.length === 0 && (
                <div
                  className={concatClassNames(
                    'flex flex-row items-center justify-center',
                    'h-full',
                    'text-center',
                    'text-gray-500',
                  )}
                >
                  Aucune expression de besoin
                </div>
              )}
              {requirementsStatus === 'success' &&
                displayedRequirements.length > 0 &&
                displayedRequirements.length > requirementRowsToDisplay && (
                  <div
                    onClick={() => {
                      navigate('/requirements');
                    }}
                    className={concatClassNames(
                      'bg-slate-200',
                      'h-full',
                      'hover:bg-slate-300',
                      'cursor-pointer',
                      'rounded-lg',
                      'flex flex-row items-center justify-center',
                    )}
                  >
                    . . .
                  </div>
                )}
              {requirementsStatus === 'loading' && <RequirementRow loading />}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
