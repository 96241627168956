/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAgencyClientDto
 */
export interface GetAgencyClientDto {
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    companyWebsite: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyClientDto
     */
    contactPhone: string;
}

/**
 * Check if a given object implements the GetAgencyClientDto interface.
 */
export function instanceOfGetAgencyClientDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "companyWebsite" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "contactLastname" in value;
    isInstance = isInstance && "contactFirstname" in value;
    isInstance = isInstance && "contactPhone" in value;

    return isInstance;
}

export function GetAgencyClientDtoFromJSON(json: any): GetAgencyClientDto {
    return GetAgencyClientDtoFromJSONTyped(json, false);
}

export function GetAgencyClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgencyClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyName': json['companyName'],
        'companyWebsite': json['companyWebsite'],
        'contactEmail': json['contactEmail'],
        'contactLastname': json['contactLastname'],
        'contactFirstname': json['contactFirstname'],
        'contactPhone': json['contactPhone'],
    };
}

export function GetAgencyClientDtoToJSON(value?: GetAgencyClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'companyWebsite': value.companyWebsite,
        'contactEmail': value.contactEmail,
        'contactLastname': value.contactLastname,
        'contactFirstname': value.contactFirstname,
        'contactPhone': value.contactPhone,
    };
}

