/* eslint-disable max-len */
import React from 'react';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function ListIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={iconSizeStyle[size]} viewBox="0 0 463 338" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M200.5 61.2347H277.5V37.7354C287.5 36.5354 300 38.2354 305 39.2354C417.5 77.2354 382.5 291.235 312 297.235C308.887 297.5 288.5 298.902 278 298.235V297.235V176.735H200V282.235C201.6 299.835 181.333 303.235 171 302.735C172.2 316.735 178.833 328.902 182 333.235H209.5L254 335.235C269.2 338.035 302 337.735 316.5 337.234C549 303.234 469 -9.76464 316.5 0.234439H287.5L238.5 3.73444L182 5.23444C173.2 14.8344 171.333 28.9013 171.5 34.7347C198.7 33.1347 202.167 51.7347 200.5 61.2347Z"
        fill={iconColorStyle[color]}
      />
      <rect x="197" y="79" width="79" height="80.0128" rx="15" fill={iconColorStyle[color]} />
      <rect x="97" y="55" width="79" height="80.0128" rx="15" fill={iconColorStyle[color]} />
      <rect y="16" width="79" height="80.0128" rx="15" fill={iconColorStyle[color]} />
    </svg>
  );
}
