/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMaintenanceInfosDto
 */
export interface GetMaintenanceInfosDto {
    /**
     * 
     * @type {string}
     * @memberof GetMaintenanceInfosDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetMaintenanceInfosDto
     */
    state: GetMaintenanceInfosDtoStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof GetMaintenanceInfosDto
     */
    beginningDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetMaintenanceInfosDto
     */
    endDate: Date;
}


/**
 * @export
 */
export const GetMaintenanceInfosDtoStateEnum = {
    InProgress: 'inProgress',
    Planned: 'planned',
    Finished: 'finished'
} as const;
export type GetMaintenanceInfosDtoStateEnum = typeof GetMaintenanceInfosDtoStateEnum[keyof typeof GetMaintenanceInfosDtoStateEnum];


/**
 * Check if a given object implements the GetMaintenanceInfosDto interface.
 */
export function instanceOfGetMaintenanceInfosDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "beginningDate" in value;
    isInstance = isInstance && "endDate" in value;

    return isInstance;
}

export function GetMaintenanceInfosDtoFromJSON(json: any): GetMaintenanceInfosDto {
    return GetMaintenanceInfosDtoFromJSONTyped(json, false);
}

export function GetMaintenanceInfosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMaintenanceInfosDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'beginningDate': (new Date(json['beginningDate'])),
        'endDate': (new Date(json['endDate'])),
    };
}

export function GetMaintenanceInfosDtoToJSON(value?: GetMaintenanceInfosDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': value.state,
        'beginningDate': (value.beginningDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
    };
}

