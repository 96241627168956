/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisckoUserControllerRemove200Response
 */
export interface DisckoUserControllerRemove200Response {
    /**
     * 
     * @type {number}
     * @memberof DisckoUserControllerRemove200Response
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof DisckoUserControllerRemove200Response
     */
    message?: string;
}

/**
 * Check if a given object implements the DisckoUserControllerRemove200Response interface.
 */
export function instanceOfDisckoUserControllerRemove200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DisckoUserControllerRemove200ResponseFromJSON(json: any): DisckoUserControllerRemove200Response {
    return DisckoUserControllerRemove200ResponseFromJSONTyped(json, false);
}

export function DisckoUserControllerRemove200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisckoUserControllerRemove200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function DisckoUserControllerRemove200ResponseToJSON(value?: DisckoUserControllerRemove200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
    };
}

