import React from 'react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type MenuButtonProps } from './Menu.props';

export function MenuButton({ id, description, iconName, isSelected, onClick }: MenuButtonProps): JSX.Element {
  function handleClick(): void {
    onClick(id);
  }
  return (
    <div
      className={concatClassNames(
        'flex flex-row items-start p-2.5 gap-2.5 h-11 w-11',
        isSelected ? 'bg-white/20 rounded-3xl' : 'hover:bg-white/20 hover:rounded-3xl',
      )}
    >
      <button onClick={handleClick}>
        <div>{getIcon(iconName, 'white', 'md')}</div>
      </button>
    </div>
  );
}
