import { RequirementsParametersApi, type UpdateRequirementsParametersDto } from 'api';

interface GetOpenAIResponseProps {
  updateRequirementsParametersDto: UpdateRequirementsParametersDto;
  accessToken: string;
}
export class RequirementParametersService {
  private readonly requirementsParametersClient: RequirementsParametersApi;

  constructor() {
    this.requirementsParametersClient = new RequirementsParametersApi();
  }

  public async saveRequirementParameters({
    updateRequirementsParametersDto,
    accessToken,
  }: GetOpenAIResponseProps): Promise<UpdateRequirementsParametersDto> {
    return this.requirementsParametersClient.requirementsParametersControllerUpdateProjectQuestions(
      {
        updateRequirementsParametersDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
