import React from 'react';

export function FrontOfficeCGU(): JSX.Element {
  return (
    <div className="max-w-3xl mx-auto ">
      <div className="flex flex-col gap-8 mx-auto text-justify">
        <div className="">
          <br />
          <p className="font-bold text-base">
            {`VEUILLEZ LIRE ATTENTIVEMENT CES CONDITIONS GÉNÉRALES D’UTILISATION (« CGU ») AVANT TOUTE UTILISATION DE LA
          SOLUTION « DISCKO » proposée par la Société par Actions Simplifiée SPECKS, immatriculée au RCS de Nantes sous
          le numéro 980 269 062, dont le siège social est sis 1 rue Pauline Roland à Nantes (44200).`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">1. Acceptation des conditions</h2>
          <p>
            {`En accédant et en utilisant la solution DISCKO, vous acceptez sans réserve les présentes Conditions Générales
          d'Utilisation (CGU). Si vous ne les acceptez pas, veuillez ne pas utiliser nos services.`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">2. Accès à DISCKO</h2>
          <p>
            {`DISCKO est accessible 24/7, sauf en cas de force majeure ou d'événements hors de notre contrôle, et sous
          réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement de nos
          services.`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">3. Utilisation des services</h2>
          <p>
            {`L'utilisateur s'engage à utiliser les services de DISCKO de manière conforme aux lois et règlements en vigueur,
          ainsi qu'aux présentes CGU. Toute utilisation frauduleuse ou abusive est interdite et pourra entraîner la
          suspension ou la résiliation de l'accès aux services.`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">4. Propriété intellectuelle</h2>
          <p>
            {`Tout le contenu présent sur DISCKO (textes, images, graphismes, logos, icônes, sons, code, etc.) est protégé par
          les lois relatives à la propriété intellectuelle et est la propriété exclusive de DISCKO ou de ses partenaires.
          Toute reproduction, distribution, modification ou utilisation de ce contenu sans autorisation préalable est
          interdite.`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">5. Responsabilité</h2>
          <p>
            {`SPECKS ne peut être tenue responsable des dommages directs ou indirects résultant de l'utilisation ou de
          l'incapacité à utiliser DISCKO. SPECKS s'efforce d'assurer l'exactitude des informations présentées, sans
          toutefois garantir leur exhaustivité ou leur mise à jour constante. SPECKS ne sera pas tenue pour responsable
          pour toute réclamation qui provient de: (i) l'utilisation par le client des produits, services, solutions
          autrement que conformément aux Conditions Générales de Service (y compris notamment l'utilisation de la solution
          par un individu autre qu'utilisateur autorisé), ou encore (ii) l'utilisation des produits, services, solutions
          non conforme aux dispositions légales et réglementaires.`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">6. Données personnelles</h2>
          <p>
            {`Les informations personnelles collectées par DISCKO sont traitées conformément à notre politique de
          confidentialité, disponible sur le site de DISCKO. Vous disposez d'un droit d'accès, de rectification et
          d'opposition aux données vous concernant, que vous pouvez exercer en contactant DISCKO.`}
          </p>
        </div>

        <div className=" ">
          <h2 className="text-2xl font-semibold mb-2">7. Modification des CGU</h2>
          <p>
            {`SPECKS se réserve le droit de modifier les présentes CGU à tout moment. Les modifications entreront en vigueur
          dès leur publication sur le site. Nous vous encourageons à consulter régulièrement cette page pour prendre
          connaissance des éventuelles modifications.`}
          </p>
        </div>

        <div className="">
          <h2 className="text-2xl font-semibold mb-2">8. Loi applicable et juridiction</h2>
          <p>
            {`Les présentes CGU sont soumises au droit français. En cas de litige, et après tentative de résolution amiable,
          compétence exclusive est attribuée aux tribunaux français.`}
          </p>
        </div>

        <div className="">
          <h2 className="text-2xl font-semibold mb-2">9. Contact</h2>
          <p>
            {`Pour toute question relative aux présentes CGU, vous pouvez nous contacter à l'adresse suivante : `}
            <a href="mailto:contact@discko.io" className="text-blue-500 underline">
              contact@discko.io
            </a>
            {`.`}
          </p>
        </div>
      </div>
    </div>
  );
}
