import { type GetMaintenanceInfosDto, MaintenanceApi } from 'api';

export class MaintenanceService {
  private readonly maintenanceClient: MaintenanceApi;

  constructor() {
    this.maintenanceClient = new MaintenanceApi();
  }

  public async getMaintenanceInfos(): Promise<GetMaintenanceInfosDto> {
    return await this.maintenanceClient.maintenanceControllerGetMaintenanceInfos();
  }
}
