/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAgencyClientDto
 */
export interface CreateAgencyClientDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyClientDto
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyClientDto
     */
    companyWebsite?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyClientDto
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyClientDto
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyClientDto
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyClientDto
     */
    contactPhone?: string;
}

/**
 * Check if a given object implements the CreateAgencyClientDto interface.
 */
export function instanceOfCreateAgencyClientDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "contactLastname" in value;
    isInstance = isInstance && "contactFirstname" in value;

    return isInstance;
}

export function CreateAgencyClientDtoFromJSON(json: any): CreateAgencyClientDto {
    return CreateAgencyClientDtoFromJSONTyped(json, false);
}

export function CreateAgencyClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAgencyClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyWebsite': !exists(json, 'companyWebsite') ? undefined : json['companyWebsite'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactLastname': json['contactLastname'],
        'contactFirstname': json['contactFirstname'],
        'contactPhone': !exists(json, 'contactPhone') ? undefined : json['contactPhone'],
    };
}

export function CreateAgencyClientDtoToJSON(value?: CreateAgencyClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyWebsite': value.companyWebsite,
        'contactEmail': value.contactEmail,
        'contactLastname': value.contactLastname,
        'contactFirstname': value.contactFirstname,
        'contactPhone': value.contactPhone,
    };
}

