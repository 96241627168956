import React from 'react';

import concatClassNames from 'utils/classNames';

interface MaxCharVisualizerProps {
  maxCharVisualizer: number;
  watchLength: number;
}
export function MaxCharVisualizer({ maxCharVisualizer, watchLength }: MaxCharVisualizerProps): JSX.Element {
  return (
    <div className={concatClassNames('absolute bottom-1 right-1 flex flex-row gap-2')}>
      <p className={concatClassNames(watchLength > maxCharVisualizer ? 'text-red-500 font-bold' : 'text-black')}>
        {watchLength}
      </p>
      <p> / </p>
      <p> {maxCharVisualizer}</p>
    </div>
  );
}
