/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoUserIdDto } from './DisckoUserIdDto';
import {
    DisckoUserIdDtoFromJSON,
    DisckoUserIdDtoFromJSONTyped,
    DisckoUserIdDtoToJSON,
} from './DisckoUserIdDto';

/**
 * 
 * @export
 * @interface GetDisckoCompanyDto
 */
export interface GetDisckoCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    companyStatus: GetDisckoCompanyDtoCompanyStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetDisckoCompanyDto
     */
    credits: number;
    /**
     * 
     * @type {Date}
     * @memberof GetDisckoCompanyDto
     */
    subscriptionDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isSubscribed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    askDeadline?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    askPriceRange?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    askProjectMaturity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    askProjectType?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    questionFocus: GetDisckoCompanyDtoQuestionFocusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    urlRedirect?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isOldPrice?: boolean;
    /**
     * 
     * @type {Array<DisckoUserIdDto>}
     * @memberof GetDisckoCompanyDto
     */
    users: Array<DisckoUserIdDto>;
}


/**
 * @export
 */
export const GetDisckoCompanyDtoCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetDisckoCompanyDtoCompanyStatusEnum = typeof GetDisckoCompanyDtoCompanyStatusEnum[keyof typeof GetDisckoCompanyDtoCompanyStatusEnum];

/**
 * @export
 */
export const GetDisckoCompanyDtoQuestionFocusEnum = {
    Needs: 'NEEDS',
    Solution: 'SOLUTION'
} as const;
export type GetDisckoCompanyDtoQuestionFocusEnum = typeof GetDisckoCompanyDtoQuestionFocusEnum[keyof typeof GetDisckoCompanyDtoQuestionFocusEnum];


/**
 * Check if a given object implements the GetDisckoCompanyDto interface.
 */
export function instanceOfGetDisckoCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "credits" in value;
    isInstance = isInstance && "isSubscribed" in value;
    isInstance = isInstance && "questionFocus" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function GetDisckoCompanyDtoFromJSON(json: any): GetDisckoCompanyDto {
    return GetDisckoCompanyDtoFromJSONTyped(json, false);
}

export function GetDisckoCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDisckoCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyStatus': json['companyStatus'],
        'credits': json['credits'],
        'subscriptionDate': !exists(json, 'subscriptionDate') ? undefined : (new Date(json['subscriptionDate'])),
        'isSubscribed': json['isSubscribed'],
        'askDeadline': !exists(json, 'askDeadline') ? undefined : json['askDeadline'],
        'askPriceRange': !exists(json, 'askPriceRange') ? undefined : json['askPriceRange'],
        'askProjectMaturity': !exists(json, 'askProjectMaturity') ? undefined : json['askProjectMaturity'],
        'askProjectType': !exists(json, 'askProjectType') ? undefined : json['askProjectType'],
        'questionFocus': json['questionFocus'],
        'urlRedirect': !exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'isOldPrice': !exists(json, 'isOldPrice') ? undefined : json['isOldPrice'],
        'users': ((json['users'] as Array<any>).map(DisckoUserIdDtoFromJSON)),
    };
}

export function GetDisckoCompanyDtoToJSON(value?: GetDisckoCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'companyStatus': value.companyStatus,
        'credits': value.credits,
        'subscriptionDate': value.subscriptionDate === undefined ? undefined : (value.subscriptionDate.toISOString()),
        'isSubscribed': value.isSubscribed,
        'askDeadline': value.askDeadline,
        'askPriceRange': value.askPriceRange,
        'askProjectMaturity': value.askProjectMaturity,
        'askProjectType': value.askProjectType,
        'questionFocus': value.questionFocus,
        'urlRedirect': value.urlRedirect,
        'isOldPrice': value.isOldPrice,
        'users': ((value.users as Array<any>).map(DisckoUserIdDtoToJSON)),
    };
}

