/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

interface CurrentPlanProps {
  isOldPrice?: boolean;
  isSubscribed: boolean;
}
export function CurrentPlan({ isSubscribed, isOldPrice = false }: CurrentPlanProps): JSX.Element {
  const { stripeService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  async function openBillingPortal(): Promise<string> {
    const accessToken: string = await getAccessTokenSilently();
    return await stripeService.openBillingPortal(accessToken, window.location.origin + '/settings');
  }

  const { data: billingData, status: billingStatus } = useQuery<string, Error>(
    ['billing', user?.sub],
    openBillingPortal,
  );

  // Open the billing portal

  async function createCheckoutSession(): Promise<string> {
    const accessToken: string = await getAccessTokenSilently();
    if (user === undefined || user.sub === undefined) return '';
    return await stripeService.createCheckoutSession({
      accessToken,
      itemName: 'SUBSCRIPTION',
    });
  }

  const [clickedOnSubscribe, setClickedOnSubscribe] = useState<boolean>(false);

  const { data, status } = useQuery(['createCheckoutSession'], createCheckoutSession, {
    enabled: clickedOnSubscribe,
  });

  useEffect(() => {
    if (status === 'success' && clickedOnSubscribe) {
      window.open(data, '_blank');
      setClickedOnSubscribe(false);
    }
    if (status === 'error') {
      console.error('Error creating checkout session');
    }
  }, [clickedOnSubscribe, status]);

  return (
    <>
      {isLargeScreen === true && (
        <>
          {/* // si tarification à l'usage */}
          {!isSubscribed && (
            <div className={concatClassNames('flex flex-row gap-6 p-4 items-start')}>
              <div className="flew bg-blue-400 rounded-lg p-6 text-white">
                <div className="flex flex-col align-middle gap-4 items-start w-full">
                  <p className="text-lg font-bold">{'Envie de booster votre prospection ?'}</p>
                  <p className="uppercase text-lg font-bold">{'Choisissez notre formule sans engagement'}</p>
                  <div className="flex flex-row items-center gap-1">
                    <Text content={isOldPrice ? '25€' : '35€'} size="4xl" weight="bold" color="white" />
                    <p className="text-base text-white"> HT/mois</p>
                  </div>
                </div>
                <br />

                <div className="flex flex-col items-center">
                  <div className="flex flex-col justify-start text-lg w-full">
                    <ul>
                      <li>{'✅ Expressions de besoin illimitées.'}</li>
                      <li>{"✅ Jusqu'à 10 administrateurs."}</li>
                      <li>{'✅ Personnalisation du formulaire.'} </li>
                    </ul>
                  </div>
                  <br />
                  <Button
                    bgColor="white"
                    textColor="black"
                    content="Souscrire dès maintenant"
                    onClick={() => {
                      setClickedOnSubscribe(true);
                    }}
                  ></Button>
                </div>
              </div>
            </div>
          )}
          {/* //sinon */}
          {isSubscribed && (
            <div className="flew bg-white rounded-lg p-6 w-fit">
              <div className="flex flex-col align-middle gap-4 items-start w-full">
                <p className="text-lg font-bold">{'Votre plan actuel : '}</p>
                <p className="uppercase text-lg font-bold">{'Abonnement mensuel'}</p>
              </div>
              <br />

              <div className="flex flex-col">
                <div className="flex flex-col justify-start text-lg w-full">
                  <ul>
                    <li>{'✅ Expressions de besoin illimitées.'}</li>
                    <li>{"✅ Jusqu'à 10 administrateurs."}</li>
                    <li>{'✅ Personnalisation du formulaire.'} </li>
                  </ul>
                </div>
                <br />
                {billingStatus === 'success' && billingData !== '' && (
                  <Button
                    bgColor="blue-400"
                    content="Mon abonnement et facturation"
                    onClick={async () => {
                      window.open(billingData, '_blank');
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
      {isLargeScreen === false && (
        <>
          {/* // si tarification à l'usage */}
          {!isSubscribed && (
            <div className={concatClassNames('flex flex-col gap-6 py-4 items-start')}>
              <div className="flew bg-blue-400 rounded-lg p-6 text-white">
                <div className="flex flex-col align-middle gap-4 items-start w-full">
                  <p className="text-sm font-bold">{'Envie de booster votre prospection ?'}</p>
                  <p className="uppercase text-sm font-bold">{'Choisissez notre formule sans engagement'}</p>
                  <div className="flex flex-row items-center gap-1">
                    <Text content={isOldPrice ? '25€' : '35€'} size="4xl" weight="bold" color="white" />
                    <p className="text-base text-white"> HT/mois</p>
                  </div>
                </div>
                <br />

                <div className="flex flex-col items-center">
                  <div className="flex flex-col justify-start text-xs w-full">
                    <ul>
                      <li>{'✅ Expressions de besoin illimitées.'}</li>
                      <li>{"✅ Jusqu'à 10 administrateurs."}</li>
                      <li>{'✅ Personnalisation du formulaire.'} </li>
                    </ul>
                  </div>
                  <br />
                  <Button
                    bgColor="white"
                    textColor="black"
                    content="Souscrire dès maintenant"
                    onClick={() => {
                      setClickedOnSubscribe(true);
                    }}
                  ></Button>
                </div>
              </div>
            </div>
          )}
          {/* //sinon */}
          {isSubscribed && (
            <div className="flew bg-white rounded-lg p-6 w-fit">
              <div className="flex flex-col align-middle gap-4 items-start w-full">
                <p className="text-sm font-bold">{'Votre plan actuel : '}</p>
                <p className="uppercase text-sm font-bold">{'Abonnement mensuel'}</p>
              </div>
              <br />

              <div className="flex flex-col">
                <div className="flex flex-col justify-start text-sm w-full">
                  <ul>
                    <li>{'✅ Expressions de besoin illimitées.'}</li>
                    <li>{"✅ Jusqu'à 10 administrateurs."}</li>
                    <li>{'✅ Personnalisation du formulaire.'} </li>
                  </ul>
                </div>
                <br />
                {billingStatus === 'success' && billingData !== '' && (
                  <Button
                    bgColor="blue-400"
                    content="Mon abonnement et facturation"
                    onClick={async () => {
                      window.open(billingData, '_blank');
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
