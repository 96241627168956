/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDisckoUserDto } from './GetDisckoUserDto';
import {
    GetDisckoUserDtoFromJSON,
    GetDisckoUserDtoFromJSONTyped,
    GetDisckoUserDtoToJSON,
} from './GetDisckoUserDto';

/**
 * 
 * @export
 * @interface GetDisckoUsersDto
 */
export interface GetDisckoUsersDto {
    /**
     * 
     * @type {Array<GetDisckoUserDto>}
     * @memberof GetDisckoUsersDto
     */
    users: Array<GetDisckoUserDto>;
}

/**
 * Check if a given object implements the GetDisckoUsersDto interface.
 */
export function instanceOfGetDisckoUsersDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function GetDisckoUsersDtoFromJSON(json: any): GetDisckoUsersDto {
    return GetDisckoUsersDtoFromJSONTyped(json, false);
}

export function GetDisckoUsersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDisckoUsersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(GetDisckoUserDtoFromJSON)),
    };
}

export function GetDisckoUsersDtoToJSON(value?: GetDisckoUsersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(GetDisckoUserDtoToJSON)),
    };
}

