/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetupProjectInputsDto
 */
export interface SetupProjectInputsDto {
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    projectDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    clientMaturity?: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    projectMaturity?: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    priceRange?: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    deadlineCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    deadlineDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    requirementId: string;
    /**
     * 
     * @type {string}
     * @memberof SetupProjectInputsDto
     */
    step: string;
}

/**
 * Check if a given object implements the SetupProjectInputsDto interface.
 */
export function instanceOfSetupProjectInputsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "requirementId" in value;
    isInstance = isInstance && "step" in value;

    return isInstance;
}

export function SetupProjectInputsDtoFromJSON(json: any): SetupProjectInputsDto {
    return SetupProjectInputsDtoFromJSONTyped(json, false);
}

export function SetupProjectInputsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetupProjectInputsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectDescription': !exists(json, 'projectDescription') ? undefined : json['projectDescription'],
        'clientMaturity': !exists(json, 'clientMaturity') ? undefined : json['clientMaturity'],
        'projectMaturity': !exists(json, 'projectMaturity') ? undefined : json['projectMaturity'],
        'priceRange': !exists(json, 'priceRange') ? undefined : json['priceRange'],
        'deadlineCategory': !exists(json, 'deadlineCategory') ? undefined : json['deadlineCategory'],
        'deadlineDate': !exists(json, 'deadlineDate') ? undefined : json['deadlineDate'],
        'requirementId': json['requirementId'],
        'step': json['step'],
    };
}

export function SetupProjectInputsDtoToJSON(value?: SetupProjectInputsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectDescription': value.projectDescription,
        'clientMaturity': value.clientMaturity,
        'projectMaturity': value.projectMaturity,
        'priceRange': value.priceRange,
        'deadlineCategory': value.deadlineCategory,
        'deadlineDate': value.deadlineDate,
        'requirementId': value.requirementId,
        'step': value.step,
    };
}

