import React from 'react';

import { Chip } from 'components/core/Chip/Chip';
import { type TextSize } from 'components/core/Text/Text';

export enum ProjectType {
  Strategy = 'Strategy',
  SocialMedia = 'SocialMedia',
  Website = 'Website',
  Apps = 'Apps',
  HR = 'HR',
  Integration = 'Integration',
  Data = 'Data',
  BrandContent = 'BrandContent',
  Unknown = 'Unknown',
}

interface ProjectTypeChipProps {
  projectType: ProjectType;
  size: TextSize;
}

export default function getProjectTypeChip({
  projectType = ProjectType.Unknown,
  size,
}: ProjectTypeChipProps): JSX.Element {
  switch (projectType) {
    case ProjectType.Strategy:
      return (
        <Chip
          label="Stratégie - audit - conseil"
          textPosition="center"
          bgColor="pink-200"
          paddingBottom="sm"
          paddingHorizontal="sm"
          paddingTop="sm"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.HR:
      return (
        <Chip
          label="Recrutement - mise à disposition de compétences"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="green-200"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.SocialMedia:
      return (
        <Chip
          label="Réseaux sociaux - référencement - stratégie éditoriale"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="cyan-200"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.Website:
      return (
        <Chip
          label="Site web - site vitrine - e-commerce"
          textPosition="center"
          bgColor="purple-200"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.Apps:
      return (
        <Chip
          label="Application web ou mobile - logiciel"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="indigo-200"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.Data:
      return (
        <Chip
          label="Data - collecte, analyse, stockage de données"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="orange-200"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.BrandContent:
      return (
        <Chip
          label="Identité visuelle et de marque - logo - charte graphique"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="yellow-200"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.Integration:
      return (
        <Chip
          label="Intégration de progiciels - ERP - CRM"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="emerald-200"
          borderColor="none"
          textSize={size}
        />
      );
    case ProjectType.Unknown:
      return (
        <Chip
          label="A préciser"
          textPosition="center"
          paddingHorizontal="md"
          paddingTop="sm"
          paddingBottom="sm"
          bgColor="lime-200"
          borderColor="none"
          textSize={size}
        />
      );
    default:
      return <div>error</div>;
  }
}
