import React from 'react';

import concatClassNames from 'utils/classNames';

import AnimatedLogo from '../../assets/discko_loading.gif';

export function LoadingPage(): JSX.Element {
  return (
    <div className={concatClassNames('h-screen flex items-center justify-center', 'scale-[40%]')}>
      <img src={AnimatedLogo} />
    </div>
  );
}
