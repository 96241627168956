/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDisckoUserDto } from './GetDisckoUserDto';
import {
    GetDisckoUserDtoFromJSON,
    GetDisckoUserDtoFromJSONTyped,
    GetDisckoUserDtoToJSON,
} from './GetDisckoUserDto';

/**
 * 
 * @export
 * @interface GetFullDisckoCompanyDto
 */
export interface GetFullDisckoCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetFullDisckoCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetFullDisckoCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetFullDisckoCompanyDto
     */
    companyStatus: GetFullDisckoCompanyDtoCompanyStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetFullDisckoCompanyDto
     */
    credits: number;
    /**
     * 
     * @type {Date}
     * @memberof GetFullDisckoCompanyDto
     */
    subscriptionDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetFullDisckoCompanyDto
     */
    isSubscribed: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFullDisckoCompanyDto
     */
    customerStripeId: string;
    /**
     * 
     * @type {number}
     * @memberof GetFullDisckoCompanyDto
     */
    requirementsPastMonth: number;
    /**
     * 
     * @type {number}
     * @memberof GetFullDisckoCompanyDto
     */
    requirementsCurrentMonth: number;
    /**
     * 
     * @type {Array<GetDisckoUserDto>}
     * @memberof GetFullDisckoCompanyDto
     */
    users: Array<GetDisckoUserDto>;
}


/**
 * @export
 */
export const GetFullDisckoCompanyDtoCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetFullDisckoCompanyDtoCompanyStatusEnum = typeof GetFullDisckoCompanyDtoCompanyStatusEnum[keyof typeof GetFullDisckoCompanyDtoCompanyStatusEnum];


/**
 * Check if a given object implements the GetFullDisckoCompanyDto interface.
 */
export function instanceOfGetFullDisckoCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "credits" in value;
    isInstance = isInstance && "isSubscribed" in value;
    isInstance = isInstance && "customerStripeId" in value;
    isInstance = isInstance && "requirementsPastMonth" in value;
    isInstance = isInstance && "requirementsCurrentMonth" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function GetFullDisckoCompanyDtoFromJSON(json: any): GetFullDisckoCompanyDto {
    return GetFullDisckoCompanyDtoFromJSONTyped(json, false);
}

export function GetFullDisckoCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFullDisckoCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyStatus': json['companyStatus'],
        'credits': json['credits'],
        'subscriptionDate': !exists(json, 'subscriptionDate') ? undefined : (new Date(json['subscriptionDate'])),
        'isSubscribed': json['isSubscribed'],
        'customerStripeId': json['customerStripeId'],
        'requirementsPastMonth': json['requirementsPastMonth'],
        'requirementsCurrentMonth': json['requirementsCurrentMonth'],
        'users': ((json['users'] as Array<any>).map(GetDisckoUserDtoFromJSON)),
    };
}

export function GetFullDisckoCompanyDtoToJSON(value?: GetFullDisckoCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'companyStatus': value.companyStatus,
        'credits': value.credits,
        'subscriptionDate': value.subscriptionDate === undefined ? undefined : (value.subscriptionDate.toISOString()),
        'isSubscribed': value.isSubscribed,
        'customerStripeId': value.customerStripeId,
        'requirementsPastMonth': value.requirementsPastMonth,
        'requirementsCurrentMonth': value.requirementsCurrentMonth,
        'users': ((value.users as Array<any>).map(GetDisckoUserDtoToJSON)),
    };
}

