/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import { type GetDisckoUsersDto } from 'api';

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { RichText } from 'components/RichText/RichText';
import { P } from 'components/core/P';

import concatClassNames from 'utils/classNames';

export function MembersManagement(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  const { userService } = useContext(DisckoApisContext);

  const { getAccessTokenSilently, user } = useAuth0();

  async function getCompanyUsersByUserId(): Promise<GetDisckoUsersDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await userService.findMyCompanyUsers(accessToken);
  }

  const { data: usersData, status: usersStatus } = useQuery<GetDisckoUsersDto, Error>(
    ['users', user?.sub],
    getCompanyUsersByUserId,
  );

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <div>
      {isLargeScreen === true && (
        <div className="pl-4 flex flex-col gap-4">
          <P
            position="left"
            weight="normal"
            text="Voici les membres de votre équipe actuellement inscrits sur Discko : "
          />

          <ul className={concatClassNames('list-disc list-inside flex flex-col')}>
            {usersStatus === 'success' &&
              usersData.users.map((member) => (
                <li key={uuid()} className={concatClassNames('flex flex-row', 'gap-2')}>
                  <div className="font-bold">{member.name}</div> <div></div> <div>{`[${member.email}]`}</div>
                </li>
              ))}
          </ul>
          <div className="">
            <RichText
              fragments={[
                {
                  contentType: 'span',
                  position: 'justify',
                  content: 'Pour inviter de nouveaux membres, envoyez-nous un email à ',
                  color: 'black',
                  size: 'lg',
                },
                {
                  contentType: 'link',
                  position: 'justify',
                  content: 'contact@discko.io',
                  color: 'gray-500',
                  size: 'lg',
                  weight: 'bold',
                  onClick: () => (window.location.href = 'mailto:contact@discko.io'),
                },
                {
                  contentType: 'span',
                  position: 'justify',
                  content: ' avec leurs coordonnées.',
                  color: 'black',
                  size: 'lg',
                },
              ]}
            />
          </div>
        </div>
      )}
      {isLargeScreen === false && (
        <div className="pl-4 flex flex-col gap-2">
          <p className="text-sm text-justify">
            {'Voici les membres de votre équipe actuellement inscrits sur Discko :'}{' '}
          </p>

          <ul className={concatClassNames('list-disc list-inside text-xs')}>
            {usersStatus === 'success' &&
              usersData.users.map((member) => (
                <li key={uuid()}>
                  <span>{member.name}</span> - <span>{member.email}</span>
                </li>
              ))}
          </ul>

          <RichText
            fragments={[
              {
                contentType: 'span',
                position: 'justify',
                content: 'Pour inviter de nouveaux membres, envoyez-nous un email à ',
                color: 'black',
                size: 'sm',
              },
              {
                contentType: 'link',
                position: 'justify',
                content: 'contact@discko.io',
                color: 'black',
                size: 'sm',
                weight: 'bold',
                onClick: () => (window.location.href = 'mailto:contact@discko.io'),
              },
              {
                contentType: 'span',
                position: 'justify',
                content: ' avec leurs coordonnées.',
                color: 'black',
                size: 'sm',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}
