import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto, type GetFullDisckoCompaniesDto } from 'api';
import * as Yup from 'yup';

import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { CompanyRow } from 'components/Admin-CompanyRow/CompanyRow';
import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export function SuperAdminBackOfficePage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const queryClient: QueryClient = useQueryClient();

  const { companyService, adminService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  const [_, setIsLoading] = useState<boolean>(false);
  const [isAddCreditModalOpen, setIsAddCreditModalOpen] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [isNewClientLoading, setIsNewClientLoading] = useState<boolean>(false);
  const [isNewMemberLoading, setIsNewMemberLoading] = useState<boolean>(false);

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  /* -------------------------------------------------- Get Company ------------------------------------------------- */

  async function amIAdmin(): Promise<boolean> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.isAdminCompany(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<boolean, Error>(['admin', user?.sub], amIAdmin);

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  async function getCompanies(): Promise<GetFullDisckoCompaniesDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.getCompanies(accessToken);
  }

  const {
    data: companiesData,
    status: companiesStatus,
    refetch: comapniesRefetch,
  } = useQuery<GetFullDisckoCompaniesDto, Error>(['companies', 'allCompanies'], getCompanies);

  /* -------------------------------------------------- Add Credits ------------------------------------------------- */

  async function addCredits(creditsToAdd: number): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.addCreditsToCompany(accessToken, selectedCompanyId, creditsToAdd);
  }

  const { mutate: mutateCompaniesAddCredit } = useMutation(addCredits, {
    onSuccess: (companyUpdated: GetDisckoCompanyDto) => {
      queryClient.setQueryData<GetFullDisckoCompaniesDto>(
        ['companies', 'allCompanies'],
        (oldData: GetFullDisckoCompaniesDto | undefined) => {
          if (oldData === undefined) {
            return { companies: [] };
          }
          // ajoute moi un crédit à la companyId et renvoie moi le tableau
          oldData.companies = oldData.companies.map((company) => {
            if (company.id === companyUpdated.id) {
              company.credits = companyUpdated.credits;
              return company;
            }
            return company;
          });

          return oldData;
        },
      );
    },
    onError: () => {
      // toast.error('Il y a eu un problème au moment de la création de cette entrée.');
    },
  });

  async function handleAddCredits(): Promise<void> {
    setIsLoading(true);
    if (companiesStatus === 'success') {
      mutateCompaniesAddCredit(1);
    }
  }

  /* -------------------------------------------------- New client -------------------------------------------------- */

  interface NewCompanyFormModel {
    companyFormCompanyName: string;
  }

  const schemaNewClient: Yup.ObjectSchema<NewCompanyFormModel> = Yup.object().shape({
    companyFormCompanyName: Yup.string().required("Le nom de l'entreprise est requis"),
  });

  const {
    register: registerNewCompanyForm,
    watch: watchNewCompanyForm,
    handleSubmit: handleSubmitNewClient,
    reset: resetNewCompanyForm,
    formState: { errors: errorsNewCompanyForm },
  } = useForm<NewCompanyFormModel>({
    resolver: yupResolver(schemaNewClient),
  });

  const watchCompanyFormCompanyName: string = watchNewCompanyForm('companyFormCompanyName');

  async function createNewAgency(data: NewCompanyFormModel): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await adminService.createCompany(accessToken, data.companyFormCompanyName);
  }

  const { mutate: mutateNewAgency } = useMutation(createNewAgency, {
    onSuccess: (company: GetDisckoCompanyDto) => {
      queryClient.setQueryData<GetFullDisckoCompaniesDto>(
        ['companies', 'allCompanies'],
        (oldData: GetFullDisckoCompaniesDto | undefined) => {
          if (oldData === undefined) {
            return { companies: [] };
          }
          oldData.companies.push({
            companyStatus: company.companyStatus,
            credits: company.credits,
            id: company.id,
            name: company.name,
            customerStripeId: '',
            users: [],
            subscriptionDate: company.subscriptionDate,
            isSubscribed: company.isSubscribed,
            requirementsCurrentMonth: 0,
            requirementsPastMonth: 0,
          });
          return oldData;
        },
      );
      setIsNewClientLoading(false);
      toast.success('La nouvelle agence a bien été créée.');
      resetNewCompanyForm();
    },
    onError: () => {
      setIsNewClientLoading(false);
      toast.error('Il y a eu un problème au moment de la création de cette entrée.');
      console.error('error');
    },
  });

  async function handleCreateNewAgency(data: NewCompanyFormModel): Promise<void> {
    setIsNewClientLoading(true);
    mutateNewAgency(data);
  }

  let isNewCompanyFormValid: boolean = true;

  if (watchCompanyFormCompanyName === '' || watchCompanyFormCompanyName === undefined) {
    isNewCompanyFormValid = false;
  }
  /* -------------------------------------------------- New member -------------------------------------------------- */

  interface NewMemberFormModel {
    memberFormCompanyId: string;
    memberFormContactName: string;
    memberFormContactEmail: string;
  }

  const schemaNewMember: Yup.ObjectSchema<NewMemberFormModel> = Yup.object().shape({
    memberFormCompanyId: Yup.string().required("L'id de l'entreprise est requis"),
    memberFormContactName: Yup.string().required('Le nom du contact est requis'),
    memberFormContactEmail: Yup.string()
      .email("L'email du contact doit être valide")
      .required("L'email du contact est requis"),
  });

  const {
    register: registerNewMemberForm,
    watch: watchNewMemberForm,
    handleSubmit: handleSubmitNewMember,
    reset: resetNewMemberForm,
    formState: { errors: errorsNewMemberForm },
  } = useForm<NewMemberFormModel>({
    resolver: yupResolver(schemaNewMember),
  });

  const watchMemberFormCompanyId: string = watchNewMemberForm('memberFormCompanyId');
  const watchMemberFormContactName: string = watchNewMemberForm('memberFormContactName');
  const watchMemberFormContactEmail: string = watchNewMemberForm('memberFormContactEmail');

  let isNewMemberFormValid: boolean = true;

  if (
    watchMemberFormCompanyId === '' ||
    watchMemberFormContactEmail === '' ||
    watchMemberFormContactName === '' ||
    watchMemberFormContactEmail === undefined ||
    watchMemberFormContactName === undefined ||
    watchMemberFormContactEmail === undefined
  ) {
    isNewMemberFormValid = false;
  }

  async function createNewMember(data: NewMemberFormModel): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await adminService.addUserToCompany(
      accessToken,
      data.memberFormCompanyId,
      data.memberFormContactEmail,
      data.memberFormContactName,
    );
    // return requirement;
  }

  const { mutate: mutateaddUserToCompany } = useMutation(createNewMember, {
    onSuccess: async () => {
      await comapniesRefetch();
      setIsNewMemberLoading(false);
      toast.success('Le nouveau membre a bien été ajouté.');
      resetNewMemberForm();
    },
    onError: () => {
      setIsNewMemberLoading(false);
      toast.error('Il y a eu un problème au moment de la création de cette entrée.');
      console.error('error');
    },
  });

  async function handleCreateNewMember(data: NewMemberFormModel): Promise<void> {
    setIsNewMemberLoading(true);
    mutateaddUserToCompany(data);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  if (companyStatus === 'success' && !companyData) {
    navigate('/');
    return <></>;
  }

  return (
    <BasePage>
      <Modal isOpen={isAddCreditModalOpen} setIsOpen={setIsAddCreditModalOpen}>
        <Text
          size="sm"
          content="Tu vas ajouter un crédit gratuitement à l'entreprise. Es-tu sûre de vouloir continuer?"
        />
        <Button
          content="Annuler"
          onClick={() => {
            setSelectedCompanyId('');
            setIsAddCreditModalOpen(false);
          }}
        />
        <Button
          content="Confirmer"
          onClick={async () => {
            await handleAddCredits();
            setSelectedCompanyId('');
            setIsAddCreditModalOpen(false);
          }}
        />
      </Modal>
      <div className={concatClassNames('flex', 'flex-col h-full w-full', 'overflow-auto', 'p-10')}>
        <br />
        <Text content="Back Office Discko" position="left" size="2xl" weight="bold" />
        <br />
        <Text content="Nouvelle agence" position="left" size="xl" weight="bold" />
        <br />

        <form className="flex flex-col gap-4" onSubmit={handleSubmitNewClient(handleCreateNewAgency)}>
          <Input
            placeholder="Nom de l'entreprise"
            value={watchCompanyFormCompanyName}
            {...registerNewCompanyForm('companyFormCompanyName')}
            isMandatory
            error={
              errorsNewCompanyForm.companyFormCompanyName != null
                ? errorsNewCompanyForm.companyFormCompanyName.message
                : undefined
            }
          />
          {!isNewClientLoading && (
            <Button
              content="Créer une nouvelle agence"
              width="fit"
              height="fit"
              type="submit"
              disabled={!isNewCompanyFormValid}
            />
          )}
          {isNewClientLoading && (
            <Button
              iconName="spinCircle"
              width="lg"
              type="button"
              iconAnimation="spin"
              disabled
              borderWidth="2"
              borderColor="gray-50"
            />
          )}
        </form>
        <br />
        <br />
        <Text content="Ajouter un membre à une agence existante" position="left" size="xl" weight="bold" />
        <br />

        <form className="flex flex-col gap-4" onSubmit={handleSubmitNewMember(handleCreateNewMember)}>
          <Input
            placeholder="Id de l'entreprise"
            value={watchMemberFormCompanyId}
            {...registerNewMemberForm('memberFormCompanyId')}
            error={
              errorsNewMemberForm.memberFormCompanyId != null
                ? errorsNewMemberForm.memberFormCompanyId.message
                : undefined
            }
            isMandatory
          />
          <Input
            placeholder="Nom du contact (Prénom NOM)"
            value={watchMemberFormContactName}
            {...registerNewMemberForm('memberFormContactName')}
            error={
              errorsNewMemberForm.memberFormContactName != null
                ? errorsNewMemberForm.memberFormContactName.message
                : undefined
            }
            isMandatory
          />
          <Input
            placeholder="Email du contact"
            value={watchMemberFormContactEmail}
            {...registerNewMemberForm('memberFormContactEmail')}
            error={
              errorsNewMemberForm.memberFormContactEmail != null
                ? errorsNewMemberForm.memberFormContactEmail.message
                : undefined
            }
            isMandatory
          />
          {!isNewMemberLoading && (
            <Button
              content="Rattacher ce contact à l'agence"
              width="fit"
              height="fit"
              type="submit"
              disabled={!isNewMemberFormValid}
            />
          )}
          {isNewMemberLoading && (
            <Button
              iconName="spinCircle"
              width="lg"
              type="button"
              iconAnimation="spin"
              disabled
              borderWidth="2"
              borderColor="gray-50"
            />
          )}
        </form>
        <br />
        <br />
        <Text content="Liste des entreprises" position="left" size="xl" weight="bold" />
        <br />
        <CompanyRow title />
        {companiesStatus === 'success' &&
          companiesData.companies.map((company) => (
            <div key={uuid()} className="flex flex-row w-full">
              <CompanyRow
                company={company}
                setSelectedCompanyId={setSelectedCompanyId}
                setIsAddCreditModalOpen={setIsAddCreditModalOpen}
              />
            </div>
          ))}
      </div>
    </BasePage>
  );
}
