import React from 'react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';

import { type CursorTypes, cursorStyle } from '../CoreStyle';
import { Text, type TextColor, type TextColorOnHover, type TextSize, type TextWeight } from '../Text/Text';

type ButtonWidth = 'full' | 'fit' | '1/2' | 'lg';

const buttonWidthStyle: Record<ButtonWidth, string> = {
  full: 'w-full',
  fit: 'w-fit',
  '1/2': 'w-1/2',
  lg: 'w-72',
};

type ButtonHeight = 'full' | 'fit' | 'sm';

const buttonHeightStyle: Record<ButtonHeight, string> = {
  full: 'h-full',
  fit: 'h-fit',
  sm: 'h-11',
};

type ButtonBgColor =
  | 'gradient1'
  | 'gradient2'
  | 'grey'
  | 'white'
  | 'black'
  | 'blue-100'
  | 'blue-200'
  | 'blue-300'
  | 'blue-400'
  | 'gradient3'
  | 'secondary-500'
  | 'secondary-400'
  | 'secondary-300'
  | 'secondary-200'
  | 'secondary-100'
  | 'none';

const buttonBgColorStyle: Record<ButtonBgColor, string> = {
  gradient1: 'bg-gradient-to-r from-gradient1-from to-gradient1-to',
  gradient2: 'bg-gradient-to-r from-gradient2-from to-gradient2-to',
  gradient3: 'bg-[#073B4C]',
  grey: 'bg-gray-25',
  white: 'bg-white',
  black: 'bg-black',
  'blue-100': 'bg-blue-100',
  'blue-200': 'bg-blue-200',
  'blue-300': 'bg-blue-300',
  'blue-400': 'bg-blue-400',
  'secondary-500': 'bg-secondary-500',
  'secondary-400': 'bg-secondary-400',
  'secondary-300': 'bg-secondary-300',
  'secondary-200': 'bg-secondary-200',
  'secondary-100': 'bg-secondary-100',
  none: 'bg-none',
};
type ButtonBgColorOnHover =
  | 'gray-25'
  | 'gray-200'
  | 'blue-300'
  | 'blue-400'
  | 'none'
  | 'white'
  | 'red-500'
  | 'green-500';

const buttonBgColorStyleOnHover: Record<ButtonBgColorOnHover, string> = {
  'gray-25': 'hover:bg-gray-25',
  'blue-300': 'hover:bg-blue-300',
  'blue-400': 'hover:bg-blue-400',
  'gray-200': 'hover:bg-gray-200',
  'red-500': 'hover:bg-red-500',
  'green-500': 'hover:bg-green-500',
  white: 'hover:bg-white',
  none: '',
};

type ButtonTextColor = 'white' | 'black' | 'blue-400';

type ButtonBorderColor = 'grey' | 'grey-500' | 'none' | 'black' | 'gray-50';

const buttonBorderColorStyle: Record<ButtonBorderColor, string> = {
  grey: 'border-gray-50',
  'grey-500': 'border-gray-500',
  none: 'border-none',
  black: 'border-black',
  'gray-50': 'border-gray-50',
};

type ButtonBorderWidth = 'none' | '1' | '2';

const buttonBorderWidthStyle: Record<ButtonBorderWidth, string> = {
  none: 'border-none',
  1: 'border-1',
  2: 'border-2',
};

type ButtonWhitespace = 'pre' | 'normal' | 'nowrap';

const buttonWhitespaceStyle: Record<ButtonWhitespace, string> = {
  pre: 'whitespace-pre',
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
};

type ButtonIconAnimation = 'none' | 'spin';

const buttonIconAnimationStyle: Record<ButtonIconAnimation, string> = {
  none: '',
  spin: 'animate-spin',
};

type ButtonPosition = 'none' | 'fixedb';

const buttonPositionStyle: Record<ButtonPosition, string> = {
  none: '',
  fixedb: 'sticky bottom-2 right-0 left-0',
};

type ButtonHorizontalPadding = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

const buttonHorizontalPaddingStyle: Record<ButtonHorizontalPadding, string> = {
  '0': 'px-0 lg:px-0',
  '1': 'px-1 lg:px-2',
  '2': 'px-2 lg:px-4',
  '3': 'px-3 lg:px-6',
  '4': 'px-4 lg:px-8',
  '5': 'px-5 lg:px-10',
  '6': 'px-6 lg:px-12',
  '7': 'px-7 lg:px-14',
  '8': 'px-8 lg:px-16',
  '9': 'px-9 lg:px-18',
  '10': 'px-10 lg:px-20',
  '11': 'px-11 lg:px-22',
  '12': 'px-12 lg:px-24',
};

interface ButtonProps {
  content?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onHover?: React.MouseEventHandler<HTMLButtonElement>;
  iconName?: IconName;
  iconPosition?: 'left' | 'right';
  width?: ButtonWidth;
  height?: ButtonHeight;
  bgColor?: ButtonBgColor;
  bgColorOnHover?: ButtonBgColorOnHover;

  textColorOnHover?: TextColor;
  textColor?: ButtonTextColor;
  textSize?: TextSize;
  borderColor?: ButtonBorderColor;
  borderColorOnHover?: ButtonBorderColor;
  borderWidth?: ButtonBorderWidth;
  borderWidthOnHover?: ButtonBorderWidth;
  iconColor?: IconColor;
  iconAnimation?: ButtonIconAnimation;
  whitespace?: ButtonWhitespace;
  position?: ButtonPosition;
  cursor?: CursorTypes;
  error?: boolean;
  iconSize?: IconSize;
  textWeight?: TextWeight;
  underline?: boolean;
  horizontalPadding?: ButtonHorizontalPadding;
}

export function Button({
  content,
  type,
  disabled,
  iconName,
  iconPosition = 'right',
  onClick,
  onHover,
  whitespace = 'pre',
  width = 'full',
  height = 'full',
  bgColor = 'black',
  bgColorOnHover = 'blue-300',
  textColorOnHover = 'white',
  textColor = 'white',
  textSize = 'base',
  borderColor = 'none',
  borderWidth = 'none',
  iconColor = 'white',
  iconAnimation = 'none',
  position = 'none',
  cursor = 'pointer',
  error = false,
  iconSize = 'sm',
  textWeight = 'normal',
  underline = false,
  borderColorOnHover = 'none',
  borderWidthOnHover = 'none',
  horizontalPadding,
}: ButtonProps): JSX.Element {
  const [isButtonHovered, setIsButtonHovered] = React.useState(false);
  return (
    <button
      className={concatClassNames(
        'flex',
        'flex-row',
        buttonWidthStyle[width],
        buttonHeightStyle[height],
        buttonWhitespaceStyle[whitespace],
        // textWeightStyle[textWeight],
        'gap-2.5',
        'justify-center',
        'items-center',
        'align-middle',
        horizontalPadding !== undefined ? buttonHorizontalPaddingStyle[horizontalPadding] : 'px-4',
        'py-2.5',
        'rounded-lg',
        buttonBgColorStyle[bgColor],
        buttonBgColorStyleOnHover[bgColorOnHover],
        isButtonHovered ? buttonBorderColorStyle[borderColorOnHover] : buttonBorderColorStyle[borderColor],
        isButtonHovered ? buttonBorderWidthStyle[borderWidthOnHover] : buttonBorderWidthStyle[borderWidth],
        buttonPositionStyle[position],
        cursorStyle[cursor],
        'disabled:bg-gray-50',
        'disabled:pointer-events-none',

        // 'bg-gradient-to-right hover:animate-bg-gradient',
        // 'hover:transition-colors duration-1000 ease-in-out hover:animate-background',
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
      // add on hover at on mouse enter
      onMouseEnter={(e) => {
        onHover?.(e);
        setIsButtonHovered(true);
      }}
      onMouseLeave={() => {
        setIsButtonHovered(false);
      }}
    >
      {iconName !== undefined && iconPosition === 'left' && (
        <div className={concatClassNames(buttonIconAnimationStyle[iconAnimation])}>
          {getIcon(iconName, iconColor, iconSize)}
        </div>
      )}
      {content !== undefined && (
        <Text
          content={content}
          size={textSize}
          color={isButtonHovered ? textColorOnHover : textColor}
          weight={textWeight}
          whitespace="pre-line"
          position="center"
          textDecoration={underline ? 'underline' : 'no-underline'}
        />
      )}
      {iconName !== undefined && iconPosition === 'right' && (
        <div className={concatClassNames(buttonIconAnimationStyle[iconAnimation])}>
          {getIcon(iconName, iconColor, iconSize)}
        </div>
      )}
    </button>
  );
}
