import {
  AgencyFormOrchestratorApi,
  type AgencyFormOrchestratorResponseDto,
  type ReceiveQuestionsInputsDto,
  type SetupProjectInputsDto,
} from 'api';

export class AgencyFormOrchestratorService {
  private readonly openAiClient: AgencyFormOrchestratorApi;

  constructor() {
    this.openAiClient = new AgencyFormOrchestratorApi();
  }

  public async setupProject(
    companyId: string,
    {
      requirementId,
      step,
      projectDescription,
      clientMaturity,
      projectMaturity,
      deadlineCategory,
      deadlineDate,
      priceRange,
    }: SetupProjectInputsDto,
  ): Promise<AgencyFormOrchestratorResponseDto> {
    return this.openAiClient.agencyFormOrchestratorControllerSetupProject({
      setupProjectInputsDto: {
        clientMaturity,
        projectDescription,
        projectMaturity,
        requirementId,
        step,
        deadlineCategory,
        deadlineDate,
        priceRange,
      },
      companyId,
    });
  }

  public async sendQuestion(
    companyId: string,
    { questions, requirementId, step }: ReceiveQuestionsInputsDto,
  ): Promise<AgencyFormOrchestratorResponseDto> {
    return this.openAiClient.agencyFormOrchestratorControllerReceiveQuestions({
      receiveQuestionsInputsDto: {
        requirementId,
        step,
        questions,
      },
      companyId,
    });
  }
}
