import {
  type CreateAgencyClientDto,
  type GetRequirementDto,
  type GetRequirementIdParamsDto,
  type GetRequirementsDto,
  RequirementsApi,
} from 'api';

export class RequirementService {
  private readonly requirementClient: RequirementsApi;

  constructor() {
    this.requirementClient = new RequirementsApi();
  }

  public async getRequirement(requirementId: string, accessToken: string): Promise<GetRequirementDto> {
    const getRequirementDto: GetRequirementDto = await this.requirementClient.authRequirementControllerFindById(
      {
        id: requirementId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getRequirementDto;
  }

  public async findAll(accessToken: string): Promise<GetRequirementsDto> {
    const getRequirementsDto: GetRequirementsDto = await this.requirementClient.authRequirementControllerFindAll({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return getRequirementsDto;
  }

  public async initRequirement(
    clientsInfo: CreateAgencyClientDto,
    companyId: string,
  ): Promise<GetRequirementIdParamsDto> {
    return await this.requirementClient.formRequirementControllerInitRequirement({
      companyId,
      createAgencyClientDto: clientsInfo,
    });
  }

  public async readRequirement(requirementId: string, accessToken: string): Promise<GetRequirementDto> {
    const getRequirementDto: GetRequirementDto = await this.requirementClient.authRequirementControllerRead(
      {
        id: requirementId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getRequirementDto;
  }

  public async unreadRequirement(requirementId: string, accessToken: string): Promise<GetRequirementDto> {
    const getRequirementDto: GetRequirementDto = await this.requirementClient.authRequirementControllerUnread(
      {
        id: requirementId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getRequirementDto;
  }

  public async commentRequirement(
    requirementId: string,
    comment: string,
    companyId: string,
  ): Promise<GetRequirementDto> {
    const getRequirementDto: GetRequirementDto = await this.requirementClient.formRequirementControllerAddComment({
      id: requirementId,
      addCommentDto: { comment },
      companyId,
    });
    return getRequirementDto;
  }
}
