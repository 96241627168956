/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { MaintenanceContext } from 'App';

import React, { useContext, useEffect, useState } from 'react';

import { BasePage } from 'components/core/BasePage/BasePage';
import { H3Title } from 'components/core/H3Title';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export function MaintenancePage(): JSX.Element {
  const { maintenanceInfos } = useContext(MaintenanceContext);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const now: number = new Date().getTime();
    const diff: number = maintenanceInfos.endDate.getTime() - now;

    const days: number = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours: number = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes: number = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((diff % (1000 * 60)) / 1000);

    setTimeLeft({
      days: days < 10 ? `0${days}` : `${days}`,
      hours: hours < 10 ? `0${hours}` : `${hours}`,
      minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
      seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
    });
    const x: NodeJS.Timeout = setInterval(() => {
      const now: number = new Date().getTime();
      const diff: number = maintenanceInfos.endDate.getTime() - now;

      // Check if the countdown has reached zero or negative
      if (diff <= 0) {
        clearInterval(x); // Stop the countdown
        return; // Exit the function
      }

      const days: number = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours: number = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes: number = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds: number = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft({
        days: days < 10 ? `0${days}` : `${days}`,
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
        seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
      });
    }, 1000);

    return () => {
      clearInterval(x);
    }; // Clean up on component unmount
  }, [maintenanceInfos]);

  /* ------------------------------------------------------ TSX ----------------------------------------------------- */

  return (
    <BasePage>
      <div
        className={concatClassNames(
          'flex flex-col gap-8',
          'w-full h-full',
          isLargeScreen ? 'py-20' : 'py-6',
          'items-center align-middle justify-center',
        )}
      >
        <img src="/imgs/maintenance.gif" className="w-40 h-40" />

        <H3Title text="MAINTENANCE EN COURS" weight="bold" color="blue-400" />
        <Text content="Discko.io est actuellement en maintenance !" weight="bold" />
        {maintenanceInfos.endDate > new Date() && (
          <>
            <Text content="Nous serons de retour dans :" />
            <div className="flex items-center justify-center w-full gap-4 count-down-main">
              <div className="timer w-16 flex flex-col align-middle justify-center">
                <div className="bg-blue-400 py-4 px-2 rounded-lg overflow-hidden">
                  <h3 className="countdown-element hours font-Cormorant font-semibold text-2xl text-white text-center">
                    {timeLeft.hours}
                  </h3>
                </div>
                <p className="text-lg font-Cormorant font-normal text-gray-900 mt-1 text-center w-full">heures</p>
              </div>
              <div className="rounded-lg overflow-hidden">
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <p className="text-lg font-Cormorant font-normal text-transparent mt-1 text-center w-full">{`|`}</p>
              </div>
              <div className="timer w-16 flex flex-col align-middle justify-center">
                <div className="bg-blue-400 py-4 px-2 rounded-lg overflow-hidden">
                  <h3 className="countdown-element minutes font-Cormorant font-semibold text-2xl text-white text-center">
                    {timeLeft.minutes}
                  </h3>
                </div>
                <p className="text-lg font-Cormorant font-normal text-gray-900 mt-1 text-center w-full">minutes</p>
              </div>
              <div className="rounded-lg overflow-hidden">
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <p className="text-lg font-Cormorant font-normal text-transparent mt-1 text-center w-full">{`|`}</p>
              </div>
              <div className="timer w-16 flex flex-col align-middle justify-center">
                <div className="bg-blue-400 py-4 px-2 rounded-lg overflow-hidden">
                  <h3 className="countdown-element seconds font-Cormorant font-semibold text-2xl text-white text-center">
                    {timeLeft.seconds}
                  </h3>
                </div>
                <p className="text-lg font-Cormorant font-normal text-gray-900 mt-1 text-center w-full">secondes</p>
              </div>
            </div>
          </>
        )}
      </div>
    </BasePage>
  );
}
