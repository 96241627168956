import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto } from 'api';
import { animate, motion, useInView, useMotionValue, useTransform } from 'framer-motion';

import React, { useContext, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

interface CreditsWidgetProps {
  isNotEnoughCreditError?: boolean;
}

export function CreditsWidget({ isNotEnoughCreditError = false }: CreditsWidgetProps): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const { companyService } = useContext(DisckoApisContext);
  const navigate: NavigateFunction = useNavigate();

  /* ---------------------------------------------------- Query; ---------------------------------------------------- */

  async function getCompany(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    const getDisckoCompanyDto: GetDisckoCompanyDto = await companyService.getCompanyByUserId(accessToken);
    return getDisckoCompanyDto;
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>('company', getCompany);

  /* ---------------------------------------------------- Counter --------------------------------------------------- */

  interface CounterProps {
    to: number;
  }

  function Counter({ to }: CounterProps): JSX.Element {
    const ref = useRef(null);
    const isInView: boolean = useInView(ref);
    const count = useMotionValue(to + 10);
    const rounded = useTransform(count, (x: number) => Math.floor(x));

    useEffect(() => {
      if (isInView) {
        const animation = animate(count, to, {
          duration: 1.5,
          ease: 'easeOut',
        });

        return animation.stop;
      }
    }, [isInView]);

    return (
      <>
        <span className={concatClassNames('p-1.5', 'pt-3')}>
          <motion.span className={concatClassNames('text-5xl text-white font-bold')} ref={ref}>
            {rounded}
          </motion.span>
        </span>
      </>
    );
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div>
      {companyStatus === 'success' && (
        <div className="flex flex-col gap-2 items-center">
          <div className="flex flex-row items-center gap-4">
            <Counter to={Number(companyData?.credits ?? 0)} />
            <Text content="crédits restants" color="white" size="lg" weight="bold" position="left" />
          </div>
          {isNotEnoughCreditError && (
            <p className={concatClassNames('text-red-500')}>{"Vous n'avez plus de crédits."}</p>
          )}
        </div>
      )}
    </div>
  );
}
