import React from 'react';

import { Chip } from 'components/core/Chip/Chip';

export enum CompletionType {
  Agency = 'agency',
  Client = 'client',
}

interface CompletionTypeChipProps {
  completionType?: CompletionType;
  loading?: boolean;
}

export default function getCompletionTypeChip({
  completionType = CompletionType.Agency,
  loading = false,
}: CompletionTypeChipProps): JSX.Element {
  if (loading) return <Chip loading />;
  switch (completionType) {
    case CompletionType.Agency:
      return <Chip label="Agence" />;
    case CompletionType.Client:
      return <Chip label="Client" />;

    default:
      return <div>error</div>;
  }
}
