import { DisckoApisContext } from 'App';
import { type GetRequirementDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { type RequirementSynthesis } from 'pages/RequirementForm/DisckoPage';

import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import { Text, type TextColor } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import { convertStringToRequirement } from 'utils/convertStringToSynthesis';
import { CompletionType } from 'utils/getCompletionTypeChip';
import getProjectTypeChip, { type ProjectType } from 'utils/getProjectTypeChip';

import { formatDate } from './RequirementsPage';

export function RequirementPage(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const navigate: NavigateFunction = useNavigate();
  const { requirementId = '' }: RequirementPageParams = useParams();

  const { requirementService } = useContext(DisckoApisContext);

  interface RequirementPageParams {
    requirementId?: string;
  }

  interface Tab {
    name: string;
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
  }

  interface ClientAnalysis {
    analyse: {
      evaluation?: {
        maturity?: string;
        engagement?: string;
        decision_makers?: string;
        score?: number;
        comment?: string;
      };
      upsell?: [{ service?: string; description?: string; value?: string }];
      personality?: string;
    };
  }

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  const projectScoreOptions: string[] = ['0', '1', '2', '3'];

  const projectScoreStyleOptions: string[] = [
    'Critique, manque de soutien stratégique',
    'Réalisable, mais objectifs flous et faible engagement',
    'Projet bien structuré avec ajustements à prévoir',
    'Projet idéal avec engagement fort et alignement stratégique',
  ];

  const projectScoreOptionsMap: Map<string, string> = new Map<string, string>();
  projectScoreOptionsMap.set('0', 'Critique, manque de soutien stratégique');
  projectScoreOptionsMap.set('1', 'Réalisable, mais objectifs flous et faible engagement');
  projectScoreOptionsMap.set('2', 'Projet bien structuré avec ajustements à prévoir');
  projectScoreOptionsMap.set('3', 'Projet idéal avec engagement fort et alignement stratégique');

  const projectScoreColorMap: Map<string, string> = new Map<string, string>();
  projectScoreColorMap.set('0', 'bg-red-500');
  projectScoreColorMap.set('1', 'bg-yellow-500');
  projectScoreColorMap.set('2', 'bg-green-300');
  projectScoreColorMap.set('3', 'bg-green-500');

  const projectScoreTextColorMap: Map<string, TextColor> = new Map<string, TextColor>();
  projectScoreTextColorMap.set('0', 'white');
  projectScoreTextColorMap.set('1', 'white');
  projectScoreTextColorMap.set('2', 'white');
  projectScoreTextColorMap.set('3', 'white');
  /* ---------------------------------------------------- States ---------------------------------------------------- */

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isRequirementOpen, setIsRequirementOpen] = useState<boolean>(true);
  const [isClientAnalysisOpen, setIsClientAnalysisOpen] = useState<boolean>(false);

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  const queryClient: QueryClient = useQueryClient();

  async function getRequirement(): Promise<GetRequirementDto> {
    const accessToken: string = await getAccessTokenSilently();
    const getRequirementDto: GetRequirementDto = await requirementService.getRequirement(requirementId, accessToken);
    return getRequirementDto;
  }

  const [restructuredRequirement, setRestructuredRequirement] = useState<RequirementSynthesis>({
    clientContext: [],
    objective: [],
    constraints: [],
    keySuccessFactors: [],
    targetUsers: [],
    abstract: '',
    clientMaturity: '',
    deadline: { deadlineCategory: 'flexible', date: '' },
    priceRange: 'abstain',
    projectType: 'Website',
    projectMaturity: 'IDEA',
    projectSubject: '',
    questions: [],
    step: '',
  });

  const completionTypeMap: Map<CompletionType, string> = new Map<CompletionType, string>();
  completionTypeMap.set(CompletionType.Agency, "l'agence");
  projectScoreOptionsMap.set(CompletionType.Client, 'le client');

  const [restructuredClientAnalysis, setRestructuredClientAnalysis] = useState<ClientAnalysis>();

  const { data: requirementData, status: requirementStatus } = useQuery<GetRequirementDto, Error>(
    ['requirement', requirementId],
    getRequirement,
  );

  function _setRestructuredRequirement(requirement: string): void {
    const tmpRequirement: RequirementSynthesis = convertStringToRequirement(requirement);
    tmpRequirement.comment = JSON.parse(requirement).comment;
    if (tmpRequirement.comment === '') {
      tmpRequirement.comment = undefined;
    }

    setRestructuredRequirement(tmpRequirement);
  }

  function _setRestructuredClientAnalysis(clientAnalysis: string | undefined): void {
    if (clientAnalysis === undefined) return;
    const jsonClientAnalysis: ClientAnalysis = JSON.parse(clientAnalysis);

    const restructuredClientAnalysis: ClientAnalysis = {
      analyse: {
        evaluation: {
          maturity: jsonClientAnalysis?.analyse?.evaluation?.maturity,
          engagement: jsonClientAnalysis?.analyse?.evaluation?.engagement,
          decision_makers: jsonClientAnalysis?.analyse?.evaluation?.decision_makers,
          score: jsonClientAnalysis?.analyse?.evaluation?.score,
          comment: jsonClientAnalysis?.analyse?.evaluation?.comment,
        },
        personality: jsonClientAnalysis.analyse?.personality,
        upsell: jsonClientAnalysis.analyse?.upsell,
      },
    };

    setRestructuredClientAnalysis(restructuredClientAnalysis);
  }

  useEffect(() => {
    if (requirementStatus === 'success') {
      _setRestructuredRequirement(requirementData.requirement);
      _setRestructuredClientAnalysis(requirementData.clientAnalysis);
    }
  }, [requirementData, requirementStatus]);

  // set read status to true if the requirement is opened
  useEffect(() => {
    if (requirementStatus === 'success' && isFirstRender) {
      if (!requirementData.read) {
        mutateReadRequirement();
      }
      setIsFirstRender(false);
    }
  }, [isFirstRender, requirementData, requirementStatus]);

  /* ------------------------------------------------ readRequirement ----------------------------------------------- */

  async function readRequirement(): Promise<GetRequirementDto> {
    const accessToken: string = await getAccessTokenSilently();
    const requirement: GetRequirementDto = await requirementService.readRequirement(requirementId, accessToken);
    return requirement;
  }

  const { mutate: mutateReadRequirement } = useMutation(readRequirement, {
    onSuccess: (getRequirementDto: GetRequirementDto) => {
      queryClient.setQueryData<GetRequirementDto>(
        ['requirement', requirementId],
        (oldData: GetRequirementDto | undefined) => {
          _setRestructuredRequirement(getRequirementDto.requirement);
          oldData = getRequirementDto;
          return getRequirementDto;
        },
      );
    },
    onError: () => {
      console.error('error');
    },
  });

  /* ---------------------------------------------- unread Requirement ---------------------------------------------- */

  async function unreadRequirement(): Promise<GetRequirementDto> {
    const accessToken: string = await getAccessTokenSilently();
    const requirement: GetRequirementDto = await requirementService.unreadRequirement(requirementId, accessToken);
    return requirement;
  }

  const { mutate: mutateUnreadRequirement } = useMutation(unreadRequirement, {
    onSuccess: (getRequirementDto: GetRequirementDto) => {
      queryClient.setQueryData<GetRequirementDto>(
        ['requirement', requirementId],
        (oldData: GetRequirementDto | undefined) => {
          _setRestructuredRequirement(getRequirementDto.requirement);
          oldData = getRequirementDto;
          return getRequirementDto;
        },
      );
    },
    onError: () => {
      console.error('error');
    },
  });

  return (
    <BasePage>
      <>
        {isLargeScreen === true && (
          <div className={concatClassNames('h-full', 'w-full', 'flex', 'flex-col', 'relative', 'pb-4', '')}>
            <div className={concatClassNames('flex flex-col pt-2 pb-0 w-full h-full overflow-auto ')}>
              {requirementStatus === 'success' && (
                <div
                  className={concatClassNames(
                    'flex flex-row h-full px-12 pb-0 pt-12',
                    'gap-2',
                    'overflow-auto',
                    'bg-white m-4 rounded-lg',
                    // 'flex-grow items-stretch',
                  )}
                >
                  <div className={concatClassNames('w-[45%] sticky overflow-auto', 'flex flex-col', 'gap-4')}>
                    <div className={concatClassNames('h-fit w-full', 'flex flex-row')}>
                      <Button
                        content="Retour"
                        onClick={() => {
                          navigate('/requirements');
                        }}
                        iconName="chevronLeft"
                        iconPosition="left"
                        width="fit"
                        bgColor="none"
                        iconColor="black"
                        textColor="black"
                        bgColorOnHover="none"
                        borderColorOnHover="none"
                        textColorOnHover="black"
                      />
                    </div>
                    <div className="overflow-auto flex flex-col gap-6 rounded-lg p-4 h-full">
                      <div className={concatClassNames('flex flex-row bg-blue-400 gap-4 p-4')}>
                        <img src="/imgs/buyer.png" className={concatClassNames('hidden xl:block xl:w-16 xl:h-16')} />
                        <div className="flex flex-col gap-1  w-full">
                          <div className={concatClassNames('flex flex-row gap-4 items-center ')}>
                            <Text color="white" size="sm" position="left" whitespace="nowrap" content="Entreprise :" />
                            <Text
                              size="sm"
                              position="left"
                              weight="bold"
                              color="white"
                              content={requirementData.agencyClient.companyName}
                            />
                          </div>
                          <div className={concatClassNames('flex flex-row justify-start')}>
                            <Text color="white" size="sm" position="left" whitespace="nowrap" content="Nom :" />
                            <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                              <Text
                                size="sm"
                                weight="bold"
                                color="white"
                                uppercase
                                content={requirementData.agencyClient.contactLastname}
                              />
                            </div>
                          </div>
                          <div className={concatClassNames('flex flex-row justify-start')}>
                            <Text color="white" size="sm" position="left" whitespace="nowrap" content="Prénom :" />
                            <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                              <Text
                                size="sm"
                                position="left"
                                weight="bold"
                                color="white"
                                content={requirementData.agencyClient.contactFirstname}
                              />
                            </div>
                          </div>

                          <div className={concatClassNames('flex flex-row gap-4 items-start')}>
                            <Text size="sm" position="left" whitespace="nowrap" content="Email :" color="white" />
                            <Text
                              size="sm"
                              position="left"
                              color="white"
                              weight="bold"
                              // whitespace="pre-wrap"
                              textBreak="all"
                              content={requirementData.agencyClient.contactEmail}
                            />
                          </div>
                          <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                            <Text size="sm" position="left" whitespace="nowrap" content="Téléphone :" color="white" />
                            <Text
                              size="sm"
                              color="white"
                              position="left"
                              weight="bold"
                              content={requirementData.agencyClient.contactPhone}
                            />
                          </div>
                        </div>
                      </div>
                      {getProjectTypeChip({ projectType: requirementData.projectType as ProjectType, size: 'base' })}
                      <div>
                        {'Complétée le '}
                        {formatDate(requirementData.finishedAt)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full ">
                    <div className="flex flex-row items-center justify-between gap-6 pb-4">
                      <div className="flex flex-row w-full">
                        <div
                          key={uuid()}
                          onClick={() => {
                            setIsRequirementOpen(true);
                            setIsClientAnalysisOpen(false);
                          }}
                          className={concatClassNames(
                            'border-black ',
                            'px-4 py-2',
                            'cursor-pointer',
                            'rounded-full',
                            isRequirementOpen ? 'bg-black' : '',
                          )}
                        >
                          <Text
                            content="Expression de besoin"
                            size="lg"
                            color={isRequirementOpen ? 'white' : 'gray-400'}
                          />
                        </div>
                        {Object.keys(JSON.parse(requirementData.clientAnalysis ?? '{}')).length !== 0 && (
                          <div
                            key={uuid()}
                            onClick={() => {
                              setIsClientAnalysisOpen(true);
                              setIsRequirementOpen(false);
                            }}
                            className={concatClassNames(
                              'border-black ',
                              'px-4 py-2',
                              'cursor-pointer',
                              'rounded-full',
                              isClientAnalysisOpen ? 'bg-black' : '',
                            )}
                          >
                            <Text
                              content="Analyse (Bêta)"
                              size="lg"
                              color={isClientAnalysisOpen ? 'white' : 'gray-400'}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" p-8 overflow-auto flex-grow items-stretch w-full">
                      {isRequirementOpen && (
                        <div className="flex flex-col gap-4">
                          <div className="">
                            {(restructuredRequirement.projectMaturity !== undefined ||
                              restructuredRequirement.priceRange !== undefined ||
                              restructuredRequirement.deadline?.deadlineCategory !== undefined ||
                              restructuredRequirement.clientMaturity !== undefined) && (
                              <div className={concatClassNames('flex flex-col', 'gap-4')}>
                                <div className=" bg-blue-400 px-4 py-1">
                                  <Text
                                    size="lg"
                                    content="Informations générales exprimées par le prospect"
                                    weight="bold"
                                    color="white"
                                  />
                                </div>
                                {getProjectTypeChip({
                                  projectType: requirementData.projectType as ProjectType,
                                  size: 'base',
                                })}

                                {restructuredRequirement.projectMaturity !== undefined && (
                                  <div className={concatClassNames('flex flex-row gap-1 items-center')}>
                                    <Text
                                      size="sm"
                                      position="left"
                                      whitespace="nowrap"
                                      content="Maturité de son projet :"
                                    />
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      content={restructuredRequirement.projectMaturity}
                                    />
                                  </div>
                                )}

                                {restructuredRequirement.priceRange !== undefined && (
                                  <div className={concatClassNames('flex flex-row gap-1 items-start')}>
                                    <Text size="sm" position="left" whitespace="nowrap" content="Budget envisagé:" />
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      content={restructuredRequirement.priceRange}
                                    />
                                  </div>
                                )}
                                {restructuredRequirement.deadline?.deadlineCategory !== undefined && (
                                  <div className={concatClassNames('flex flex-row gap-1 items-start')}>
                                    <Text size="sm" position="left" whitespace="nowrap" content="Délai envisagé :" />
                                    <Text
                                      size="sm"
                                      weight="bold"
                                      content={restructuredRequirement.deadline?.deadlineCategory}
                                    />
                                    {restructuredRequirement.deadline?.date !== undefined && (
                                      <Text
                                        size="sm"
                                        weight="bold"
                                        content={`- ${restructuredRequirement.deadline?.date}`}
                                      />
                                    )}
                                  </div>
                                )}

                                {restructuredRequirement.clientMaturity !== undefined && (
                                  <div className={concatClassNames('flex flex-row gap-1 items-start')}>
                                    <Text
                                      size="sm"
                                      position="left"
                                      whitespace="nowrap"
                                      content="Compétences techniques du client :"
                                    />
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      content={restructuredRequirement.clientMaturity}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4 w-[100%]">
                              {restructuredRequirement.clientContext !== undefined && (
                                <div className="h-fit">
                                  <div className=" ">
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Contexte" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/landscape.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {restructuredRequirement.clientContext.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="h-fit">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Objectifs" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-row gap-4 items-center ">
                                    <img src="/imgs/mission.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.objective?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className="h-fit">
                                  <div className="bg-blue-400 px-4 py-1 ">
                                    <Text size="lg" content="Utilisateurs cibles" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-row gap-4 items-center">
                                    <img src="/imgs/student.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.targetUsers?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 w-[100%]">
                              <div className="">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Contraintes" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-row gap-4 items-center">
                                    <img src="/imgs/barrier.gif" className="w-16 h-16" />

                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.constraints?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Facteurs clefs de succès" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-row gap-4 items-center">
                                    <img src="/imgs/like.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.keySuccessFactors?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {restructuredRequirement.comment !== undefined && (
                            <>
                              <Text
                                color="black"
                                size="lg"
                                weight="bold"
                                content="Commentaire ajouté par le prospect:"
                              />
                              <Text size="sm" content={restructuredRequirement.comment} position="justify" />
                            </>
                          )}
                        </div>
                      )}
                      {isClientAnalysisOpen && (
                        <div className="w-full flex flex-col gap-4">
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1">
                              <div className={concatClassNames('flex flex-row gap-6 items-center')} key={uuid()}>
                                <div className="flex flex-row gap-1 items-center">
                                  {projectScoreOptions.map((e, index) => (
                                    <div
                                      className={concatClassNames(
                                        restructuredClientAnalysis?.analyse.evaluation?.score?.toString() === e
                                          ? 'h-8 w-8'
                                          : 'h-2 w-2',
                                        'rounded-full flex items-center justify-center align-middle',
                                        projectScoreColorMap.get(e.toString() ?? '') ?? '',
                                      )}
                                      key={uuid()}
                                    >
                                      {restructuredClientAnalysis?.analyse.evaluation?.score?.toString() === e && (
                                        <Text
                                          size="lg"
                                          position="left"
                                          weight="bold"
                                          color={projectScoreTextColorMap.get(
                                            restructuredClientAnalysis?.analyse.evaluation?.score?.toString() ?? '',
                                          )}
                                          content={restructuredClientAnalysis?.analyse.evaluation?.score?.toString()}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                                {/* <div
                                  className={concatClassNames(
                                    'h-8 w-8 rounded-full flex items-center justify-center align-middle',
                                    projectScoreColorMap.get(
                                      restructuredClientAnalysis?.analyse.evaluation?.score?.toString() ?? '',
                                    ) ?? '',
                                  )}
                                >
                                  <Text
                                    size="lg"
                                    position="left"
                                    weight="bold"
                                    color={projectScoreTextColorMap.get(
                                      restructuredClientAnalysis?.analyse.evaluation?.score?.toString() ?? '',
                                    )}
                                    content={restructuredClientAnalysis?.analyse.evaluation?.score?.toString()}
                                  />
                                </div> */}
                                <Text
                                  size="lg"
                                  weight="bold"
                                  position="left"
                                  content={projectScoreOptionsMap.get(
                                    restructuredClientAnalysis?.analyse.evaluation?.score?.toString() ?? '',
                                  )}
                                />
                              </div>
                            </div>
                            <div className="">
                              <Text
                                size="md"
                                weight="bold"
                                position="justify"
                                content={restructuredClientAnalysis?.analyse.evaluation?.comment}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row gap-4">
                            <div className="flex flex-col gap-4 w-[50%]">
                              <div>
                                <div className="bg-green-400 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Maturité" />
                                </div>
                                <div className="bg-green-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={restructuredClientAnalysis?.analyse.evaluation?.maturity}
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="bg-pink-500 px-4 py-1">
                                  <Text position="justify" size="lg" weight="bold" content="Décideurs" color="white" />
                                </div>
                                <div className="bg-pink-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={restructuredClientAnalysis?.analyse.evaluation?.decision_makers}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="bg-purple-300 px-4 py-1">
                                  <Text size="lg" position="left" weight="bold" content="Personnalité" />
                                </div>
                                <div className="bg-purple-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={restructuredClientAnalysis?.analyse.personality}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 w-[50%]">
                              <div>
                                <div className="bg-indigo-500 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Upsell" color="white" />
                                </div>
                                <ul className="list-inside justify-start text-justify p-4 space-y-4 bg-indigo-100">
                                  {restructuredClientAnalysis?.analyse.upsell?.map((e, index) => (
                                    <>
                                      <div key={uuid()} className="text-sm flex flex-col gap-2">
                                        <li className="font-bold text-white bg-indigo-400 w-fit px-2 py-1">
                                          {e.service}
                                        </li>
                                        <div> {e.description}</div>
                                        <div> {e.value}</div>
                                      </div>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {isLargeScreen === false && (
          <div className={concatClassNames('h-full', 'w-full', 'flex', 'flex-col', 'relative')}>
            <div className={concatClassNames('flex flex-col  w-full h-full overflow-auto')}>
              {requirementStatus === 'success' && (
                <div
                  className={concatClassNames(
                    'flex flex-col w-full h-full',
                    'gap-2',
                    'overflow-auto',
                    'bg-white rounded-lg',
                  )}
                >
                  <div className={concatClassNames('w-full', 'flex flex-col')}>
                    <div className={concatClassNames('h-fit w-fit', 'flex flex-row')}>
                      <Button
                        content="Retour"
                        onClick={() => {
                          navigate('/requirements');
                        }}
                        iconName="chevronLeft"
                        iconPosition="left"
                        width="fit"
                        bgColor="none"
                        iconColor="black"
                        textColor="black"
                        bgColorOnHover="none"
                        borderColorOnHover="none"
                        textColorOnHover="black"
                      />
                    </div>
                    <div className="overflow-auto flex flex-col gap-6 rounded-lg p-4 h-full">
                      <div className="flex flex-col gap-4">
                        {getProjectTypeChip({ projectType: requirementData.projectType as ProjectType, size: 'base' })}
                        <div className="italic text-xs md:text-sm">
                          {'Complétée le '}
                          {formatDate(requirementData.finishedAt)}
                        </div>
                      </div>
                      <div className={concatClassNames('flex flex-row bg-blue-400  gap-4  p-4')}>
                        <img src="/imgs/buyer.png" className="w-16 h-16 hidden sm:flex" />
                        <div className="flex flex-col gap-1  w-full">
                          <div className={concatClassNames('flex flex-row gap-4 items-center ')}>
                            <Text color="white" size="sm" position="left" whitespace="nowrap" content="Entreprise :" />
                            <Text
                              size="sm"
                              position="left"
                              weight="bold"
                              color="white"
                              content={requirementData.agencyClient.companyName}
                            />
                          </div>
                          <div className={concatClassNames('flex flex-row justify-start')}>
                            <Text color="white" size="sm" position="left" whitespace="nowrap" content="Contact :" />
                            <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                              <Text
                                size="sm"
                                position="left"
                                weight="bold"
                                color="white"
                                content={requirementData.agencyClient.contactFirstname}
                              />

                              <Text
                                size="sm"
                                weight="bold"
                                color="white"
                                uppercase
                                content={requirementData.agencyClient.contactLastname}
                              />
                            </div>
                          </div>
                          <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                            <Text size="sm" position="left" whitespace="nowrap" content="Email :" color="white" />
                            <Text
                              size="sm"
                              position="left"
                              color="white"
                              weight="bold"
                              content={requirementData.agencyClient.contactEmail}
                            />
                          </div>
                          <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                            <Text size="sm" position="left" whitespace="nowrap" content="Téléphone :" color="white" />
                            <Text
                              size="sm"
                              color="white"
                              position="left"
                              weight="bold"
                              content={requirementData.agencyClient.contactPhone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full items-center justify-center">
                    <div className=" p-4 md:p-8 overflow-auto flex-grow items-stretch w-full flex flex-col gap-8">
                      <div id="requirement">
                        <div className="flex flex-col gap-4">
                          <div className="">
                            {(restructuredRequirement.projectMaturity !== undefined ||
                              restructuredRequirement.priceRange !== undefined ||
                              restructuredRequirement.deadline?.deadlineCategory !== undefined ||
                              restructuredRequirement.clientMaturity !== undefined) && (
                              <div className={concatClassNames('flex flex-col', 'gap-4')}>
                                <div className=" bg-blue-400 px-0 md:px-4 py-1">
                                  <Text
                                    size="sm"
                                    content="Informations générales exprimées par le prospect"
                                    weight="bold"
                                    color="white"
                                  />
                                </div>
                                <div className="hidden md:flex">
                                  {getProjectTypeChip({
                                    projectType: requirementData.projectType as ProjectType,
                                    size: 'base',
                                  })}
                                </div>
                                {restructuredRequirement.projectMaturity !== undefined && (
                                  <div
                                    className={concatClassNames(
                                      'flex flex-col md:flex-row gap-1 justify-items-start md:items-center',
                                    )}
                                  >
                                    <Text
                                      size="sm"
                                      position="left"
                                      whitespace="nowrap"
                                      content="Maturité de son projet :"
                                    />
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      content={restructuredRequirement.projectMaturity}
                                    />
                                  </div>
                                )}

                                {restructuredRequirement.priceRange !== undefined && (
                                  <div
                                    className={concatClassNames(
                                      'flex flex-col md:flex-row gap-1 justify-items-start md:items-center',
                                    )}
                                  >
                                    <Text size="sm" position="left" whitespace="nowrap" content="Budget envisagé:" />
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      content={restructuredRequirement.priceRange}
                                    />
                                  </div>
                                )}
                                {restructuredRequirement.deadline?.deadlineCategory !== undefined && (
                                  <div
                                    className={concatClassNames(
                                      'flex flex-col md:flex-row gap-1 justify-items-start md:items-center',
                                    )}
                                  >
                                    <Text size="sm" position="left" whitespace="nowrap" content="Délai envisagé :" />
                                    <Text
                                      size="sm"
                                      weight="bold"
                                      content={restructuredRequirement.deadline?.deadlineCategory}
                                    />
                                    {restructuredRequirement.deadline?.date !== undefined && (
                                      <Text
                                        size="sm"
                                        weight="bold"
                                        position="left"
                                        content={`${restructuredRequirement.deadline?.date}`}
                                      />
                                    )}
                                  </div>
                                )}

                                {restructuredRequirement.clientMaturity !== undefined && (
                                  <div
                                    className={concatClassNames(
                                      'flex flex-col md:flex-row gap-1 justify-items-start md:items-center',
                                    )}
                                  >
                                    <Text
                                      size="sm"
                                      position="left"
                                      whitespace="nowrap"
                                      content="Compétences techniques du client :"
                                    />
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      content={restructuredRequirement.clientMaturity}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4 w-[100%]">
                              {restructuredRequirement.clientContext !== undefined && (
                                <div className="h-fit">
                                  <div className=" ">
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Contexte" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-4 items-center">
                                      <img src="/imgs/landscape.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {restructuredRequirement.clientContext.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="h-fit">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Objectifs" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center ">
                                    <img src="/imgs/mission.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.objective?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className="h-fit">
                                  <div className="bg-blue-400 px-4 py-1 ">
                                    <Text size="lg" content="Utilisateurs cibles" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center">
                                    <img src="/imgs/student.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.targetUsers?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 w-[100%]">
                              <div className="">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Contraintes" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center">
                                    <img src="/imgs/barrier.gif" className="w-16 h-16" />

                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.constraints?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Facteurs clefs de succès" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center">
                                    <img src="/imgs/like.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {restructuredRequirement?.keySuccessFactors?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {restructuredRequirement.comment !== undefined && (
                            <>
                              <Text
                                color="black"
                                size="lg"
                                weight="bold"
                                content="Commentaire ajouté par le prospect:"
                              />
                              <Text size="sm" content={restructuredRequirement.comment} position="justify" />
                            </>
                          )}
                        </div>
                      </div>
                      <hr></hr>
                      <div id="clientAnalysis" className="flex flex-col gap-4">
                        <div className="hidden md:flex">
                          <Text content="Analyse client" />
                        </div>
                        <div className="w-full flex flex-col gap-4">
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1">
                              <div
                                className={concatClassNames('flex flex-col md:flex-row gap-6  items-center')}
                                key={uuid()}
                              >
                                <div className="flex flex-row gap-1 items-center">
                                  {projectScoreOptions.map((e, index) => (
                                    <div
                                      className={concatClassNames(
                                        restructuredClientAnalysis?.analyse.evaluation?.score?.toString() === e
                                          ? 'h-8 w-8'
                                          : 'h-2 w-2',
                                        'rounded-full flex items-center justify-center align-middle',
                                        projectScoreColorMap.get(e.toString() ?? '') ?? '',
                                      )}
                                      key={uuid()}
                                    >
                                      {restructuredClientAnalysis?.analyse.evaluation?.score?.toString() === e && (
                                        <Text
                                          size="lg"
                                          position="left"
                                          weight="bold"
                                          color={projectScoreTextColorMap.get(
                                            restructuredClientAnalysis?.analyse.evaluation?.score?.toString() ?? '',
                                          )}
                                          content={restructuredClientAnalysis?.analyse.evaluation?.score?.toString()}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                                <Text
                                  size="lg"
                                  weight="bold"
                                  position="center"
                                  content={projectScoreOptionsMap.get(
                                    restructuredClientAnalysis?.analyse.evaluation?.score?.toString() ?? '',
                                  )}
                                />
                              </div>
                            </div>
                            <div className="">
                              <Text
                                size="sm"
                                weight="bold"
                                position="justify"
                                content={restructuredClientAnalysis?.analyse.evaluation?.comment}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col gap-4 md:w-[50%]">
                              <div>
                                <div className="bg-green-400 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Maturité" />
                                </div>
                                <div className="bg-green-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={restructuredClientAnalysis?.analyse.evaluation?.maturity}
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="bg-pink-500 px-4 py-1">
                                  <Text position="justify" size="lg" weight="bold" content="Décideurs" color="white" />
                                </div>
                                <div className="bg-pink-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={restructuredClientAnalysis?.analyse.evaluation?.decision_makers}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="bg-purple-300 px-4 py-1">
                                  <Text size="lg" position="left" weight="bold" content="Personnalité" />
                                </div>
                                <div className="bg-purple-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={restructuredClientAnalysis?.analyse.personality}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 md:w-[50%]">
                              <div>
                                <div className="bg-indigo-500 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Upsell" color="white" />
                                </div>
                                <ul className="list-inside justify-start text-justify p-4 space-y-4 bg-indigo-100">
                                  {restructuredClientAnalysis?.analyse.upsell?.map((e, index) => (
                                    <>
                                      <div key={uuid()} className="text-sm flex flex-col gap-2">
                                        <li className="font-bold text-white bg-indigo-400 w-fit px-2 py-1 text-left">
                                          {e.service}
                                        </li>
                                        <div> {e.value}</div>
                                      </div>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </BasePage>
  );
}
