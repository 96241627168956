import React from 'react';

import { BasePage } from 'components/core/BasePage/BasePage';

import { PrivacyPolicyWidget } from './PrivacyPolicyWidget';

export function PrivacyPolicy(): JSX.Element {
  return (
    <BasePage>
      <PrivacyPolicyWidget />
    </BasePage>
  );
}
