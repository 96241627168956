/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCheckoutSessionDto,
  ErrorBadRequest,
} from '../models';
import {
    CreateCheckoutSessionDtoFromJSON,
    CreateCheckoutSessionDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
} from '../models';

export interface StripeControllerCreateCheckoutSessionRequest {
    createCheckoutSessionDto: CreateCheckoutSessionDto;
}

export interface StripeControllerCreatePortalSessionRequest {
    returnUrl: string;
}

/**
 * 
 */
export class StripeApi extends runtime.BaseAPI {

    /**
     */
    async stripeControllerCreateCheckoutSessionRaw(requestParameters: StripeControllerCreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.createCheckoutSessionDto === null || requestParameters.createCheckoutSessionDto === undefined) {
            throw new runtime.RequiredError('createCheckoutSessionDto','Required parameter requestParameters.createCheckoutSessionDto was null or undefined when calling stripeControllerCreateCheckoutSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/stripe/create-checkout-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCheckoutSessionDtoToJSON(requestParameters.createCheckoutSessionDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async stripeControllerCreateCheckoutSession(requestParameters: StripeControllerCreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.stripeControllerCreateCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stripeControllerCreatePortalSessionRaw(requestParameters: StripeControllerCreatePortalSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.returnUrl === null || requestParameters.returnUrl === undefined) {
            throw new runtime.RequiredError('returnUrl','Required parameter requestParameters.returnUrl was null or undefined when calling stripeControllerCreatePortalSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['return_url'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/stripe/portal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async stripeControllerCreatePortalSession(requestParameters: StripeControllerCreatePortalSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.stripeControllerCreatePortalSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stripeWebhookControllerGetCheckoutSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stripe/get-checkout-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async stripeWebhookControllerGetCheckoutSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.stripeWebhookControllerGetCheckoutSessionRaw(initOverrides);
    }

}
